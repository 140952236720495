import { ThemeProvider } from "@mui/material/styles";
import { GlobalProvider } from "./context/Provider";
import { CssBaseline } from "@mui/material";
import { theme } from "./theme";
import Routing from "./routing/Routing";

function App() {
  return (
    <div className={"app"}>
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routing />
        </ThemeProvider>
      </GlobalProvider>
    </div>
  );
}

export default App;
