/* eslint-disable import/no-anonymous-default-export */
import {
  DELETE_USER_LOADING,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
} from "../../../constants/actionTypes";
import axiosInstance from "../../../helpers/axiosInstance";

export default (payload) => (dispatch) => {
  dispatch({
    type: DELETE_USER_LOADING,
    payload: payload,
  });

  axiosInstance
      .delete("backoffice/UsuarioBackoffice/Delete", { data: payload })
    .then(() => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: payload,
      });
    })
    .catch((err) => {
      console.log("res.status", err.status);
      dispatch({
        type: DELETE_USER_ERROR,
        payload: err.response ? err.response.data : "could not connect",
      });
    });
};
