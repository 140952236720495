import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import {
  Typography,
  Divider,
  Box,
  IconButton,
  TextField,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";

const RecetaPosdatada = (props) => {
  const { estilos, cantidadRecetas, onClick, posdatadas, handleChange, limitePosdatadas } = props;
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      <Typography
        sx={{
          m: 1,
          fontSize: "0.91rem",
          color: estilos.colorTextSecondary,
          fontWeight: "500",
        }}
      >
        Crear recetas
      </Typography>
      <Divider variant={"fullWidth"} sx={{ py: 1, borderColor: "#2D3748" }} />
      {limitePosdatadas > 0 && (
        <Box
          sx={{
            padding: "5px 15px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mr: 1,
              fontSize: "0.71rem",
              color: estilos.colorTextSecondary,
              fontWeight: "400",
            }}
          >
            Se creará(n)
          </Typography>
          <IconButton
            aria-label="delete"
            size="small"
            sx={{ color: estilos.backgroundColorPrimary }}
            onClick={() => onClick("remove-postdatadas")}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
          <Typography
            sx={{
              mx: 1,
              fontSize: "0.99rem",
              color: estilos.colorTextSecondary,
              fontWeight: "500",
            }}
          >
            {cantidadRecetas}
          </Typography>
          <IconButton
            aria-label="add"
            size="small"
            sx={{ color: estilos.backgroundColorPrimary }}
            onClick={() => onClick("add-postdatadas")}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <Typography
            sx={{
              ml: 1,
              fontSize: "0.71rem",
              color: estilos.colorTextSecondary,
              fontWeight: "400",
            }}
          >
            receta(s)
          </Typography>
        </Box>
      )}
      {limitePosdatadas === 0 && (
        <Box
          sx={{
            padding: "5px 15px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mr: 1,
              fontSize: "0.71rem",
              color: estilos.colorTextSecondary,
              fontWeight: "400",
            }}
          >
            Se creará 1 (una) receta con fecha
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          padding: "20px 15px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" spacing={2}>
            {posdatadas.map((p, i) => {
              return (
                <>
                  <DesktopDatePicker
                    key={i}
                    label="Fecha"
                    sx={{ width: "150px" }}
                    inputFormat="DD/MM/YYYY"
                    minDate={
                      i === 0
                        ? posdatadas[0].fecha
                        : posdatadas[i - 1].fecha.add(1, "day")
                    }
                    value={p.fecha}
                    onChange={(value) => handleChange(p.id, value)}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        color={estilos.backgroundColorPrimary}
                        fullWidth
                        label="Fecha"
                        id="fecha"
                      />
                    )}
                  />
                </>
              );
            })}
          </Stack>
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          padding: "0px 15px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            mr: 1,
            fontSize: "0.85rem",
            color: estilos.colorTextPrimary,
            fontWeight: "400",
          }}
        >
          Receta válida por {process.env.REACT_APP_VENCIMIENTO_RECETA} días
        </Typography>
      </Box>
    </div>
  );
};

export default RecetaPosdatada;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: '18px 0px',
    padding: 12,
    backgroundColor: theme.palette.neutral,
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "10px",
  },
}));


const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& label.Mui-focused": {
    color: color,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: color,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: color,
    },
    "&.Mui-focused fieldset": {
      borderColor: color,
    },
    fontSize: "14px",
  },
}));