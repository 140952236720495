import { HighlightSharp } from "@mui/icons-material";
import { action, observable, makeAutoObservable, runInAction } from "mobx";
import ConfigurationService from "../../services/ConfigurationService";

class Store {
  appId = 0;
  application = null;
  configurationId = null;
  dataFormulario = null;
  estilosFormulario = null;
  secciones = null;
  vinculateAllFinanciers = false;
  selectedFinanciers = [];
  error = null;
  dataDictionary = [
    {
      source: "Medico",
      campos: [
        { campo: "TipoMatricula", descripcion: "TipoMatricula" },
        { campo: "Matricula", descripcion: "Matricula" },
      ],
    },
    {
      source: "Paciente",
      campos: [
        { campo: "Nombre", descripcion: "Nombre" },
        { campo: "Apellido", descripcion: "Apellido" },
        { campo: "TipoDocumento", descripcion: "TipoDocumento" },
        { campo: "NroDocumento", descripcion: "NroDocumento" },
      ],
    },
  ];
  dataMaestrosPaciente = [];
  dataMaestrosMedico = [];
  dataMaestros = [];
  loadingConfiguration = false;
  loadingDictionary = false;
  loadingFinanciadores = false;
  loadingUpdate = false;
  loadingUploadImage = false;

  financiadores = null;
  validacionFinanciador = null;
  validacionCantidades = null;
  validacionDiagnostico = null;
  mensajeExitoso = null;
  errorUpdate = null;
  openCamposObligatorios = false;
  images = [];
  logoReceta = null;

  coloresEstandar = {
    logo: null,
    backgroundColorPrimary: "#1976D2",
    backgroundColorSecondary: "#E3EEF9",
    backgroundColorAccent: "#E3E3E3",
    colorTextPrimary: "#1976D2",
    colorTextSecondary: "#000000",
    colorTextAccent: "#B9B9B9",
  };

  constructor(appId, navigate) {
    makeAutoObservable(this, {
      coloresEstandar: observable,
      application: observable,
      loadingConfiguration: observable,
      dataFormulario: observable,
      estilosFormulario: observable,
      secciones: observable,
      vinculateAllFinanciers: observable,
      error: observable,
      dataDictionary: observable,
      loadingDictionary: observable,
      financiadores: observable,
      loadingFinanciadores: observable,
      loadingUploadImage: observable,
      selectedFinanciers: observable,
      validacionFinanciador: observable,
      validacionCantidades: observable,
      mensajeExitoso: observable,
      errorUpdate: observable,
      openCamposObligatorios: observable,
      validacionDiagnostico: observable,
      images: observable,
      logoReceta: observable,
      getConfiguration: action,
      goToPage: action,
      addFinanciadores: action,
      onChangeFinanciadores: action,
      handleOnChangeStyles: action,
      handleOnChangeLogo: action,
      handleSetSameLogo: action,
      handleChangeSection: action,
      handleChangeFields: action,
      handleOnChange: action,
      guardarConfiguration: action,
      getDescripcion: action,
      onChangeImage: action,
      onDeleteImage: action,
    });

    this.appId = appId;
    this.navigate = navigate;
  }

  getConfiguration = async (data) => {
    this.application = data;

    try {
      this.loadingConfiguration = true;
      var camposObligatorios = [];
      const responseConfiguration = await ConfigurationService.getConfiguration(
        this.appId
      );

      if (responseConfiguration.status === "Ok") {
        this.configurationId = responseConfiguration.result.configuracionId;
        this.dataFormulario = responseConfiguration.result.dataFormulario;
        this.estilosFormulario = responseConfiguration.result.estilos;
        this.secciones = {
          showHeaderSection: responseConfiguration.result.showHeaderSection,
          showMedicoSection: responseConfiguration.result.showMedicoSection,
          showPacienteSection: responseConfiguration.result.showPacienteSection,
        };
        this.vinculateAllFinanciers =
          responseConfiguration.result.vinculaTodosFinanciadores;

        camposObligatorios = responseConfiguration.result.camposObligatorios;

        if (responseConfiguration.result.financiadores.length > 0) {
          this.selectedFinanciers =
            responseConfiguration.result.financiadores.map((item) => {
              return {
                id: item.nrofinanciador,
                descripcion: item.nombreComercial,
              };
            });
        }
        this.loadingConfiguration = false;
      } else {
        this.error = responseConfiguration.errors[0];
        this.loadingConfiguration = false;
      }
      this.loadingDictionary = true;

      this.loadingUploadImage = true;

      try {
        const responseLogo = await ConfigurationService.getLogo(
          "0",
          this.appId
        );

        if (responseLogo.status === "Ok") {
          runInAction(() => {

            this.logoReceta = responseLogo.result.s3Link;
            this.loadingUploadImage = false;
          });
        } else {
          this.loadingUploadImage = false;
        }
      } catch (e) {
        this.loadingUploadImage = false;
        console.log(e);
      }

      const responseDictionary =
        await ConfigurationService.getMaestrosCamposConfigurables(this.appId);

      if (responseDictionary.status === "Ok") {
        if (responseDictionary.result.configurados.length > 0) {
          this.dataDictionary = responseDictionary.result.configurados.map(
            (item) => {
              return {
                source: item.source,
                campos: item.campos.map((c) => {
                  return {
                    campo: c,
                    descripcion: this.getDescripcion(c),
                  };
                }),
              };
            }
          );
        }
        if (responseDictionary.result.maestros.length > 0) {
          this.dataMaestros = responseDictionary.result.maestros;
        }

        this.loadingDictionary = false;
      } else {
        this.error = responseDictionary.errors[0];
        this.loadingDictionary = false;
      }

      this.loadingFinanciadores = true;
      const responseFinanciers = await ConfigurationService.getFinanciadores(
        data.accessKey
      );
      if (responseFinanciers.status === "Ok") {
        this.financiadores = responseFinanciers.results;
        this.loadingFinanciadores = false;
      } else {
        this.error = responseFinanciers.errors[0];
        this.loadingFinanciadores = false;
      }
    } catch (err) {
      this.loadingConfiguration = false;
      this.loadingDictionary = false;
      this.loadingFinanciadores = false;
      this.error = "Error al obtener la configuración. Inténtelo más tarde.";
    }
  };

  onDeleteImage = async () => {
    try {
      this.loadingUploadImage = true;
      const response = await ConfigurationService.deleteLogo("0", this.appId);

      this.loadingUploadImage = false;

      if (response.status === "Ok") {
        this.logoReceta = null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChangeImage = async (event) => {

    try {
      this.loadingUploadImage = true;
      const response = await ConfigurationService.saveLogo(
        "0",
        this.appId,
        event.target.files[0]
      );

      if (response.status === "Ok") {
        this.logoReceta = response.result.s3Link;
        this.loadingUploadImage = false;
      } else {
        this.logoReceta = URL.createObjectURL(event.target.files[0]);
        this.loadingUploadImage = false;
      }

    } catch (error) {
      this.loadingUploadImage = false;
      console.log(error);
    }
  };

  goToPage = (action) => {
    if (action === "back") {
      this.navigate("/configuration");
    } else if (action === "formulario") {
      this.navigate(`/formulario-vista-previa/${this.appId}`);
    }
  };

  addFinanciadores = (event, value) => {
    if (this.validacionFinanciador) this.validacionFinanciador = null;
    this.selectedFinanciers = value;
  };

  onChangeFinanciadores = () => {
    if (this.validacionFinanciador) this.validacionFinanciador = null;
    this.vinculateAllFinanciers = !this.vinculateAllFinanciers;
  };

  handleOnChangeStyles = (color, name) => {
    this.estilosFormulario = { ...this.estilosFormulario, [name]: color.hex };
  };

  handleOnChangeLogo = (event) => {
    this.estilosFormulario = {
      ...this.estilosFormulario,
      logo: event.target.value,
    };
  };

  handleSetSameLogo = (logo) => {
     this.estilosFormulario = {
       ...this.estilosFormulario,
       logo: logo,
     };
  }

  handleChangeSection = (item, value) => {
    this.secciones = { ...this.secciones, [item]: !value };
  };

  getDescripcion = (item) => {
    if (item === "FechaNac") return "FechaNacimiento";
    else if (item === "Matricula") return "NumeroMatricula";
    else if (item === "NroDoc") return "NroDocumento";
    else if (item === "TipoDoc") return "TipoDocumento";
    else return item;
  };

  handleOpenCamposObligatorios = () => {
    this.dataMaestrosMedico = this.dataMaestros
      .filter((item) => item.source === "Medico")[0]
      .campos.filter((field) => field !== "ProvinciaMatricula")
      .map((item) => {
        return {
          campo: item,
          descripcion: this.getDescripcion(item),
          select:
            this.dataDictionary
              .filter((p) => p.source === "Medico")[0]
              .campos.filter((c) => c.campo === item).length > 0,
          disabled: item === "TipoMatricula" || item === "Matricula",
        };
      });
    this.dataMaestrosPaciente = this.dataMaestros
      .filter((item) => item.source === "Paciente")[0]
      .campos.filter(
        (field) =>
          field !== "Localidad" && field !== "Provincia" && field !== "Telefono"
      )
      .map((item) => {
        return {
          campo: item,
          descripcion: item,
          select:
            this.dataDictionary
              .filter((p) => p.source === "Paciente")[0]
              .campos.filter((c) => c.campo === item).length > 0,
          disabled: false,
        };
      });
    this.openCamposObligatorios = true;
  };

  handleCloseCamposObligatorios = () => {
    this.openCamposObligatorios = false;
  };

  handleChangeFields = (entidad, field) => {
    if (entidad === "Paciente") {
      this.dataMaestrosPaciente = this.dataMaestrosPaciente.map((obj) => {
        if (obj.campo === field) {
          return { ...obj, select: !obj.select };
        }
        return obj;
      });
    } else {
      this.dataMaestrosMedico = this.dataMaestrosMedico.map((obj) => {
        if (obj.campo === field) {
          return { ...obj, select: !obj.select };
        }
        return obj;
      });
    }
  };

  saveCamposObligatorios = async () => {
    try {
      let payload = {};
      payload.aplicacionId = this.appId;

      let configuracion = [];

      let medico = {};
      medico.source = "Medico";
      medico.camposObligatorios = this.dataMaestrosMedico
        .filter((p) => p.select)
        .map((p) => {
          return p.campo;
        });

      configuracion.push(medico);

      let paciente = {};
      paciente.source = "Paciente";
      paciente.camposObligatorios = this.dataMaestrosPaciente
        .filter((p) => p.select)
        .map((p) => {
          return p.campo;
        });

      configuracion.push(paciente);

      payload.configuracion = configuracion;

      let result = await ConfigurationService.setCamposConfigurados(payload);

      if (result.status === "Ok") {
        let config = [];

        let medico = {};
        medico.source = "Medico";
        medico.camposObligatorios = this.dataMaestrosMedico
          .filter((p) => p.select)
          .map((p) => {
            return { campo: p.campo, descripcion: p.descripcion };
          });

        config.push(medico);

        let paciente = {};
        paciente.source = "Paciente";
        paciente.camposObligatorios = this.dataMaestrosPaciente
          .filter((p) => p.select)
          .map((p) => {
            return { campo: p.campo, descripcion: p.descripcion };
          });

        config.push(paciente);
        this.dataDictionary = config.map((p) => {
          return { source: p.source, campos: p.camposObligatorios };
        });

        this.openCamposObligatorios = false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleOnChange = (name, event) => {
    if (
      name === "cantidadMedicamentoPorReceta" ||
      name === "cantidadRecetasPostdatadas" ||
      name === "cantidadLineasPorReceta"
    ) {
      if (event.target.value !== "0" && this.validacionCantidades) {
        this.validacionCantidades = null;
      }
      this.dataFormulario = {
        ...this.dataFormulario,
        [name]: event.target.value,
      };
    } else if (name === "diagnosticoGeneral") {
      this.dataFormulario = {
        ...this.dataFormulario,
        [name]: !this.dataFormulario.diagnosticoGeneral,
      };
    } else if (name === "diagnosticoPorMedicamento") {
      this.dataFormulario = {
        ...this.dataFormulario,
        [name]: !this.dataFormulario.diagnosticoPorMedicamento,
      };
    }
  };

  guardarConfiguration = () => {
    if (this.selectedFinanciers.length === 0 && !this.vinculateAllFinanciers)
      this.validacionFinanciador =
        "Debe asociar al menos un financiador en la configuración";
    else this.validacionFinanciador = null;

    if (
      this.dataFormulario.cantidadMedicamentoPorReceta.toString() === "0" ||
      this.dataFormulario.cantidadLineasPorReceta.toString() === "0"
    )
      this.validacionCantidades =
        "Las cantidad de líneas por receta no puede ser 0.";
    else this.validacionCantidades = null;

    if (
      Number(this.dataFormulario.cantidadMedicamentoPorReceta) <= 0 ||
      Number(this.dataFormulario.cantidadRecetasPostdatadas) < 0 ||
      Number(this.dataFormulario.cantidadLineasPorReceta) <= 0
    )
      this.validacionCantidades = "El valor ingresado es incorrecto.";
    else this.validacionCantidades = null;

    if (
      !this.dataFormulario.diagnosticoGeneral &&
      !this.dataFormulario.diagnosticoPorMedicamento
    ) {
      this.validacionDiagnostico =
        "Debe seleccionar al menos una diágnostico para la receta.";
    } else this.validacionDiagnostico = null;

    const payload = {};
    payload.configuracionId = this.configurationId;
    payload.logo = this.estilosFormulario.logo
      ? this.estilosFormulario.logo
      : "";
    payload.backgroundColorPrimary = this.estilosFormulario
      .backgroundColorPrimary
      ? this.estilosFormulario.backgroundColorPrimary
      : this.coloresEstandar.backgroundColorPrimary;
    payload.backgroundColorSecondary = this.estilosFormulario
      .backgroundColorSecondary
      ? this.estilosFormulario.backgroundColorSecondary
      : this.coloresEstandar.backgroundColorSecondary;
    payload.backgroundColorAccent = this.estilosFormulario.backgroundColorAccent
      ? this.estilosFormulario.backgroundColorAccent
      : this.coloresEstandar.backgroundColorAccent;
    payload.colorTextPrimary = this.estilosFormulario.colorTextPrimary
      ? this.estilosFormulario.colorTextPrimary
      : this.coloresEstandar.colorTextPrimary;
    payload.colorTextSecondary = this.estilosFormulario.colorTextSecondary
      ? this.estilosFormulario.colorTextSecondary
      : this.coloresEstandar.colorTextSecondary;
    payload.colorTextAccent = this.estilosFormulario.colorTextSecondary
      ? this.estilosFormulario.colorTextAccent
      : this.coloresEstandar.colorTextAccent;
    payload.vinculaTodosFinanciadores = this.vinculateAllFinanciers;
    payload.diagnosticoGeneral = this.dataFormulario.diagnosticoGeneral;
    payload.diagnosticoPorMedicamento =
      this.dataFormulario.diagnosticoPorMedicamento;
    payload.camposObligatorios = [];
    payload.cantidadMedicamentoPorReceta = Number(
      this.dataFormulario.cantidadMedicamentoPorReceta
    );
    payload.cantidadRecetasPostdatadas = Number(
      this.dataFormulario.cantidadRecetasPostdatadas
    );
    payload.cantidadLineasPorReceta = Number(
      this.dataFormulario.cantidadLineasPorReceta
    );
    payload.financiadores = this.vinculateAllFinanciers
      ? []
      : this.selectedFinanciers;
    payload.showHeaderSection = this.secciones.showHeaderSection;
    payload.showMedicoSection = this.secciones.showMedicoSection;
    payload.showPacienteSection = this.secciones.showPacienteSection;

    if (
      !this.validacionCantidades &&
      !this.validacionFinanciador &&
      !this.validacionDiagnostico
    ) {
      this.loadingUpdate = true;
      this.error = null;

      ConfigurationService.updateConfiguration(payload)
        .then((response) => {
          if (response.status === "Ok") {
            this.mensajeExitoso = "Configuración actualizada";
            this.loadingUpdate = false;
          } else {
            this.errorUpdate = response.errors[0];
            this.loadingUpdate = false;
          }
        })
        .catch(function (error) {
          this.loadingUpdate = false;
          this.error = "Error al guardar la configuración";
        });
    }
  };
}

export default Store;
