import React from "react";
import { Typography, Box, Button, Stack, Alert, AlertTitle} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import LeyendaReceta from "./panel-receta/leyenda-receta";
import DiagnosticoGeneral from "./panel-receta/diagnostico-general";
import RecetaPosdatada from "./panel-receta/receta-posdatada";
import SinMedicamentosCargados from "./panel-receta/sin-medicamentos-cargados";
import CardMedicamento from "./card-medicamento";
import TratamientoProlongado from "./panel-receta/tratamiento-prolongado";

const PanelReceta = (props) => {
  const {
    onClick,
    diagnosticos,
    diagnostico,
    setDiagnostico,
    imprimirCodigo,
    medicamentosReceta,
    estilos,
    dataForm,
    cantidadRecetas,
    posdatadas,
    limitePosdatadas,
    onInputChange,
    leyenda,
    onChangeLeyenda,
    tratamientoProlongado,
    handleChangePostdatada,
    error,
  } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.card}>
      <Typography
        sx={{
          m: 1,
          fontSize: "1.10rem",
          color: estilos.colorTextSecondary,
          fontWeight: "bold",
        }}
      >
        Prescripción de recetas
      </Typography>
      <Box
        sx={{
          padding: "15px 0px",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{
            m: 1,
            fontSize: "0.91rem",
            color: estilos.colorTextSecondary,
            fontWeight: "500",
          }}
        >
          Medicamentos
        </Typography>

        <Button
          startIcon={<AddIcon fontSize="small" />}
          variant="outlined"
          disabled={
            medicamentosReceta.length >= dataForm.cantidadLineasPorReceta
          }
          sx={{
            mr: 1,
            fontSize: "0.68rem",
            fontWeight: "500",
            color: estilos.backgroundColorPrimary,
            "&.MuiButton-outlinedPrimary": {
              border: "1px solid",
              borderColor: estilos.backgroundColorPrimary,
            },
          }}
          onClick={() => onClick("add-medicamento")}
        >
          AGREGAR MEDICAMENTO
        </Button>
      </Box>
      {medicamentosReceta.length > 0 && (
        <Stack
          spacing={2}
          sx={{
            margin: "10px 0px 30px",
          }}
        >
          {medicamentosReceta.map((medicamento, index) => {
            return (
              <CardMedicamento
                key={index}
                medicamento={medicamento}
                estilos={estilos}
                onClick={onClick}
              />
            );
          })}
        </Stack>
      )}
      {medicamentosReceta.length === 0 && (
        <Box
          sx={{
            margin: "10px 0px 30px",
            alignItems: "center",
          }}
        >
          <SinMedicamentosCargados />
        </Box>
      )}
      <TratamientoProlongado
        estilos={estilos}
        handleOnChange={onClick}
        checked={tratamientoProlongado}
      />
      <LeyendaReceta
        estilos={estilos}
        value={leyenda}
        onChange={onChangeLeyenda}
      />
      {dataForm.diagnosticoGeneral && (
        <DiagnosticoGeneral
          estilos={estilos}
          title={"Diagnóstico general"}
          data={diagnosticos}
          diagnostico={diagnostico}
          setDiagnostico={setDiagnostico}
          handleOnChange={onClick}
          checked={imprimirCodigo}
          cantidadRecetas={cantidadRecetas}
          onInputChange={onInputChange}
        />
      )}
      <RecetaPosdatada
        estilos={estilos}
        cantidadRecetas={cantidadRecetas}
        onClick={onClick}
        posdatadas={posdatadas}
        handleChange={handleChangePostdatada}
        limitePosdatadas={limitePosdatadas}
      />
      {error && (
        <Box
          sx={{
            padding: "5px 0px",
            alignItems: "center",
          }}
        >
          <Alert severity="error">
            <AlertTitle>Error al generar la receta</AlertTitle>
            {error}
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          padding: "15px 0px",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              backgroundColor: estilos.backgroundColorPrimary,
              "&:hover": {
                backgroundColor: estilos.backgroundColorPrimary,
                opacity: 0.8,
              },
            }}
            variant="contained"
            disabled={
              medicamentosReceta.length === 0 ||
              (!diagnostico &&
              (medicamentosReceta.filter((p) => p.diagnostico).length !== medicamentosReceta.length))
            }
            onClick={() => onClick("generar-receta")}
          >
            CONFIRMAR RECETA
          </Button>
          {/* <Button
            variant="outlined"
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              color: estilos.backgroundColorPrimary,
              "&.MuiButton-outlinedPrimary": {
                border: "1px solid",
                borderColor: estilos.backgroundColorPrimary,
              },
            }}
            onClick={() => onClick("cancelar")}
          >
            CANCELAR
          </Button> */}
        </Stack>
      </Box>
    </div>
  );
};

export default PanelReceta;

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    borderRadius: 7,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    margin: "5px",
    padding: "16px",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
  },
}));
