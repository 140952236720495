import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { Typography} from "@mui/material";

const ListItemText = (props) => {
  const { label, data, color } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      <Typography
        sx={{
          fontSize: "12.5px",
          lineHeight: "22px",
          color: {color},
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontSize: "16.4px",
          lineHeight: "22px",
          fontWeight: "500",
          color: {color},
        }}
      >
        {data}
      </Typography>
    </div>
  );
};

export default ListItemText;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginBottom: 5
  },
}));
