/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_APLICATION_LOAD,
  ADD_APLICATION_SUCCESS,
  ADD_APLICATION_ERROR,
} from "../../../constants/actionTypes";
import axiosInstance from "../../../helpers/axiosInstance";
import { CONNECTION_ERROR } from "../../../constants/apiConstants";

export default (payload) => (dispatch) => {
  dispatch({
    type: ADD_APLICATION_LOAD,
  });
  axiosInstance
    .post("backoffice/Aplicacion/Create", payload)
    .then((res) => {
      if (res.data.status !== "Error") {
        console.log("ALTA APP", res.data.result);
        dispatch({
          type: ADD_APLICATION_SUCCESS,
          payload: res.data.result,
        });
      } else {
        dispatch({
          type: ADD_APLICATION_ERROR,
          payload: res.data.errors[0],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ADD_APLICATION_ERROR,
        payload: err.response ? err.response.data : CONNECTION_ERROR,
      });
    });
};
