import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const UserEditToolbar = (props) => {
  const { onClick } = props;
  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <IconButton aria-label="back" onClick={() => onClick("back")}>
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography
          sx={{ m: 1, fontSize: "1.45rem", color: "primary.dark" }}
          variant="h4"
        >
          Editar usuario
        </Typography>
      </Box>
    </Box>
  );
};
