import React, { useState } from "react";
import useStyles from "./styles";
import { theme } from "../../theme";
import {
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";

const HistorialRecetas = (props) => {
  const {
    showRecord,
    record,
    setRecord,
    unsubscribeRecipe,
    getResendRecipe,
    createRecipeWithOldRecipe,
  } = props;
  const classes = useStyles(theme);

  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [orderDate, setOrderDate] = useState("desc");
  const [orderState, setOrderState] = useState("vig");
  const [shorOrderState, setShortOrderState] = useState("des");

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSort = (filter) => {
    if (filter === "date") {
      if (orderDate === "asc") {
        setOrderDate("desc");
        setRecord(
          record.sort((a, b) => {
            return a.fecha > b.fecha ? -1 : a.fecha < b.fecha ? 1 : 0;
          })
        );
      } else {
        setOrderDate("asc");
        setRecord(
          record.sort((a, b) => {
            return a.fecha < b.fecha ? -1 : a.fecha > b.fecha ? 1 : 0;
          })
        );
      }
    }
    if (filter === "state") {
      const recordVigentes = record.filter((item) => {
        return item.estado === "VIGENTE";
      });
      const recordVencidos = record.filter((item) => {
        return item.estado === "VENCIDA";
      });
      if (orderState === "vig") {
        setOrderState("ven");
        setShortOrderState("asc");
        setRecord(recordVencidos.concat(recordVigentes));
      }
      if (orderState === "ven") {
        setOrderState("vig");
        setShortOrderState("desc");
        setRecord(recordVigentes.concat(recordVencidos));
      }
    }
  };
  return (
    <div className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <Typography className={classes.title} sx={{ m: 1 }}>
            Historial de Recetas
          </Typography>
        </Grid>
        <Grid
          container
          xs={4}
          sm={4}
          md={4}
          lg={4}
          direction="row"
          justifyContent="flex-end"
          alignItems="end"
        >
          <Button
            variant="outlined"
            className={classes.goBackButton}
            onClick={() => (showRecord ? showRecord() : null)}
          >
            VOLVER
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.ml2rem}>
          <TableContainer>
            <Table>
              <TableHead className={classes.bg}>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.standardCellHeaderTable}
                  >
                    Número
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.standardCellHeaderTable}
                  >
                    <TableSortLabel
                      direction={orderDate}
                      onClick={() => handleSort("date")}
                      hideSortIcon={false}
                      active={true}
                      sx={{
                        color: "#706e6e !important",
                      }}
                    >
                      Fecha
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.standardCellHeaderTable}
                  >
                    <TableSortLabel
                      direction={shorOrderState}
                      hideSortIcon={false}
                      active={true}
                      onClick={() => handleSort("state")}
                      sx={{
                        color: "#706e6e !important",
                      }}
                    >
                      Estado
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.standardCellHeaderTable}
                    sx={{
                      width: "40%",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.length > 0 &&
                  record
                    .slice(page * limit, page * limit + limit)
                    .map((row, index) => (
                      <>
                        <TableRow
                          className={classes.p0Mb0}
                          key={row.id + index}
                        >
                          <TableCell
                            align="left"
                            className={classes.standardCellBodyTable}
                          >
                            {row.numero}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.standardCellBodyTable}
                          >
                            {dayjs(row.fecha)
                              .add(12, "hour")
                              .format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.standardCellBodyTable}
                          >
                            {row.estado}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.cellButtonAction}
                          >
                            {row.estado === "VIGENTE" ? (
                              <>
                                <Button
                                  variant="outlined"
                                  className={classes.actionButton}
                                  onClick={() => {
                                    if (unsubscribeRecipe) {
                                      unsubscribeRecipe(
                                        {
                                          recetaId: row.id,
                                          estado: row.estado,
                                        },
                                        "unsubscribeRecipe"
                                      );
                                    }
                                  }}
                                >
                                  BAJA
                                </Button>
                                <Button
                                  variant="outlined"
                                  className={classes.actionButton}
                                  onClick={() => {
                                    if (getResendRecipe) {
                                      getResendRecipe(
                                        {
                                          recetaId: row.numero,
                                          estado: row.estado,
                                          hash: row.hash,
                                        },
                                        "getResendRecipe"
                                      );
                                    }
                                  }}
                                >
                                  REENVIAR
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                            <Tooltip title="Generar nueva receta tomando ésta como base">
                              <Button
                                variant="outlined"
                                className={classes.actionButton}
                                onClick={() => {
                                  if (createRecipeWithOldRecipe) {
                                    createRecipeWithOldRecipe(
                                      {...row.recipe, recetaId: row.idReceta},
                                      "createRecipeWithOldRecipe"
                                    );
                                  }
                                }}
                              >
                                Nueva en base...
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        {row.medicamentos.map((medicamento, index) => (
                          <TableRow
                            className={classes.pM0}
                            key={index + medicamento.nombreDroga}
                          >
                            <TableCell
                              colSpan={3}
                              className={classes.cellMediceName}
                              sx={{
                                paddingBottom:
                                  row.medicamentos.length - 1 == index
                                    ? "0.5rem !important"
                                    : "",
                              }}
                            >
                              {medicamento.nombreDroga} -{" "}
                              {medicamento.presentacion} -{" "}
                              {medicamento.nombreProducto}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className={classes.pM0} key={index + row.id}>
                          <TableCell
                            colSpan={3}
                            className={classes.cellMediceName}
                            sx={{
                              borderBottom: "2px solid #cecece",
                              paddingBottom: "0.1rem !important",
                            }}
                          ></TableCell>
                        </TableRow>
                      </>
                    ))}
                {(record.length === 0 || !record) && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={4}
                      className={classes.standardCellBodyTable}
                    >
                      No hay recetas para mostrar
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {record.length > 5 ? (
                <TablePagination
                  count={record.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  labelRowsPerPage="Filas por página"
                  rowsPerPageOptions={[5, 10, 25]}
                />
              ) : (
                <br></br>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default HistorialRecetas;
