import {
  CONFIGURATION_APP_LOAD_SUCCESS,
  CONFIGURATION_APP_LOAD_ERROR,
  CONFIGURATION_APP_LOADING,
} from "../../constants/actionTypes";

const configuration = (state, { payload, type }) => {
  switch (type) {
    case CONFIGURATION_APP_LOADING: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          loadingConfig: true,
        },
      };
    }

    case CONFIGURATION_APP_LOAD_ERROR: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          errorConfig: payload,
          loadingConfig: false,
        },
      };
    }

    case CONFIGURATION_APP_LOAD_SUCCESS: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          loadingConfig: false,
          dataConfig: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default configuration;
