/* eslint-disable import/no-anonymous-default-export */
import {
  UPDATE_USER_LOAD,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "../../../constants/actionTypes";
import axiosInstance from "../../../helpers/axiosInstance";
import { CONNECTION_ERROR } from "../../../constants/apiConstants";

export default (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_LOAD,
  });
  axiosInstance
    .put("backoffice/UsuarioBackoffice/Edit", payload)
    .then((res) => {
      if (res.data.status !== "Error") {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: res.data.result,
        });
      } else {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: res.data.errors[0],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: err.response ? err.response.data : CONNECTION_ERROR,
      });
    });
};
