import * as Yup from "yup";

export const UsuarioSchema = Yup.object().shape({
  nombre: Yup.string().required("Por favor ingrese el campo nombre"),
  apellido: Yup.string().required("Por favor ingrese el campo apellido"),
  rol: Yup.string().required("Por favor seleccione el rol"),
  email: Yup.string()
    .email("Por favor ingrese un email válido")
    .required("Por favor ingrese un email"),
  password: Yup.string()
    .required("Por favor ingrese una contraseña válida")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "La contraseña debe tener como mínimo 8 caracteres y contener al menos una mayúscula, una minúscula, un número y un caracter especial"
    ),
  repetirPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Ambas contraseñas deben coincidir"
    ),
  }),
});

export const UsuarioDataSchema = Yup.object().shape({
  nombre: Yup.string().required("Por favor ingrese el campo nombre"),
  apellido: Yup.string().required("Por favor ingrese el campo apellido"),
  rol: Yup.string().required("Por favor seleccione el rol"),
  email: Yup.string()
    .email("Por favor ingrese un email válido")
    .required("Por favor ingrese un email"),
});

export const CredencialesSchema = Yup.object().shape({
  user: Yup.string().required("Por favor ingrese el campo usuario"),
  pass: Yup.string().required("Por favor ingrese el campo password"),
  prefijo: Yup.string().required("Por favor ingrese un número válido"),
});



export const CambiarPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Por favor ingrese una contraseña válida")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "La contraseña debe tener como mínimo 8 caracteres y contener al menos una mayúscula, una minúscula, un número y un caracter especial"
    ),
  repetirPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Ambas contraseñas deben coincidir"
    ),
  }),
});

export const ClientSchema = Yup.object().shape({
  appName: Yup.string().required("Por favor ingrese el campo nombre"),
  appUser: Yup.string().required("Por favor ingrese el nombre de usuario. Debe ser único."),
  appUrl: Yup.string().required("Por favor ingrese el campo apellido"),
  email: Yup.string()
    .email("Por favor ingrese un email válido")
    .required("Por favor ingrese un email"),
  razonSocial: Yup.string().required("Por favor ingrese el campo razón social"),
  personaFisica: Yup.boolean(),
  nroDoc: Yup.string().required(
    "Por favor ingrese el campo número de documento"
  ),
  cuit: Yup.string().required("Por favor ingrese el campo CUIT"),
  // nroDoc: Yup.string().when("personaFisica", {
  //   is: (val) => val,
  //   then: Yup.string().required(
  //     "Por favor ingrese el campo número de documento"
  //   ),
  // }),
  // cuit: Yup.string().when("personaFisica", {
  //   is: (val) => !val,
  //   then: Yup.string().required("Por favor ingrese el campo CUIT"),
  // }),
  aceptaTerminos: Yup.boolean()
    .required(
      "Para el alta del cliente de aplicación deben aceptarse los términos y condiciones"
    )
    .oneOf(
      [true],
      "Para el alta del cliente de aplicación deben aceptarse los términos y condiciones"
    ),
});
