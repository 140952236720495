import React, { useContext, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { GlobalContext } from "../../context/Provider";
import getAplications from "../../context/actions/aplications/getAplications";
import { DocumentacionMainToolbar } from "../../components/documentacion/documentacion-main-toolbar";
import { EndpointLogin } from "../../components/documentacion/endpoints/endpoint-login";
import { EndpointRefresh } from "../../components/documentacion/endpoints/endpoint-refresh";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EndpointGenerateForm } from "../../components/documentacion/endpoints/endpoint-generate-form";

const Documentacion = () => {
  const { aplicationsDispatch, aplicationsState } = useContext(GlobalContext);
  const [expandedLogin, setexpandedLogin] = React.useState(false);
  const [expandedRefresh, setExpandedRefresh] = React.useState(false);
  const [expandedForm, setExpandedForm] =
    React.useState(false);

  const {
    aplications: { data, loading },
  } = aplicationsState;

  useEffect(() => {
    if (data.length === 0) {
      getAplications()(aplicationsDispatch);
    }
  }, []);

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ my: 3 }}>
            <DocumentacionMainToolbar />
            <Grid sx={{ my: 2 }}>
              <Typography
                sx={{
                  fontSize: "1.10rem",
                  color: "primary.dark",
                  fontWeight: "700",
                }}
              >
                {" "}
                Base URL
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.00rem",
                  color: "#067acc",
                  my: 1,
                }}
              >
                {process.env.REACT_APP_API_BASE_URL}
              </Typography>
            </Grid>
            <Grid sx={{ my: 5 }}>
              <Typography sx={{ fontSize: "1.00rem", color: "primary.dark" }}>
                {" "}
                Los siguientes API endpoint se utilizan para generar un
                formulario de receta.
              </Typography>
            </Grid>

            <Box>
              <Accordion
                expanded={expandedLogin}
                onChange={() => setexpandedLogin(!expandedLogin)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel12-header"
                  sx={{
                    background: "#D4F5E6",
                    borderRadius: 1,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      md={1}
                      xs={2}
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Box
                        sx={{
                          background: "#49cc90",
                          borderRadius: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          POST
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={11}
                      xs={11}
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.10rem",
                          color: "primary.dark",
                          fontWeight: "bold",
                        }}
                      >
                        Login
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "10px 30px 30px 30px" }}>
                  <EndpointLogin />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedRefresh}
                onChange={() => setExpandedRefresh(!expandedRefresh)}
                sx={{ my: 2 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel12-header"
                  sx={{
                    background: "#D4F5E6",
                    borderRadius: 1,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      md={1}
                      xs={2}
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Box
                        sx={{
                          background: "#49cc90",
                          borderRadius: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          POST
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={11}
                      xs={11}
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.10rem",
                          color: "primary.dark",
                          fontWeight: "bold",
                        }}
                      >
                        Refresh Token
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "10px 30px 30px 30px" }}>
                  <EndpointRefresh />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedForm}
                onChange={() => setExpandedForm(!expandedForm)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel12-header"
                  sx={{
                    background: "#D4F5E6",
                    borderRadius: 1,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      md={1}
                      xs={2}
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Box
                        sx={{
                          background: "#49cc90",
                          borderRadius: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          POST
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={11}
                      xs={11}
                      sx={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.10rem",
                          color: "primary.dark",
                          fontWeight: "bold",
                        }}
                      >
                        Generar link de formulario
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "10px 30px 30px 30px" }}>
                  <EndpointGenerateForm />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default Documentacion;
