import { Box, Typography, Button, Divider, Grid } from "@mui/material";

export const EndpointLogin = (props) => {
  const { onClick } = props;
  return (
    <Box {...props}>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Descripción
        </Typography>
        <Typography
          sx={{
            fontSize: "1.00rem",
            color: "primary.dark",
            my: 1,
          }}
        >
          Api para autenticación y generación de token de sesión. Este token es
          necesario para poder generar el link de formulario de receta.
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Resource URL
        </Typography>
        <Typography
          sx={{
            fontSize: "1.00rem",
            color: "#067acc",
          }}
        >
          Aplicacion/login
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Parámetros
        </Typography>
        <Typography
          sx={{
            fontSize: "0.92rem",
            color: "primary.dark",
          }}
        >
          No se requieren parámetros
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Request Body
        </Typography>
        <Box sx={{ my: 2, p: 2, background: "#f3f7fa", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "username": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "accessKey": "string"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
        <Box sx={{ px: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              md={1}
              xs={2}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  fontSize: "0.98rem",
                  color: "primary.dark",
                  fontWeight: "bold",
                  fontFamily: "Courier New,Courier,monospace!important",
                }}
              >
                username:
              </Typography>
            </Grid>
            <Grid
              item
              md={11}
              xs={11}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  fontSize: "0.95rem",
                  color: "primary.dark",
                  fontFamily: "Courier New,Courier,monospace!important",
                }}
              >
                Se crear por el administrador al dar de alta la aplicación en el
                sistema. Es única por aplicación. Para acceder a este valor de
                la aplicación ingresar al detalle.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              md={1}
              xs={2}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  fontSize: "0.98rem",
                  color: "primary.dark",
                  fontWeight: "bold",
                  fontFamily: "Courier New,Courier,monospace!important",
                }}
              >
                accessKey:
              </Typography>
            </Grid>
            <Grid
              item
              md={11}
              xs={11}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  fontSize: "0.95rem",
                  color: "primary.dark",
                  fontFamily: "Courier New,Courier,monospace!important",
                }}
              >
                Se crear automáticamente al crear una nueva aplicación. Para
                acceder a este valor de la aplicación ingresar al detalle.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Request example
        </Typography>
        <Box sx={{ my: 2, p: 2, background: "#f3f7fa", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "username": "app-data-test",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "accessKey": "BP-lg09hBKaaaffFDLSa4DnOAhFFpWvr2aWw"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Response examples
        </Typography>
        <Box sx={{ my: 2, p: 2, background: "#F7FAEC", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.98rem",
              fontWeight: "bold",
              color: "primary.dark",
            }}
          >
            Code: 200 - <span style={{ fontWeight: "Bold" }}>Status: OK</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "result": {"{"}
          </Typography>
          <Box>
            <Typography
              sx={{
                fontFamily: "Courier New,Courier,monospace!important",
                fontSize: "0.94rem",
                color: "primary.dark",
                pl: 6,
              }}
            >
              "accessToken":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjE1NmU5Y2YzLWY2YTYtNDJiZS05NjFmLWFiNz",
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "refreshToken":
            "aAbkowtAMNRqXDFZX6IBwzgDahh8KQXgqW453Z6lYpihBSojjAa3KeN1ahuLk0IAuBBnvQLMZ9L/SzEM3GI0Vw=="
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"}"},
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "status": "Ok",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "successMessage": "",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "errors": {"[]"},
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "validationErrors": {"[]"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>{" "}
        <Box sx={{ my: 2, p: 2, background: "#F7FAEC", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.98rem",
              fontWeight: "700",
              color: "primary.dark",
            }}
          >
            Code: 200 - <span style={{ fontWeight: "Bold" }}>Status: Error</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "result": null,
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "status": "Error",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "successMessage": "",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "errors": {"["}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "Error es las credenciales. Por favor intente nuevamente"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"]"},
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "validationErrors": {"[]"},
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
