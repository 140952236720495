import "./client.scss";
import React, { useContext, useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { useNavigate } from "react-router-dom";
import { ClientNewToolbar } from "../../components/clients/client-new-toolbar";
import { ClientNewForm } from "../../components/clients/client-new-form";
import { GlobalContext } from "../../context/Provider";
import clearCreateAplication from "../../context/actions/aplications/clearCreateAplication";
import createAplication from "../../context/actions/aplications/createAplication";

const NewClient = () => {
  const navigate = useNavigate();
  const {
    aplicationsDispatch,
    aplicationsState: {
      addAplication: { loading, error, data },
    },
  } = useContext(GlobalContext);

   useEffect(() => {
     if (data) {
       navigate("/home");
     }
     return () => {
       clearCreateAplication()(aplicationsDispatch);
     };
   }, [data]);
  

  const onSubmit = (values) => {
    const payload = {};
    payload.appName = values.appName;
    payload.appUser = values.appUser;
    payload.appUrl = values.appUrl;
    payload.razonSocial = values.razonSocial;
    payload.email = values.email;
    payload.personaFisica = values.personaFisica;
    payload.tipoDoc = values.tipoDoc;
    payload.nroDoc = values.nroDoc;
    payload.cuit = values.cuit;
    payload.aceptaTerminos = values.aceptaTerminos;
    createAplication(payload)(aplicationsDispatch);
  };

  const executeAction = (action) => {
    if (action === "back") {
      navigate("/home");
    }
  };

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth={false}>
          <ClientNewToolbar onClick={executeAction} />
          <Box sx={{ mt: 4 }}>
            <ClientNewForm onSubmit={onSubmit} loading={loading} error={error}/>
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default NewClient;
