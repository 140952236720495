import "./clients.scss";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Link,
  Box,
} from "@mui/material";
import { SeverityPill } from "../severity-pill";
//import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
//import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

export const ClientInformation = (props) => {
  const { client, onClick } = props;

  return (
    <Card
      sx={{
        border: "0.5px solid #dddddd",
        padding: "1px",
        boxShadow: "1px 1px #d5d5d5",
      }}
    >
      <CardHeader
        subheader="Datos del cliente"
        title="Cliente"
        sx={{ padding: 2 }}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pb: 2,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "0.84rem",
                lineHeight: "1.5rem",
                fontWeight: "600",
                color: "primary.dark",
              }}
            >
              Nombre app
            </Typography>
            <Typography
              sx={{
                fontSize: "0.88rem",
                lineHeight: "1.1rem",
              }}
            >
              {client.appName}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "0.84rem",
                lineHeight: "1.5rem",
                fontWeight: "600",
                color: "primary.dark",
              }}
            >
              Estado
            </Typography>
            <Typography
              sx={{
                fontSize: "0.88rem",
                lineHeight: "1.1rem",
              }}
            >
              <SeverityPill color={client.activo ? "success" : "error"}>
                {client.activo ? "Activo" : "Inactivo"}
              </SeverityPill>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography
            sx={{
              fontSize: "0.84rem",
              lineHeight: "1.5rem",
              fontWeight: "600",
              color: "primary.dark",
            }}
          >
            Url app
          </Typography>
          <Link href={client.appUrl} underline="none" target="_blank">
            <Typography
              sx={{
                fontSize: "0.88rem",
                lineHeight: "1.1rem",
              }}
            >
              {client.appUrl}
            </Typography>
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pb: 2,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "0.84rem",
                lineHeight: "1.5rem",
                fontWeight: "600",
                color: "primary.dark",
              }}
            >
              Razón social
            </Typography>
            <Typography
              sx={{
                fontSize: "0.88rem",
                lineHeight: "1.1rem",
              }}
            >
              {client.razonSocial}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "0.84rem",
                lineHeight: "1.5rem",
                fontWeight: "600",
                color: "primary.dark",
              }}
            >
              {!client.personaFisica ? "CUIT" : client.tipoDoc}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.88rem",
                lineHeight: "1.1rem",
              }}
            >
              {client.tipoDoc === "CUIT" ? client.cuit : client.nroDoc}
            </Typography>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 2,
          }}
        >
          {client.activo ? (
            <Button
              variant="outlined"
              sx={{ fontSize: "0.86rem", lineHeight: "1.1", fontWeight: "500" }}
              color="error"
              onClick={() => onClick("desactivar", client)}
              startIcon={<CancelOutlinedIcon color="error" />}
            >
              Inhabilitar
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{ fontSize: "0.86rem", lineHeight: "1.1", fontWeight: "500" }}
              color="success"
              onClick={() => onClick("activar", client)}
              startIcon={<CheckCircleOutlineOutlinedIcon color="success" />}
            >
              Habilitar
            </Button>
          )}
        </Box> */}
      </CardContent>
    </Card>
  );
};
