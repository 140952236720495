/* eslint-disable import/no-anonymous-default-export */
export default {
  aplications: {
    loading: false,
    error: null,
    data: [],
    isSearchActive: false,
    foundAplication: [],
  },
  addAplication: {
    loading: false,
    error: null,
    data: null,
  },
};
