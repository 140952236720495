import axios from "axios";
import { API_BASE_URL } from "../constants/apiConstants";

const TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function deleteToken() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  //window.location = "/";
}

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const urlRefresh =
      API_BASE_URL + "backoffice/LoginBackoffice/refresh-token";
    if (error.response.status === 401 && originalRequest.url === urlRefresh) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refresh = {
        refreshToken: getRefreshToken(),
      };
      return axios
        .post(urlRefresh, refresh)
        .then((res) => {
          setToken(res.data.result.accessToken);
          setRefreshToken(res.data.result.refreshToken);

          axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.result.accessToken;
          originalRequest.headers["Authorization"] =
            "Bearer " + res.data.result.accessToken;

          return axiosInstance(originalRequest);
        })
        .catch((error) => {
          deleteToken();
        });
    }
  }
);

export default axiosInstance;
