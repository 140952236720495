import "./login.scss";
import React, { useContext, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import { GlobalContext } from "../../context/Provider";
import { login } from "../../context/actions/auth/login";

const Login = () => {
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const navigate = useNavigate();

  const {
    authDispatch,
    authState: {
      auth: { loading, error, data },
    },
  } = useContext(GlobalContext);

  useEffect(() => {
    if (data) {
      navigate("/home");
    }
  }, [data]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };
    login(payload)(authDispatch);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: (data) => onSubmit(data),
  });
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        marginTop: 20,
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 1 }}>
              <Typography color="textPrimary" align="center" variant="h4">
                Backoffice
              </Typography>
              {error && (
                <Typography
                  align="center"
                  sx={{
                    fontSize: "0.84rem",
                    lineHeight: "1.5rem",
                    fontWeight: "300",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                pb: 1,
                pt: 1,
              }}
            >
              <Typography align="center" color="textSecondary" variant="body1">
                Completá los datos para ingresar a la plataforma
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={values.showPassword ? "text" : "password"}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ py: 2 }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Iniciar sesión
              </LoadingButton>
            </Box>
          </form>
        </Card>
      </Container>
    </Box>
  );
};

export default Login;
