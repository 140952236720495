import PropTypes from "prop-types";
import { Box, Divider, Drawer, Typography, useMediaQuery } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { NavItem } from "../nav-item";
import { IconNavItem } from "../icon-nav-item";
import { Logo } from "../../assets/logo";

const items = [
  {
    href: "/home",
    icon: <HomeIcon fontSize="small" />,
    title: "Inicio",
    key: "home",
  },
  {
    href: "/configuration",
    icon: <SettingsIcon fontSize="small" />,
    title: "Configuración",
    key: "configuration",
  },
  {
    href: "/users",
    icon: <GroupIcon fontSize="small" />,
    title: "Usuarios",
    key: "users",
  },
  {
    href: "/documentacion",
    icon: <IntegrationInstructionsIcon fontSize="small" />,
    title: "Documentación",
    key: "documentacion",
  },
];

export const PageSidebar = (props) => {
  const { open, openPersistent, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <div>
          <Box sx={{ px: 2, pt: 3 }}>
            {!openPersistent && (
              <Logo
                sx={{
                  height: 32,
                  width: 32,
                }}
                variant={"light"}
              />
            )}
            {(openPersistent) && <Box
              sx={{
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.04)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                px: 2,
                py: "11px",
                borderRadius: 1,
              }}
            >
              <Logo
                sx={{
                  height: 42,
                  width: 42,
                }}
                variant={"light"}
              />
              <div>
                <Typography color="inherit" variant="subtitle1">
                  Backoffice
                </Typography>
                <Typography
                  color="neutral.400"
                  variant="subtitle1"
                  sx={{ fontSize: "0.85rem" }}
                >
                  Botón de prescripción
                </Typography>
              </div>
            </Box>}
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => ((openPersistent) ? <NavItem
              key={item.key}
              icon={item.icon}
              href={item.href}
              title={item.title}
            /> : <IconNavItem key={item.key}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />)     
          )}
        </Box>
        <Divider sx={{ borderColor: "#2D3748" }} />
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={openPersistent}
        PaperProps={
          openPersistent
            ? {
                sx: {
                  backgroundColor: "neutral.900",
                  color: "#FFFFFF",
                  width: 280,
                },
              }
            : {
                sx: {
                  backgroundColor: "neutral.900",
                  color: "#FFFFFF",
                  width: 65,
                },
              }
        }
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

PageSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
