/* eslint-disable import/no-anonymous-default-export */
export default {
  users: {
    loading: false,
    error: null,
    data: [],
    isSearchActive: false,
    foundUsers: [],
  },
  addUser: {
    loading: false,
    error: null,
    data: null,
  },
};
