// export const API_BASE_URL =
//   "https://dev-boton-suscripcion.qbitos.com/boton-prescipcion-back/api/";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
export const CONNECTION_ERROR = "No se puede conectar en este momento inténtelo más tarde.";

//caso prueba
//"https://dev-boton-suscripcion.qbitos.com/boton-prescipcion-front/formulario/4ea54202-fe01-4646-8445-9f4d2c3a9820"
//http://localhost:3000/boton-prescipcion-front/formulario/4ea54202-fe01-4646-8445-9f4d2c3a9820

//"https://dev-boton-suscripcion.qbitos.com/boton-prescipcion-front/formulario/8f49534f-d258-4297-9ba3-977737ff6f60"
