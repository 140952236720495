import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import RecentActorsIcon from "@mui/icons-material/RecentActors";

export const Budget = (props) => {
  const { cantidad } = props;

  return (
    <Card
      sx={{
        border: "0.5px solid #dddddd",
        padding: "1px",
        boxShadow: "1px 1px #d5d5d5",
      }}
      {...props}
    >
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">
              Aplicaciones activas
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {cantidad}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: "primary.main",
                height: 56,
                width: 56,
              }}
            >
              <RecentActorsIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
