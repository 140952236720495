import React from "react";
import { Box, Typography, Grid, Alert, Divider, Tooltip } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

const TabFinanciadores = (props) => {
  const {
    financiadores,
    selectAll,
    onChange,
    dataForm,
    handleOnChange,
    addFinanciadores, 
    financiadoresSelected,
    validacionFinanciador,
    validacionCantidades,
    validacionDiagnostico
  } = props;
  
  return (
    <Box sx={{ mx: 2, mt: 5 }}>
      <Grid container spacing={8} sx={{ justifyContent: "space-between" }}>
        <Grid item md={6} xs={12}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.95rem", fontWeight: "500" }}>
              Financiadores
            </Typography>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
                backgroundColor: "#DCDCDC",
                textAlign: "center",
                width: "30px",
                height: "30px",
                padding: "5px",
                borderRadius: "50px",
              }}
            >
              <Tooltip title="Cantidad de financiadores asociados">
                <Typography sx={{ fontSize: "0.95rem", fontWeight: "500" }}>
                  {financiadoresSelected.length.toString()}
                </Typography>
              </Tooltip>
            </div>
          </div>
          <Divider sx={{ py: 0.5 }} />
          <Box sx={{ my: 2 }}>
            <Typography sx={{ fontSize: "0.95rem" }}>
              Seleccione el/los financiador/es asociado/s a la aplicación
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={financiadores}
              value={financiadoresSelected}
              readOnly={selectAll}
              disabled={selectAll}
              onChange={addFinanciadores}
              getOptionLabel={(option) =>
                option.descripcion + "   (" + option.id + ")"
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Financiadores"
                  size="small"
                  placeholder="Seleccionar financiador"
                />
              )}
            />
          </Box>
          <FormControlLabel
            control={<Checkbox checked={selectAll} />}
            onChange={onChange}
            label={
              <Typography sx={{ fontSize: "0.85rem" }}>
                Vincular todos los financiadores
              </Typography>
            }
          />
          <Box sx={{ my: 1 }}>
            {validacionFinanciador && (
              <Alert severity="error">{validacionFinanciador}</Alert>
            )}
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: "0.95rem", fontWeight: "500" }}>
            Configuración del formulario
          </Typography>
          <Divider sx={{ py: 0.5 }} />
          <Box sx={{ my: 2 }}>
            <Typography sx={{ fontSize: "0.95rem" }}>Diagnóstico</Typography>
            <Box sx={{ my: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={dataForm.diagnosticoGeneral} />}
                onChange={() => handleOnChange("diagnosticoGeneral")}
                label={
                  <Typography sx={{ fontSize: "0.85rem" }}>
                    Permite selección de diagnóstico general
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox checked={dataForm.diagnosticoPorMedicamento} />
                }
                onChange={() => handleOnChange("diagnosticoPorMedicamento")}
                label={
                  <Typography sx={{ fontSize: "0.85rem" }}>
                    Permite selección de diagnóstico por medicamento
                  </Typography>
                }
              />
            </Box>
            <Typography sx={{ fontSize: "0.95rem" }}>Receta</Typography>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "space-between", py: 2 }}
            >
              <Grid item md={12} xs={12}>
                <TextField
                  id="cantidadLineasPorReceta"
                  label="Cantidad de líneas por receta"
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.cantidadLineasPorReceta}
                  onChange={(value) =>
                    handleOnChange("cantidadLineasPorReceta", value)
                  }
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  id="cantidadMedicamentoPorReceta"
                  label="Cantidad máxima de envases por medicamento"
                  variant="outlined"
                  type="number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.cantidadMedicamentoPorReceta}
                  onChange={(value) =>
                    handleOnChange("cantidadMedicamentoPorReceta", value)
                  }
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  id="cantidadRecetasPostdatadas"
                  label="Cantidad de recetas postdatadas"
                  variant="outlined"
                  type="number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.cantidadRecetasPostdatadas}
                  onChange={(value) =>
                    handleOnChange("cantidadRecetasPostdatadas", value)
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ my: 1 }}>
            {validacionCantidades && (
              <Alert severity="error">{validacionCantidades}</Alert>
            )}
          </Box>
          <Box sx={{ my: 1 }}>
            {validacionDiagnostico && (
              <Alert severity="error">{validacionDiagnostico}</Alert>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TabFinanciadores;
