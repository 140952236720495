import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import ListItemText from "./list-item-text";


const PanelMedico = (props) => {
  const { medico, estilos, onClick } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.card}>
      <div
        className={classes.header}
        style={{ backgroundColor: estilos.backgroundColorAccent }}
      >
        <div className={classes.sectionName}>
          <Avatar sx={{ height: 64, width: 64 }}>
            <PersonIcon sx={{ height: 36, width: 36 }} />
          </Avatar>
          <div className={classes.sectionText}>
            <Typography
              sx={{
                fontSize: "13.5px",
                lineHeight: "25px",
                color: estilos.colorTextPrimary,
              }}
            >
              Médico
            </Typography>
            {medico.nombreMedico && medico.apellidoMedico && (
              <Typography
                sx={{
                  fontSize: "17.6px",
                  lineHeight: "25px",
                  color: estilos.colorTextSecondary,
                }}
              >
                {medico.nombreMedico} {medico.apellidoMedico}
              </Typography>
            )}
          </div>
          {/* <div className={classes.sectionSetting}>
            <IconButton aria-label="back" onClick={() => onClick()}>
              <SettingsIcon color="primary" />
            </IconButton>
          </div> */}
        </div>
      </div>
      <div className={classes.body}>
        <ListItemText
          color={estilos.colorTextSecondary}
          label={"Tipo. de Matrícula"}
          data={medico.tipoMatricula}
        />
        <ListItemText
          color={estilos.colorTextSecondary}
          label={"Nro. de Matrícula"}
          data={medico.nroMatricula}
        />
      </div>
    </div>
  );
};

export default PanelMedico;

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    borderRadius: 7,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    margin: "5px",
    border: "1px solid rgba(0, 0, 0, 0.14)",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
  },
  header: {
    backgroundColor: "#E3E3E3",
    height: 100,
    padding: 15,
  },
  sectionSetting: {
    alignSelf: "flex-start",
    padding: 15,
  },
  sectionName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    gap: "16",
  },
  sectionText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    paddingLeft: "16px",
  },
  body: {
    display: "flex",
    padding: 15,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
  },
}));
