import React from "react";
import { Typography, Box, Button, Stack, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import { theme } from "../../theme";
import { styled } from "@mui/material/styles";
import CanvasDraw from "react-canvas-draw";

const PanelConfiguracionMedico = (props) => {
  const {
    estilos,onClick, medico
  } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.card}>
      <Typography
        sx={{
          m: 1,
          fontSize: "1.10rem",
          color: estilos.colorTextSecondary,
          fontWeight: "bold",
        }}
      >
        Configuración de datos del médico
      </Typography>
      <div className={classes.sectionConfiguration}>
        <div className={classes.sectionName}>
          <Avatar sx={{ height: 64, width: 64 }}>
            <PersonIcon sx={{ height: 36, width: 36 }} />
          </Avatar>
          <div className={classes.sectionText}>
            <Typography
              sx={{
                fontSize: "13.5px",
                lineHeight: "25px",
                color: estilos.colorTextPrimary,
              }}
            >
              Médico
            </Typography>
            <Typography
              sx={{
                fontSize: "17.6px",
                lineHeight: "25px",
                color: estilos.colorTextSecondary,
              }}
            >
              {medico.nombreMedico} {medico.apellidoMedico}
            </Typography>
          </div>
        </div>
        <div className={classes.sectionSello}>
          <Typography
            sx={{
              m: 1,
              fontSize: "0.92rem",
              color: estilos.colorTextSecondary,
              fontWeight: "bold",
            }}
          >
            Firma y sello en receta
          </Typography>
          <div className={classes.sectionContentSello}>
            <div className={classes.sectionCanvas}>
              <Typography
                sx={{
                  m: 1,
                  fontSize: "0.75rem",
                  color: estilos.colorTextSecondary,
                }}
              >
                Puede dibujar la firma que desea se utilice para las recetas,
                recuerde dar Guardar los cambios al terminar de dibujar
              </Typography>
              <div className={classes.componentCanvas}>
                <CanvasDraw
                  hideGrid={true}
                  style={{
                    boxShadow:
                      "0 13px 13px -5px rgba(50, 50, 93, 0.25),    0 8px 8px -8px rgba(0, 0, 0, 0.3)",
                  }}
                />
              </div>
            </div>
            <div className={classes.sectionLineas}>
              <Typography
                sx={{
                  m: 1,
                  fontSize: "0.75rem",
                  color: estilos.colorTextSecondary,
                }}
              >
                Complete las líneas que componen su sello, con el mismo
                contenido.
              </Typography>
              <div className={classes.sectionInput}>
                <div className={classes.componentInput}>
                  <CustomTextField
                    size="small"
                    color={estilos.backgroundColorPrimary}
                    fullWidth
                    label="Línea 1 del sello"
                    id="linea-1"
                    placeholder="Dr. Tomás Manchini"
                    InputProps={{
                      classes: {
                        input: classes.resizeFont,
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </div>
                <div className={classes.componentInput}>
                  <CustomTextField
                    size="small"
                    color={estilos.backgroundColorPrimary}
                    fullWidth
                    label="Línea 2 del sello"
                    placeholder="CARDIOLOGÍA"
                    id="linea-2"
                    InputProps={{
                      classes: {
                        input: classes.resizeFont,
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </div>
                <div className={classes.componentInput}>
                  <CustomTextField
                    size="small"
                    color={estilos.backgroundColorPrimary}
                    fullWidth
                    label="Línea 3 del sello"
                    placeholder="M.N. 1234"
                    id="linea-3"
                    InputProps={{
                      classes: {
                        input: classes.resizeFont,
                      },
                    }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Box
        sx={{
          padding: "50px 0px",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              color: estilos.backgroundColorPrimary,
              "&.MuiButton-outlinedPrimary": {
                border: "1px solid",
                borderColor: estilos.backgroundColorPrimary,
              },
            }}
            onClick={() => onClick("salir")}
          >
            SALIR SIN GUARDAR
          </Button>
          <Button
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              backgroundColor: estilos.backgroundColorPrimary,
              "&:hover": {
                backgroundColor: estilos.backgroundColorPrimary,
                opacity: 0.8,
              },
            }}
            variant="contained"
            onClick={() => onClick("guardar")}
          >
            GUARDAR CAMBIOS
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default PanelConfiguracionMedico;

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    borderRadius: 7,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    margin: "5px",
    padding: "16px",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
  },
  sectionName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "30px 30px 0px 0px",
    gap: "16",
  },
  sectionText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    paddingLeft: "16px",
  },
  sectionConfiguration: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 15,
    gap: "16",
  },
  sectionSello: {
    padding: "0px 0px 0px 30px",
  },
  sectionContentSello: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "16",
  },
  componentInput:{
    padding: "10px 0px"
  }
}));


const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& label.Mui-focused": {
    color: color,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: color,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: color,
    },
    "&.Mui-focused fieldset": {
      borderColor: color,
    },
    fontSize: "14px",
  },
}));