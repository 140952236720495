/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_USER_LOAD,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
} from "../../../constants/actionTypes";
import axiosInstance from "../../../helpers/axiosInstance";
import { CONNECTION_ERROR } from "../../../constants/apiConstants";

export default (payload) => (dispatch) => {
  dispatch({
    type: ADD_USER_LOAD,
  });
  axiosInstance
    .post("backoffice/UsuarioBackoffice/Create", payload)
    .then((res) => {
       if (res.data.status !== "Error") {
         dispatch({
           type: ADD_USER_SUCCESS,
           payload: res.data.result,
         });
       } else {
         dispatch({
           type: ADD_USER_ERROR,
           payload: res.data.errors[0],
         });
       }
    })
    .catch((err) => {
      dispatch({
        type: ADD_USER_ERROR,
        payload: err.response ? err.response.data : CONNECTION_ERROR,
      });
    });
};
