import "./users.scss";
import React, { useContext, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { UsersListToolbar } from "../../components/users/users-list-toolbar";
import { UsersListResults } from "../../components/users/users-list-results";
import { DialogComponent } from "../../components/dialog-component";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/Provider";
import deleteUser from "../../context/actions/users/deleteUser";
import getUsers from "../../context/actions/users/getUsers";

const Users = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [titleDialog, setTitleDialog] = React.useState("");
  const [bodyDialog, setBodyDialog] = React.useState("");
  const { usersDispatch, usersState } = useContext(GlobalContext);
  const [userSelected, selectUser] = React.useState(null);

  const {
    users: { data, loading },
  } = usersState;

  useEffect(() => {
    if (data.length === 0) {
      getUsers()(usersDispatch);
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const executeAction = (action, data) => {
    if (action === "accept-dialog") {
      deleteUser({id: userSelected})(usersDispatch);
      setOpen(false);
    } else if (action === "desactivar") {
      setTitleDialog("Deshabilitar usuario");
      setBodyDialog(
        <p>
          ¿Confirma que desea eliminar el usuario de{" "}
          <span style={{ fontWeight: "600" }}>
            {data.nombre} {data.apellido}
          </span>
          ?
        </p>
      );
      setOpen(true);
      selectUser(data.id);
    } else if (action === "activar") {
      setTitleDialog("Habilitar usuario");
      setBodyDialog(
        <p>
          ¿Confirma que desea habilitar el usuario de{" "}
          <span style={{ fontWeight: "600" }}>
            {data.nombre} {data.apellido}
          </span>
          ?
        </p>
      );
      setOpen(true);
    } else if (action === "agregar") {
      navigate("/users/new");
    } else if (action === "editar") {
      navigate(`/users/edit/${data.id}`);
    }
  };

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth={false}>
          <UsersListToolbar onClick={executeAction} />
          <Box sx={{ mt: 7 }}>
            <DialogComponent
              open={open}
              handleClose={handleClose}
              onClick={executeAction}
              title={titleDialog}
              description={bodyDialog}
            />
            <UsersListResults
              loading={loading}
              users={data}
              onClick={executeAction}
            />
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default Users;

const users = [
  {
    apellido: "Manchini",
    nombre: "Tomás",
    email: "tmanchini123@email.com",
    id: "334",
    rol: {
      id: "1",
      descripcion: "Admin",
    },
    activo: 1,
  },
  {
    apellido: "López",
    nombre: "Carlos",
    email: "clopez@email.com",
    id: "213",
    rol: {
      id: "1",
      descripcion: "Admin",
    },
    activo: 1,
  },
  {
    apellido: "Robles",
    nombre: "Diego",
    email: "drobles@email.com",
    id: "409",
    rol: {
      id: "2",
      descripcion: "Operador",
    },
    activo: 1,
  },
  {
    apellido: "Martinez",
    nombre: "Carla",
    email: "cmartinez@email.com",
    id: "789",
    rol: {
      id: "2",
      descripcion: "Operador",
    },
    activo: 0,
  },
];
