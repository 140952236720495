import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { useParams, useNavigate } from "react-router-dom";
import PanelPaciente from "../../components/formulario/panel-paciente";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import PanelMedico from "../../components/formulario/panel-medico";
import PanelReceta from "../../components/formulario/panel-receta";
import CustomDialog from "../../components/custom-dialog";
import FormAddMedicamento from "../../components/formulario/form-add-medicamento";
import FormEditMedicamento from "../../components/formulario/form-edit-medicamento";
import CircularProgress from "@mui/material/CircularProgress";
import PanelDeleteMedicamento from "../../components/formulario/panel-delete-medicamento";
import PanelRecetaGenerada from "../../components/formulario/panel-receta-generada";
import RiseLoader from "react-spinners/RiseLoader";
import FormularioStore from "./Store";
import { observer } from "mobx-react";
import PanelConfiguracionMedico from "../../components/formulario/panel-configuracion-medico";
import PanelShareRecetas from "../../components/formulario/panel-share-recetas";
import { theme } from "../../theme";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import HistorialRecetas from "../../components/historial-recetas";
import { async } from "regenerator-runtime";
import { set } from "mobx";



const Formulario = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [store] = useState(new FormularioStore(params.hash, navigate));
  const [hideBarLateral, setHideBarLateral] = useState(false);
  const [togglePanelRecord, setTogglePanelRecord] = React.useState(false);
  const [record, setRecord] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("")
  const [severityMessage, setSeverityMessage] = React.useState("")
  const [openActionRecord, setOpenActionRecord] = React.useState(false)
  const [actionRecord, setActionRecord] = React.useState({
    data: null,
    action: null
  })

  const [loadingModal, setLoadingModal] = React.useState(false);
  const [titleGoBack, setTitleGoBack] = React.useState("")
  const [loadingModalHistory, setLoadingModalHistory] = React.useState(false);


  useEffect(() => {
    store.fetchData();
  }, []);

  const handleTogglePanelRecord = async () => {
    if(!togglePanelRecord) {
      getRecord();
    }else{
      store.setOldRecipe = false
      await store.duplicarReceta()
    }
    setTogglePanelRecord(!togglePanelRecord);
  };

  const showRecord = async () => {
    if(!togglePanelRecord) {
      getRecord();
    }
    setTogglePanelRecord(!togglePanelRecord);
  }

  const getRecord = async () => {
    setLoadingModalHistory(true);
    let data = await store.getRecord({
      appId: params.hash,
      medicoId: store.medico.medicoId,
      pacienteId: store.paciente.pacienteId,
    });
    setRecord(data); 
    setLoadingModalHistory(false);
  }

  const actionModalRecord = (data, action) => {
    setActionRecord({
      data:data,
      action:action
    })
    setOpenActionRecord(true)
  }

  const unsubscribeRecipe = async (recetaId, estado) => {
    if(estado !== "VIGENTE") {
      return;
    }
    setLoadingModal(true); 
    const response = await store.unsubscribeRecipe(recetaId);
    if (response.status === "Ok") {
      setLoadingModal(false);
      getRecord();
      setOpenActionRecord(false)
      setMessage("Receta dada de Baja con Éxito")
      setSeverityMessage("success")
      setOpen(true);
    }else{
      setLoadingModal(false);
      setOpenActionRecord(false)
      setMessage("Ocurrio un Error al Intentar dar de Baja a la Receta")
      setSeverityMessage("error")
      setOpen(true);  
    }

  };

  const getResendRecipe = async (recetaId, estado, hash) => {
    if(estado !== "VIGENTE") {
      return;
    }  
    setLoadingModal(true);
    const response = await store.getResendRecipe(recetaId, store.emailPaciente, hash);
    if (response.status === "Ok" && response.result) {
      setLoadingModal(false);
      setOpenActionRecord(false)
      setMessage("Receta enviada con Éxito")
      setSeverityMessage("success")
      setOpen(true);
    }else{
      setLoadingModal(false);
      setOpenActionRecord(false)
      setMessage("Ocurrio un Error al Intentar Reenviar la Receta")
      setSeverityMessage("error")
      setOpen(true);
    }
  };

  const createRecipeWithOldRecipe = async (recipe) => {
    setOpenActionRecord(false)
    setTogglePanelRecord(false)
    store.setOldRecipe = true
    store.oldRecipe = recipe
    await store.duplicarReceta()
  };

  useEffect(() => {
    if(store.recetasIds.length === 0 && !store.panelConfiguracionMedico && togglePanelRecord){
      setTitleGoBack("GENERAR RECETA") 
    }
    else if(store.recetasIds.length > 0 && !store.panelConfiguracionMedico && togglePanelRecord){
      setTitleGoBack("GENERAR RECETA")
    }
    else {
      setTitleGoBack("")
    }

  }, [togglePanelRecord]);
  

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={store.openSessionExpired}
        onClose={store.handleCloseSessionExpired}
      >
        <DialogTitle>Sesión expirada</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tu sesión expiró. Por favor ingrese su número de matrícula para
            poder reestablecerla.
          </DialogContentText>
          <TextField
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            autoFocus
            onChange={(value) => store.setDocumentoMedico(value)}
            margin="dense"
            id="dni"
            label="Número de matrícula"
            type="number"
            fullWidth
            variant="standard"
          />
          {store.errorRenovar && (
            <Typography
              sx={{
                fontSize: "0.75rem",
                fontWeight: "400",
                textAlign: "center",
                color: "red",
              }}
            >
              {store.errorRenovar}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={store.handleCloseSessionExpired}>Cancelar</Button>
          <Button
            disabled={
              !store.valueDni ||
              store.valueDni === "" ||
              store.valueDni.length < 4
            }
            onClick={() => store.renovarSesion()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <CustomDialog
        title={"Eliminar medicamento"}
        open={store.showDialogDeleteMedicamento}
        estilos={store.estilos}
        customStyle={{
          padding: "10px 20px",
          [theme.breakpoints.up("sm")]: {
            width: "640px",
          },
        }}
        onClose={() => store.setShowDialogDeleteMedicamento(false)}
      >
        <PanelDeleteMedicamento
          onClick={store.executeAction}
          medicamento={store.productSelected}
          estilos={store.estilos}
          borrado={store.borrado}
          onClose={store.setShowDialogDeleteMedicamento}
        />
      </CustomDialog>
      <CustomDialog
        title={"Compartir recetas"}
        open={store.showDialogShareRecipes}
        estilos={store.estilos}
        customStyle={{
          padding: "10px 20px",
          [theme.breakpoints.up("sm")]: {
            width: "640px",
          },
        }}
        onClose={() => store.setShowDialogShareRecipes(false)}
      >
        <PanelShareRecetas
          onClick={store.executeAction}
          estilos={store.estilos}
          onClose={store.setShowDialogShareRecipes}
          emailValidado={store.emailValidado}
          email={store.emailPaciente}
          onChange={store.onChangeEmail}
          loading={store.loadingSendEmail}
          error={store.errorSendEmail}
          enviado={store.emailEnviado}
        />
      </CustomDialog>
      <CustomDialog
        title={"Editar medicamento"}
        customStyle={{
          padding: "10px 20px 15px 20px",
        }}
        open={store.showDialogEditMedicamento}
        estilos={store.estilos}
        onClose={() => store.setShowDialogEditMedicamento(false)}
      >
        <FormEditMedicamento
          marcas={store.marcas}
          marcaSeleccionada={store.marcaSeleccionada}
          drogas={store.drogas}
          drogaSeleccionada={store.drogaSeleccionada}
          imprimirCodigo={store.imprimirCodigoByMed}
          onInputChangeMedicamentos={store.onInputChangeMedicamentos}
          seleccionarMedicamento={store.seleccionarMedicamento}
          loadingSearchMarca={store.loadingSearchMarca}
          loadingSearchDroga={store.loadingSearchDroga}
          presentaciones={store.presentaciones}
          presentacionSeleccionada={store.presentacionSeleccionada}
          cantidad={store.cantidad}
          original={store.medOriginal}
          generico={store.medGenerico}
          tratamiento={store.tratamiento}
          onChangePosologia={store.onChangePosologia}
          posologia={store.posologia}
          onClick={store.executeAction}
          diagnosticos={store.diagnosticosByMed}
          diagnostico={store.diagnosticoByMed}
          addDiagnostico={store.addDiagnosticoByMed}
          onInputChangeDiagnostico={store.onInputChangeDiagnosticoByMed}
          dataForm={store.dataForm}
          estilos={store.estilos}
          addMed={store.agregaMedicamento}
          maxCantidadEnvases={store.maxCantidadEnvases}
          existente={store.productoYaExistente}
        />
      </CustomDialog>
      <CustomDialog
        title={"Agregar medicamento"}
        customStyle={{
          padding: "10px 20px 15px 20px",
        }}
        open={store.showDialogAddMedicamento}
        estilos={store.estilos}
        onClose={() => store.setShowDialogAddMedicamento(false)}
      >
        <FormAddMedicamento
          marcas={store.marcas}
          marcaSeleccionada={store.marcaSeleccionada}
          drogas={store.drogas}
          drogaSeleccionada={store.drogaSeleccionada}
          imprimirCodigo={store.imprimirCodigoByMed}
          onInputChangeMedicamentos={store.onInputChangeMedicamentos}
          seleccionarMedicamento={store.seleccionarMedicamento}
          loadingSearchMarca={store.loadingSearchMarca}
          loadingSearchDroga={store.loadingSearchDroga}
          presentaciones={store.presentaciones}
          presentacionSeleccionada={store.presentacionSeleccionada}
          cantidad={store.cantidad}
          original={store.medOriginal}
          generico={store.medGenerico}
          tratamiento={store.tratamiento}
          onChangePosologia={store.onChangePosologia}
          posologia={store.posologia}
          onClick={store.executeAction}
          diagnosticos={store.diagnosticosByMed}
          diagnostico={store.diagnosticoByMed}
          addDiagnostico={store.addDiagnosticoByMed}
          onInputChangeDiagnostico={store.onInputChangeDiagnosticoByMed}
          dataForm={store.dataForm}
          estilos={store.estilos}
          addMed={store.agregaMedicamento}
          maxCantidadEnvases={store.maxCantidadEnvases}
          existente={store.productoYaExistente}
        />
      </CustomDialog>
      <Dialog
        open={openActionRecord}
        onClose={()=> setOpenActionRecord(false)}
      >
        <DialogTitle>
          { 
            actionRecord.action === "unsubscribeRecipe" ? "Baja de Receta" : 
            actionRecord.action === "getResendRecipe" ? "Reenviar Receta": "Crear Receta"
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              minWidth: "425px",
            }}
          >
            { !loadingModal && (
              actionRecord.action === "unsubscribeRecipe" ? "¿Estás seguro de querer dar de baja la Receta?" : 
              actionRecord.action === "getResendRecipe" ? (<>
                <Box sx={{ padding: "20px" }}>
                <Typography sx={{fontSize:"0.95rem", fontWeight:"400", textAlign:"center", color:store.estilos.colorTextSecondary}}>
                  Se reenviará la Receta al siguiente Email, si no es el correcto por favor modificalo.
                </Typography>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={store.emailPaciente}
                    onChange={store.onChangeEmail}
                  />

                </Box>
              </>): "¿Estás seguro de querer crear una nueva Receta en base a la seleccionada?")
            } 
          </DialogContentText>
          {loadingModal && (
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <Stack
              spacing={5}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <RiseLoader
                color={store.estilos.backgroundColorPrimary}
                loading={loadingModal}
                size={17}
              />
              <Typography
                sx={{
                  fontSize: "0.95rem",
                  fontWeight: "400",
                  textAlign: "center",
                  color: store.estilos.colorTextSecondary,
                }}
              >
                Procesando tu solicitud ....
              </Typography>
            </Stack>
          </Box>
        )}
        </DialogContent>
        {
          !loadingModal && (
            <DialogActions>
              <Button
                onClick={()=> !loadingModal && setOpenActionRecord(false)}
              >Cancelar
              </Button>
              <Button
                onClick={
                  ()=>{
                    if(actionRecord.action === "unsubscribeRecipe" && !loadingModal ){
                      unsubscribeRecipe(actionRecord.data.recetaId,actionRecord.data.estado)
                    }
                    if(actionRecord.action === "getResendRecipe" && !loadingModal){
                      getResendRecipe(actionRecord.data.recetaId,actionRecord.data.estado,actionRecord.data.hash)
                    }
                    if( actionRecord.action === "createRecipeWithOldRecipe" && !loadingModal){
                      createRecipeWithOldRecipe(actionRecord.data)
                    }
                    
                  }
                }
              >
                Aceptar
              </Button>
            </DialogActions>
          )
        }
        


      </Dialog>

      {store.loadingConfig && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            padding: "50px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!store.loadingConfig &&
        store.estilos &&
        store.dataForm &&
        store.sectionsEnabled && (
          <>
            {store.sectionsEnabled.showHeaderSection && (
              <AppBar position="fixed" color={"default"}>
                <Container maxWidth="xl">
                  <Toolbar disableGutters>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Stack>
                        <Tooltip
                          title={
                            !hideBarLateral
                              ? "Ocultar datos del médico y del paciente"
                              : "Mostrar datos del médico y del paciente"
                          }
                        >
                          <IconButton
                            aria-label="close-open"
                            sx={{
                              backgroundColor: "#EEEEEE",
                              "&:hover": {
                                backgroundColor: "#EEEEEE",
                                opacity: 0.8,
                              },
                              width: "30px",
                              height: "30px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => setHideBarLateral(!hideBarLateral)}
                          >
                            {!hideBarLateral ? (
                              <KeyboardDoubleArrowLeftIcon
                                sx={{
                                  [theme.breakpoints.down("md")]: {
                                    display: "none",
                                  },
                                }}
                                color="#A4A4A4"
                              />
                            ) : (
                              <KeyboardDoubleArrowRightIcon
                                sx={{
                                  [theme.breakpoints.down("md")]: {
                                    display: "none",
                                  },
                                }}
                                color="#A4A4A4"
                              />
                            )}
                            {!hideBarLateral ? (
                              <KeyboardDoubleArrowUpIcon
                                sx={{
                                  [theme.breakpoints.up("md")]: {
                                    display: "none",
                                  },
                                }}
                                color="#A4A4A4"
                              />
                            ) : (
                              <KeyboardDoubleArrowDownIcon
                                sx={{
                                  [theme.breakpoints.up("md")]: {
                                    display: "none",
                                  },
                                }}
                                color="#A4A4A4"
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Stack>
                        {store.estilos.logo && (
                          <img
                            src={store.estilos.logo}
                            height={"52px"}
                            alt="logo"
                          />
                        )}
                      </Stack>
                    </div>
                  </Toolbar>
                </Container>
              </AppBar>
            )}

            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                width: "100%",
                paddingTop: store.sectionsEnabled.showHeaderSection
                  ? "68px"
                  : "0px",
                backgroundColor: "#EEEEEE",
              }}
            >
              <Grid container spacing={1}>
                {!store.loadingConfig &&
                  store.paciente &&
                  store.medico &&
                  !hideBarLateral &&
                  (store.sectionsEnabled.showMedicoSection ||
                    store.sectionsEnabled.showPacienteSection) &&
                  !store.panelConfiguracionMedico && (
                    <Grid item xs={12} sm={12} md={3}>
                      <Stack
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "7px",
                          boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
                          margin: "5px",
                          padding: "10px",
                          filter:
                            "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
                        }}
                      >
                          {store.sectionsEnabled.showPacienteSection && (
                            <PanelPaciente
                              paciente={store.paciente}
                              estilos={store.estilos}
                              showRecord={handleTogglePanelRecord}
                              titleGoBack = {titleGoBack}
                            />
                          )}
                          {store.sectionsEnabled.showMedicoSection && (
                            <PanelMedico
                              medico={store.medico}
                              estilos={store.estilos}
                              onClick={store.openPanelConfiguracionMedico}
                            />
                          )}
                      </Stack>
                    </Grid>
                  )}

                {store.loadingReceta && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={
                      ((store.sectionsEnabled.showMedicoSection ||
                        store.sectionsEnabled.showPacienteSection) && !hideBarLateral)
                        ? 9
                        : 12
                    }
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100vh",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Stack
                        spacing={5}
                        sx={{ alignItems: "center", justifyContent: "center" }}
                      >
                        <RiseLoader
                          color={store.estilos.backgroundColorPrimary}
                          loading={store.loadingReceta}
                          size={22}
                        />
                        <Typography
                          sx={{
                            fontSize: "0.95rem",
                            fontWeight: "400",
                            textAlign: "center",
                            color: store.estilos.colorTextSecondary,
                          }}
                        >
                          {store.textAccionReceta}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                )}
                {!store.loadingConfig && !store.loadingReceta && (
                  <Grid
                    item
                    xs={12}
                    md={
                      !hideBarLateral &&
                      (store.sectionsEnabled.showMedicoSection ||
                        store.sectionsEnabled.showPacienteSection) &&
                      !store.panelConfiguracionMedico
                        ? 9
                        : 12
                    }
                  >
                    {store.panelConfiguracionMedico && (
                      <PanelConfiguracionMedico
                        medico={store.medico}
                        estilos={store.estilos}
                        onClick={store.saveConfiguracionMedico}
                      />
                    )}
                    {store.recetasIds.length === 0 &&
                      !store.panelConfiguracionMedico && !togglePanelRecord && (
                        <PanelReceta
                          dataForm={store.dataForm}
                          estilos={store.estilos}
                          diagnosticos={store.diagnosticos}
                          diagnostico={store.diagnostico}
                          onInputChange={store.onInputChange}
                          leyenda={store.leyenda}
                          onChangeLeyenda={store.onChangeLeyenda}
                          setDiagnostico={store.addDiagnostico}
                          imprimirCodigo={store.imprimirCodigo}
                          posdatadas={store.posdatadas}
                          limitePosdatadas={
                            store.dataForm.cantidadRecetasPostdatadas
                          }
                          handleChangePostdatada={store.handleChangePostdatada}
                          cantidadRecetas={store.cantidadRecetas}
                          onClick={store.executeAction}
                          medicamentosReceta={store.medicamentosReceta}
                          tratamientoProlongado={store.tratamientoProlongado}
                          error={store.error}
                        />
                      )}
                      {/*TODO - revisar el componente de la receta */}
                    {store.recetasIds.length > 0 &&  !togglePanelRecord &&
                      !store.panelConfiguracionMedico && (
                        <PanelRecetaGenerada
                          estilos={store.estilos}
                          error={store.errorPostGeneracion}
                          recetasIds={store.recetasIds}
                          recetas={store.recetasGeneradas}
                          loading={store.loadingDownloadReceta}
                          onClick={store.executeAction}
                          urlCallback={store.urlCallback}
                          duplicarReceta={store.duplicarReceta}
                          tratamientoProlongado={store.tratamientoProlongado}
                        />
                      )}
                      
                      {
                        togglePanelRecord && 
                        !loadingModalHistory && (
                          <HistorialRecetas
                            showRecord={showRecord}
                            record={record}
                            setRecord={setRecord}
                            unsubscribeRecipe={actionModalRecord}
                            getResendRecipe={actionModalRecord}
                            createRecipeWithOldRecipe={actionModalRecord}
                          /> )
                      }
                      {loadingModalHistory && (
                          <Box
                            sx={{
                              width: "100%",
                              backgroundColor: "white",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginTop: "20px",
                              height: "30vh",
                            }}
                          >
                            <Stack
                              spacing={5}
                              sx={{ alignItems: "center", justifyContent: "center" }}
                            >
                              <RiseLoader
                                color={store.estilos.backgroundColorPrimary}
                                loading={loadingModalHistory}
                                size={17}
                              />
                              <Typography
                                sx={{
                                  fontSize: "0.95rem",
                                  fontWeight: "400",
                                  textAlign: "center",
                                  color: store.estilos.colorTextSecondary,
                                }}
                              >
                                Cargando Historial....
                              </Typography>
                            </Stack>
                          </Box>
                        )
                      }
                      
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        )}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transitionDuration={500}
      >
        <Alert
          variant="outlined"
          severity={severityMessage}
        >
          {message}
        </Alert>
      </Snackbar>
     
    </>
  );
};

export default observer(Formulario);

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    borderRadius: 7,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    margin: "5px",
    padding: "16px",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
  },
}));
