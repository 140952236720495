import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import { Typography, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const LeyendaReceta = (props) => {
  const { estilos, onChange, value } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      <Typography
        sx={{
          m: 1,
          fontSize: "0.91rem",
          color: estilos.colorTextSecondary,
          fontWeight: "500",
        }}
      >
        Leyenda
      </Typography>
      <Box
        sx={{
          padding: "5px 15px 0px",
          display: "flex",
        }}
      >
        <CustomTextField
          size="small"
          onChange={onChange}
          value={value}
          color={estilos.backgroundColorPrimary}
          fullWidth
          label="Leyenda en receta"
          id="leyenda-receta"
          InputProps={{
            classes: {
              input: classes.resizeFont,
            },
          }}
          InputLabelProps={{ style: { fontSize: 15 } }}
        />
      </Box>
      <Box
        sx={{
          padding: "5px 15px 0px",
          display: "flex",
        }}
      >
        <Typography
          sx={{
            m: 1,
            fontSize: "0.61rem",
            color: "primary.contrastTextMedium",
          }}
        >
          La leyenda se imprime en la parte superior izquierda de la receta. Se
          puede utilizar para agregar el nombre de la farmacia para la dispensa
          de psicotrópicos o psicofármacos durante COVID-19.
        </Typography>
      </Box>
    </div>
  );
};




export default LeyendaReceta;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginBottom: 5,
  },
  resizeFont: {
    fontSize: 15,
  },
}));

const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& label.Mui-focused": {
    color: color,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: color,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: color,
    },
    "&.Mui-focused fieldset": {
      borderColor: color,
    },
    fontSize: '14px'
  },
}));