import React from "react";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const EndpointGenerateForm = (props) => {
  const { onClick } = props;
  const [expandedProvincias, setExpandedProvincias] = React.useState(false);
  return (
    <Box {...props}>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Descripción
        </Typography>
        <Typography
          sx={{
            fontSize: "1.00rem",
            color: "primary.dark",
            my: 1,
          }}
        >
          Api para generar el link del formulario de prescripción de receta. En
          el body del endpoint se especifica los campos del médico que prescribe
          y del paciente para el qué se genera la receta médica.
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Resource URL
        </Typography>
        <Typography
          sx={{
            fontSize: "1.00rem",
            color: "#067acc",
          }}
        >
          GenerateForm/Generate
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Resource Information
        </Typography>
        <Box sx={{ mx: 1, my: 2 }}>
          <Typography
            sx={{
              fontSize: "1.00rem",
              color: "primary.dark",
            }}
          >
            Formato respuesta: <span style={{ fontWeight: "bold" }}>JSON</span>
          </Typography>
        </Box>
        <Box sx={{ mx: 1, my: 2 }}>
          <Typography
            sx={{
              fontSize: "1.00rem",
              color: "primary.dark",
            }}
          >
            ¿Requiere autenticación?:{" "}
            <span style={{ fontWeight: "bold" }}>Sí.</span>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Autenticación
        </Typography>
        <Typography
          sx={{
            fontSize: "0.92rem",
            color: "primary.dark",
          }}
        >
          Para la autenticación de la api, se debe previamente generar un token
          de sesión con el API endpoint Login. Cada aplicación debe generar su
          propia autenticación para que la prescripción sea bajo esas
          condiciones.
        </Typography>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "0.82rem",
              color: "primary.dark",
              fontWeight: "bold",
            }}
          >
            Ejemplo:
          </Typography>
          <Typography
            sx={{
              fontSize: "0.92rem",
              color: "primary.dark",
              fontFamily: "Courier New,Courier,monospace!important",
            }}
          >
            -H "Authorization: Bearer 4eC39HqLyjWDarjtT1zdp7dc"
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Parámetros
        </Typography>
        <Typography
          sx={{
            fontSize: "0.92rem",
            color: "primary.dark",
          }}
        >
          No se requieren parámetros
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Request Body
        </Typography>
        <Box sx={{ my: 2, p: 2, background: "#f3f7fa", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "urlCallback": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "paciente": {"{"}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "nombre": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "apellido": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "tipoDocumento": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "nroDocumento": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "sexo": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "email": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "fechaNacimiento": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "numeroAfiliado": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "numeroFinanciador": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "plan": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"}"},
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "medico": {"{"}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "nombre": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "apellido": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "email": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "tipoDocumento": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "nroDocumento": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "tipoMatricula": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "numeroMatricula": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "provinciaMatricula": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "telefono": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "fechaNacimiento": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "sexo": "string",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "especialidad": "string"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"}"},
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "direccionConsultorio": "string"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography
            sx={{
              fontSize: "1.10rem",
              color: "primary.dark",
              fontWeight: "700",
            }}
          >
            Request body information
          </Typography>
          <Box sx={{ my: 2, mx: 3 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Entidad</TableCell>
                  <TableCell>Requerido</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Ejemplo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    urlCallback
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    URL de callback al ejecutar la acción de retorno desde el
                    formulario.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    direccionConsultorio
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Se corresponde con un texto libre que determina la dirección
                    física del consultorio donde el médico esta prescribiendo.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Pasteur 234, Clinica Del Sur
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    nombre
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    configurable
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. En caso que no se haya
                    configurado ningún campo OBLIGATORIO para el paciente, éste
                    campo será obligatorio. Si se configura otro campo cómo
                    obligatorio, pasa a ser OPCIONAL
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Tomás
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    apellido
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    configurable
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. En caso que no se haya
                    configurado ningún campo OBLIGATORIO para el paciente, éste
                    campo será obligatorio. Si se configura otro campo cómo
                    obligatorio, pasa a ser OPCIONAL
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Manchini
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    tipoDocumento
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    configurable
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. En caso que no se haya
                    configurado ningún campo OBLIGATORIO para el paciente, éste
                    campo será obligatorio. Si se configura otro campo cómo
                    obligatorio, pasa a ser OPCIONAL
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    DNI, CUIT
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    nroDocumento
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    configurable
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. En caso que no se haya
                    configurado ningún campo OBLIGATORIO para el paciente, éste
                    campo será obligatorio. Si se configura otro campo cómo
                    obligatorio, pasa a ser OPCIONAL
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    33597809
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    sexo
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como obligatorio en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    M, F
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como obligatorio en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    fechaNacimiento
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como obligatorio en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Formato:{" "}
                    <span style={{ fontWeight: "bold" }}>YYYY-MM-DD</span>{" "}
                    Ejemplo:{" "}
                    <span style={{ fontWeight: "bold" }}>2023-01-28 </span>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    numeroAfiliado
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como obligatorio en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    numeroFinanciador
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como obligatorio en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    plan
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Paciente
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como obligatorio en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    nombre
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    apellido
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    tipoDocumento
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    DNI, LE, LC, CI
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    nroDocumento
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    tipoMatricula
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    obligatorio
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es obligatorio siempre. Se corresponde con el
                    tipo de matrícula del médico. MN: Matrícula nacional, MP:
                    Matrícula provincial. Por default es obligatorio, no se
                    puede configurar como opcional.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    MN, MP
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    numeroMatricula
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    obligatorio
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es obligatorio siempre. Se corresponde con el
                    número de matrícula del médico. Por default es obligatorio,
                    no se puede configurar como opcional.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    provinciaMatricula
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    obligatorio
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es obligatorio siempre y cuando el tipoMatricula
                    sea provincial (MP).
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    <Accordion
                      expanded={expandedProvincias}
                      onChange={() =>
                        setExpandedProvincias(!expandedProvincias)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel12-header"
                        sx={{
                          background: "#D4d4d4",
                          borderRadius: 1,
                        }}
                      >
                        <p style={{ fontWeight: "bold" }}>
                          Listado de provincias
                        </p>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "10px 30px 30px 30px" }}>
                        <p>ENTRE RIOS</p>
                        <p>SANTIAGO DEL ESTERO</p>
                        <p>LA RIOJA</p>
                        <p>BUENOS AIRES</p>
                        <p>CATAMARCA</p>
                        <p>SANTA FE</p>
                        <p>SAN LUIS</p>
                        <p>MENDOZA</p>
                        <p>CORRIENTES</p>
                        <p>SANTA CRUZ</p>
                        <p>NEUQUEN</p>
                        <p>FORMOSA</p>
                        <p>SAN JUAN</p>
                        <p>SALTA</p>
                        <p>CIUDAD AUTONOMA DE BS.AS.</p>
                        <p>RIO NEGRO</p>
                        <p>CHACO</p>
                        <p>LA PAMPA</p>
                        <p>TIERRA DEL FUEGO</p>
                        <p>JUJUY</p>
                        <p>MISIONES</p>
                        <p>CORDOBA</p>
                        <p>TUCUMAN</p>
                        <p>CHUBUT</p>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    telefono
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    1151420490
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    fechaNacimiento
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Formato:{" "}
                    <span style={{ fontWeight: "bold" }}>YYYY-MM-DD</span>{" "}
                    Ejemplo:{" "}
                    <span style={{ fontWeight: "bold" }}>2023-01-28 </span>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    sexo
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Este campo es configurable. Es OPCIONAL siempre y cuando no
                    se haya configurado como OBLIGATORIO en la configuración de
                    la aplicación.
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    M, F
                  </TableCell>
                </TableRow>
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                      fontWeight: "600",
                    }}
                  >
                    especialidad
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    Medico
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    opcional (configurable)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    <p>
                      Este campo es configurable. Es OPCIONAL siempre y cuando
                      no se haya configurado como OBLIGATORIO en la
                      configuración de la aplicación.
                    </p><br/>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Longitud máxima:
                      </span>{" "}
                      80 caracteres. No se acepta caracteres especiales
                      incluyendo tildes.
                    </p>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Courier New,Courier,monospace!important",
                      fontSize: "0.94rem",
                    }}
                  >
                    CARDIOLOGIA
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Request example
        </Typography>
        <Box sx={{ my: 2, p: 2, background: "#f3f7fa", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "urlCallback": "",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "paciente": {"{"}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "numeroAfiliado": "04986101008",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "numeroFinanciador": "209"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"}"},
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "medico": {"{"}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "tipoMatricula": "MN",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            "numeroMatricula": "21113333"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"}"},
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "direccionConsultorio": ""
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Typography
          sx={{
            fontFamily: "Courier New,Courier,monospace!important",
            fontSize: "0.94rem",
            color: "primary.dark",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Observación:</span> En el request
          de ejemplo, para el paciente se configuró como obligatorio los campos
          numeroAfiliado y numeroFinanciador.
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography
          sx={{
            fontSize: "1.10rem",
            color: "primary.dark",
            fontWeight: "700",
          }}
        >
          Response examples
        </Typography>
        <Box sx={{ my: 2, p: 2, background: "#F7FAEC", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.98rem",
              fontWeight: "bold",
              color: "primary.dark",
            }}
          >
            Code: 401 -{" "}
            <span style={{ fontWeight: "Bold" }}>Status: Unauthorized</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            No está autorizado para generar el link del formulario de
            prescripción. Revisar la generación del token.
          </Typography>
        </Box>
        <Box sx={{ my: 2, p: 2, background: "#F7FAEC", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.98rem",
              fontWeight: "bold",
              color: "primary.dark",
            }}
          >
            Code: 200 - <span style={{ fontWeight: "Bold" }}>Status: OK</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "result": {"{"}
          </Typography>
          <Box>
            <Typography
              sx={{
                fontFamily: "Courier New,Courier,monospace!important",
                fontSize: "0.94rem",
                color: "primary.dark",
                pl: 6,
              }}
            >
              "url":"
              {process.env.REACT_APP_PUBLIC_URL}
              /formulario/b97ab5fc-226c-49ec-9fda-537897c49431",
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"}"},
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "status": "Ok",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "successMessage": "",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "errors": {"[]"},
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "validationErrors": {"[]"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
        <Box sx={{ my: 2, p: 2, background: "#F7FAEC", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.98rem",
              fontWeight: "700",
              color: "primary.dark",
            }}
          >
            Code: 200 - <span style={{ fontWeight: "Bold" }}>Status: Ok</span> -
            <span style={{ fontWeight: "600" }}> Errores de validación</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "result": null,
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "status": "Ok",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "successMessage": "",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "errors": {"[]"},
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "validationErrors": {"["}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 8,
            }}
          >
            "identifier": "Medico",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 8,
            }}
          >
            "errorMessage": "CAMPOS OBLIGATORIOS: Matricula no debe estar
            vacío.",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 8,
            }}
          >
            "severity": "Error"
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 6,
            }}
          >
            {"}"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"]"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
        <Box sx={{ my: 2, p: 2, background: "#FEE9E9", borderRadius: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.98rem",
              fontWeight: "700",
              color: "primary.dark",
            }}
          >
            Code: 200 -{" "}
            <span style={{ fontWeight: "Bold" }}>Status: Error</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"{"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "result": null,
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "status": "Error",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "successMessage": "",
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "Errors": {"["}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 8,
            }}
          >
            "El financiador especificado no corresponde con la configuración de
            la aplicación."
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            {"],"}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
              pl: 3,
            }}
          >
            "validationErrors": {"[]"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Courier New,Courier,monospace!important",
              fontSize: "0.94rem",
              color: "primary.dark",
            }}
          >
            {"}"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
