import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white !important",
    borderRadius: 7,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2) !important",
    margin: "5px !important",
    padding: "16px !important",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12)) !important",
  },
  actionButton: {
    marginLeft: "1rem !important",
    fontWeight: "500 !important",
    padding: "8px 15px !important",
    "@media (max-width: 1300px)": {
      fontSize: "10px !important",
      marginLeft: "0.2rem !important",
    },
    "@media (max-width: 740px)": {
      fontSize: "8px !important",
      marginLeft: "0.1rem !important",
    },
    "@media (max-width: 425px)": {
      padding: "8px 10px !important",
    },
  },
  standardCellHeaderTable: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
    color: "#706e6e !important",
    width: "20% !important",
    paddingLeft: "0px !important",
  },
  standardCellBodyTable: {
    fontSize: "13px !important",
    fontWeight: "300 !important",
    color: "#a1a4a9 !important",
    width: "20% !important",
    marginBottom: "0px !important",
    padding: "0px !important",
    "@media (max-width: 425px)": {
      fontSize: "11px !important",
    },
  },
  pM0: {
    padding: "0px !important",
    margin: "0px !important",
  },
  p0Mb0: {
    marginBottom: "0px !important",
    padding: "0px !important",
  },
  ml2rem: {
    marginLeft: "2rem !important",
  },
  title: {
    fontSize: "1.10rem !important",
    fontWeight: "bold !important",
  },
  goBackButton: {
    fontSize: "13px !important",
    backgroundColor: "#e5e7eb !important",
    borderRadius: "10px !important",
    border: "none !important",
    width: "100px !important",
    fontWeight: "500 !important",
    height: "45px !important",
    padding: "0 !important",
    textAlign: "center !important",
    paddingX: "10px !important",
    paddingY: "5px !important",
    color: "black !important",
  },
  bg: {
    backgroundColor: "white !important",
  },
  cellButtonAction: {
    fontSize: "13px !important",
    fontWeight: "300 !important",
    color: "#a1a4a9 !important",
    width: "40% !important",
    marginBottom: "0px !important",
    padding: "0px !important",
  },
  cellMediceName: {
    padding: "0px !important",
    margin: "0px !important",
    paddingBottom: "0.5rem !important",
    "@media (max-width: 590px)": {
      fontSize: "0.6rem !important",
    },
    "@media (max-width: 420px)": {
      fontSize: "0.5rem !important",
    },
  },
}));

export default useStyles;
