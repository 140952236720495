import "./clients.scss";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  TextField,
  Box,
    Switch,
  Alert,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import { CredencialesSchema } from "../../helpers/validation";

export const ClientCredenciales = (props) => {
  const { onSubmit, credenciales, loading, handleOnChange, status, mensaje, error } = props;

  const formik = useFormik({
    initialValues: credenciales,
    validationSchema: CredencialesSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          border: "0.5px solid #dddddd",
          padding: "1px",
          boxShadow: "1px 1px #d5d5d5",
        }}
      >
        <CardHeader
          title="Credenciales"
          subheader="Credenciales para integración y rango de emisión"
          sx={{ padding: 2 }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} mb={1}>
            <Grid item md={4} xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "0.84rem",
                    lineHeight: "1.5rem",
                    fontWeight: "600",
                    color: "primary.dark",
                  }}
                >
                  Usuario
                </Typography>
                <TextField
                  id="app-user-text"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.user}
                  name="user"
                  error={Boolean(formik.touched.user && formik.errors.user)}
                  helperText={formik.touched.user && formik.errors.user}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "0.84rem",
                    lineHeight: "1.5rem",
                    fontWeight: "600",
                    color: "primary.dark",
                  }}
                >
                  Password
                </Typography>
                <TextField
                  id="api-pass-text"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.pass}
                  name="pass"
                  error={Boolean(formik.touched.pass && formik.errors.pass)}
                  helperText={formik.touched.pass && formik.errors.pass}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "0.84rem",
                    lineHeight: "1.5rem",
                    fontWeight: "600",
                    color: "primary.dark",
                  }}
                >
                  Prefijo de emisión
                </Typography>
                <TextField
                  id="api-prex-text"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.prefijo}
                  name="prefijo"
                  type="number"
                  error={Boolean(
                    formik.touched.prefijo && formik.errors.prefijo
                  )}
                  helperText={formik.touched.prefijo && formik.errors.prefijo}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            {error && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 1,
                }}
              >
                <Alert fullWidth severity="error">
                  {error}
                </Alert>
              </Box>
            )}
            {mensaje && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 1,
                }}
              >
                <Alert fullWidth severity="success">
                  {mensaje}
                </Alert>
              </Box>
            )}
          </Grid>
          <Grid container spacing={3} my={2}>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                name="status"
                control={<Switch checked={status} />}
                onChange={() => handleOnChange(!status)}
                label={
                  <Typography sx={{ fontSize: "0.85rem" }}>
                    Usuario activo
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={6} xs={12} sx={{ textAlign: "end" }}>
              <LoadingButton
                loading={loading}
                color="primary"
                size="small"
                type="submit"
                variant="contained"
              >
                Guardar cambios
              </LoadingButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};
