import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Tooltip,
  TableRow,
  Alert,
  IconButton,
} from "@mui/material";
import { SeverityPill } from "../severity-pill";
//import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
//import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CircularProgress from "@mui/material/CircularProgress";

export const AppsListResults = ({
  clientes,
  onClick,
  loading,
  error,
  ...rest
}) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      sx={{
        border: "0.5px solid #dddddd",
        padding: "1px",
        boxShadow: "1px 1px #d5d5d5",
      }}
      {...rest}
    >
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre APP</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Razón Social</TableCell>
                <TableCell>Tipo de documento</TableCell>
                <TableCell>Número de documento</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Acciones
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                clientes.length > 0 &&
                clientes
                  .slice(page * limit, page * limit + limit)
                  .map((cliente) => (
                    <TableRow hover key={cliente.id}>
                      <TableCell>{cliente.appName}</TableCell>
                      <TableCell>
                        {" "}
                        <SeverityPill
                          color={
                            /**cliente.activo*/ 1 === 1 ? "success" : "error"
                          }
                        >
                          {/**cliente.activo*/ 1 === 1 ? "Activo" : "Inactivo"}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>{cliente.razonSocial}</TableCell>
                      <TableCell>{cliente.tipoDoc}</TableCell>
                      <TableCell>
                        <TableCell>
                          {cliente.tipoDoc === "CUIT"
                            ? cliente.cuit
                            : cliente.nroDoc}
                        </TableCell>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Ver detalle" placement="left">
                            <IconButton
                              sx={{
                                marginRight: "2px",
                              }}
                              onClick={() => onClick("view", cliente)}
                            >
                              <RemoveRedEyeOutlinedIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          {/* {cliente.activo ? (
                        <Tooltip title="Deshabilitar" placement="right">
                          <IconButton
                            sx={{
                              marginLeft: "4px",
                            }}
                            onClick={() => onClick("desactivar", cliente)}
                          >
                            <CancelOutlinedIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Habilitar" placement="right">
                          <IconButton
                            sx={{
                              marginLeft: "4px",
                            }}
                            onClick={() => onClick("activar", cliente)}
                          >
                            <CheckCircleOutlineOutlinedIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )} */}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {!loading && clientes.length === 0 && (
        <Box
          sx={{
            alignItems: "center",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Alert severity="info">
            No hay aplicaciones dadas de alta en la plataforma
          </Alert>
        </Box>
      )}

      <Box
        sx={{
          alignItems: "center",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!!loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Box>
      <TablePagination
        component="div"
        count={clientes.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

AppsListResults.propTypes = {
  clientes: PropTypes.array.isRequired,
};
