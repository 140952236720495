import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { UsuarioSchema } from "../../helpers/validation";

const roles = [
  {
    value: "Admin",
    label: "Administrador",
  },
  {
    value: "Operador",
    label: "Operador",
  },
];

export const UserNewForm = (props) => {
  const { onSubmit, loading, error } = props;

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      email: "",
      rol: "",
      password: "",
      repetirPassword: "",
    },
    validationSchema: UsuarioSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          border: "0.5px solid #dddddd",
          padding: "1px",
          boxShadow: "1px 1px #d5d5d5",
        }}
      >
        <CardHeader
          subheader="Complete los datos del formulario para agregar el usuario"
          title="Usuario"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.nombre && formik.errors.nombre)}
                fullWidth
                helperText={formik.touched.nombre && formik.errors.nombre}
                label="Nombre"
                name="nombre"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.nombre}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.apellido && formik.errors.apellido
                )}
                fullWidth
                helperText={formik.touched.apellido && formik.errors.apellido}
                label="Apellido"
                name="apellido"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.apellido}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                type="email"
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.rol && formik.errors.rol)}
                fullWidth
                helperText={formik.touched.rol && formik.errors.rol}
                label="Rol"
                name="rol"
                select
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.rol}
                variant="outlined"
              >
                {roles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Contraseña"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.repetirPassword &&
                    formik.errors.repetirPassword
                )}
                fullWidth
                helperText={
                  formik.touched.repetirPassword &&
                  formik.errors.repetirPassword
                }
                label="Repetir contraseña"
                name="repetirPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.repetirPassword}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          {error && (
            <Typography
              align="center"
              sx={{
                fontSize: "0.84rem",
                lineHeight: "1.5rem",
                fontWeight: "300",
                color: "red",
              }}
            >
              {error}
            </Typography>
          )}
          <LoadingButton
            loading={loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Guardar usuario
          </LoadingButton>
        </Box>
      </Card>
    </form>
  );
};
