import { Box, Typography, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ClientToolbar = (props) => {
  const { onClick } = props;
  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <IconButton aria-label="back" onClick={() => onClick("back")}>
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography
            sx={{ m: 1, fontSize: "1.45rem", color: "primary.dark" }}
            variant="h4"
          >
            Cliente de aplicación
          </Typography>
        </Box>
        <Box sx={{ m: 1 }}>
          <Button
            sx={{ mr: 1, fontSize: "0.75rem", fontWeight: "400" }}
            color="primary"
            variant="contained"
            onClick={() => onClick("configuracion")}
          >
            Ver configuración
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

