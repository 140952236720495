const STORAGE_KEYS = {
  TOKEN_KEY: "accessToken",
  REFRESH_TOKEN_KEY: "refreshToken",
  EMAIL: "email",
  USER_NAME: "nombre",
  USER_LASTNAME: "apellido",
  USER_ID: "id",
};


export const logout = () => {  
  localStorage.removeItem(
    STORAGE_KEYS.REFRESH_TOKEN_KEY
  );
  localStorage.removeItem(STORAGE_KEYS.TOKEN_KEY);
};

export const isAuthenticated = () => {
  if (localStorage.getItem(STORAGE_KEYS.TOKEN_KEY)) {
    return true;
  }

  return false;
};
