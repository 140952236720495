import { Box, Typography } from "@mui/material";

export const DocumentacionMainToolbar = (props) => {
  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
        }}
      >
        <Typography
          sx={{ m: 1, fontSize: "1.45rem", color: "primary.dark" }}
          variant="h4"
        >
          Documentación - Botón de prescripción 
        </Typography>
      </Box>
    </Box>
  );
};
