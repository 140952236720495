import "./configuration.scss";
import React, { useContext, useEffect } from "react";
import { Box, Container, Grid, Typography, Alert } from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { ButtonAplicacion } from "../../components/configuration/button-aplicacion";
import {ConfigurationMainToolbar} from "../../components/configuration/configuration-main-toolbar";
import { GlobalContext } from "../../context/Provider";
import getAplications from "../../context/actions/aplications/getAplications";
import CircularProgress from "@mui/material/CircularProgress";



const Configuration = () => {
  const { aplicationsDispatch, aplicationsState } = useContext(GlobalContext);

  const {
    aplications: { data, loading },
  } = aplicationsState;

  useEffect(() => {
    if (data.length === 0) {
      getAplications()(aplicationsDispatch);
    }
  }, []);

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ my: 3 }}>
            <ConfigurationMainToolbar />
            <Grid sx={{ my: 2 }}>
              <Typography sx={{ fontSize: "1.00rem", color: "primary.dark" }}>
                {" "}
                Selecciona la aplicación para configurarla
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              {!!loading && (
                <Grid item lg={3} sm={4} xl={3} xs={12}>
                  <Box
                    sx={{
                      alignItems: "center",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </Grid>
              )}
              {!loading &&
                data.length > 0 &&
                data.map((cliente, i) => {
                  return (
                    <Grid key={i} item lg={3} sm={4} xl={3} xs={12}>
                      <ButtonAplicacion
                        id={cliente.id}
                        name={cliente.appName}
                        activo={cliente.activo}
                      />
                    </Grid>
                  );
                })}
              {!loading && data.length === 0 && (
                <Box
                  sx={{
                    alignItems: "center",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Alert severity="info">
                    No hay aplicaciones dadas de alta en la plataforma
                  </Alert>
                </Box>
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default Configuration;



const clientes = [
  {
    appName: "OSDE prescriptor",
    appUrl: "https://prescriptor.osde.com",
    personaFisica: false,
    cuit: 27389785001,
    razonSocial: "OSDE",
    aceptaTerminos: true,
    email: "info@gmail.com",
    tipoDoc: "CUIT",
    nroDoc: null,
    activo: true,
    id: "124uqehuiwqiue",
  },
  {
    appName: "Innova recetas",
    appUrl: "https://prescriptor.innovamed.com",
    personaFisica: false,
    cuit: 27239085304,
    razonSocial: "INNOVAMED",
    aceptaTerminos: true,
    email: "info@gmail.com",
    tipoDoc: "CUIT",
    nroDoc: null,
    activo: false,
    id: "289uqequitkiwi",
  },
];