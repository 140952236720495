import axios from "axios";
import { API_BASE_URL } from "../constants/apiConstants";

class RecetaService {
  static duplicarFormulario(data) {
    return axios
      .post(API_BASE_URL + "GenerateForm/Duplicate", data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
  static generarReceta(data) {
    return axios
      .post(API_BASE_URL + "Receta/Create", data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        return error;
      });
  }
  static getRecetaById(hash, id) {
    return axios
      .get(API_BASE_URL + "Receta/GetRecetaById/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
          hash: hash,
          ContentType: "application/json",
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static descargarRecetaById(hash, id) {
    return axios
      .get(API_BASE_URL + "Receta/DescargarRecetaById/" + id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
          hash: hash,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static enviarRecetaByEmail(hash, email) {
    return axios
      .get(API_BASE_URL + "Receta/Compartir/" + email, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
          hash: hash,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getRecordRecipes(hash, clientAppId, medicoId, pacienteId) {
    return axios
      .get(
        `${API_BASE_URL}Receta/GetHistorialRecetas/${clientAppId}/${medicoId}/${pacienteId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
            hash: hash,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default RecetaService;