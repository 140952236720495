import React, { createContext, useReducer } from "react";
import auth from "./reducers/auth";
import authInitialState from "./initialstates/authInitialState";
import users from "./reducers/users";
import usersInitialState from "./initialstates/usersInitialState";
import aplications from "./reducers/aplications";
import aplicationsInitialState from "./initialstates/aplicationsInitialState";
import configuration from "./reducers/configuration";
import configurationInitialState from "./initialstates/configurationInitialState";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(auth, authInitialState);
  const [usersState, usersDispatch] = useReducer(
    users,
    usersInitialState
  );
  const [aplicationsState, aplicationsDispatch] = useReducer(
    aplications,
    aplicationsInitialState
  );
  const [configurationState, configurationDispatch] = useReducer(
    configuration,
    configurationInitialState
  );

  return (
    <GlobalContext.Provider
      value={{
        authState,
        authDispatch,
        usersState,
        usersDispatch,
        aplicationsState,
        aplicationsDispatch,
        configurationState,
        configurationDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
