// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clients .label {
  font-size: 24px;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/clients/clients.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,UAAA;AAAR","sourcesContent":[".clients {\n    .label {\n        font-size: 24px;\n        color: red;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
