import {
  USERS_LOADING,
  USERS_LOAD_SUCCESS,
  USERS_LOAD_ERROR,
  ADD_USER_LOAD,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER_LOAD,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  CLEAR_ADD_USER,
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
} from "../../constants/actionTypes";

const users = (state, { payload, type }) => {
  switch (type) {
    case UPDATE_USER_LOAD:
    case USERS_LOADING: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    }

    case USERS_LOAD_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          data: payload,
        },
      };
    }
    case USERS_LOAD_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: payload,
        },
      };
    }

    case ADD_USER_LOAD: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          error: null,
          loading: true,
        },
      };
    }

    case ADD_USER_ERROR: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          loading: false,
        },
      };
    }

    case ADD_USER_SUCCESS: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          loading: false,
          data: payload,
        },

        users: {
          ...state.users,
          loading: false,
          data: [payload, ...state.users.data],
        },
      };
    }

    case UPDATE_USER_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: payload,
        },
      };
    }

    case UPDATE_USER_SUCCESS: {
      return {
        users: {
          ...state.users,
          loading: false,
          data: state.users.data.map((item) => {
            if (item.id === payload.id) {
              return { ...item, nombre: payload.nombre, apellido: payload.apellido, rol: payload.rol };
            }
            return item;
          }),
        },
      };
    }

    case CLEAR_ADD_USER: {
      return {
        ...state,
        addUser: {
          ...state.addUser,
          error: null,
          loading: false,
          data: null,
        },
      };
    }

    case DELETE_USER_LOADING: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          data: state.users.data.map((item) => {
            if (item.id === payload.id) {
              return { ...item, deleting: true };
            }
            return item;
          }),
        },
      };
    }

    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          data: state.users.data.filter((item) => item.id !== payload.id),
        },
      };
    }

    default:
      return state;
  }
};

export default users;
