import { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PageNavbar } from "./page-navbar";
import { PageSidebar } from "./page-sidebar";

const PageLayoutRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: open
    ? {
        paddingLeft: 280,
      }
    : {
        paddingLeft: 65,
      },
}));

export const PageLayout = (props) => {
  const { children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSidebarPersistentOpen, setSidebarPersistentOpen] = useState(false);

  return (
    <>
      <PageLayoutRoot open={isSidebarPersistentOpen}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </PageLayoutRoot>
      <PageNavbar
        onSidebarOpen={() => setSidebarOpen(true)}
        onSidebarPersistentOpen={() =>
          setSidebarPersistentOpen(!isSidebarPersistentOpen)
        }
        openPersistent={isSidebarPersistentOpen}
      />
      <PageSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
        openPersistent={isSidebarPersistentOpen}
      />
    </>
  );
};
