import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ListItem, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";

export const IconNavItem = (props) => {
  const { href, icon, title, ...others } = props;
  const location = useLocation();
  const active = href ? location.pathname.includes(href) : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
      }}
      {...others}
    >
      <Link to={href} style={{ textDecoration: "none", width: "100%" }}>
        <IconButton
          sx={{
            backgroundColor: active && "rgba(255,255,255, 0.08)",
            color: active ? "secondary.main" : "neutral.300",
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.08)",
            },
          }}
        >
          {icon}
        </IconButton>
      </Link>
    </ListItem>
  );
};

IconNavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
};
