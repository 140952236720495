import React from "react";
import { ChromePicker } from "react-color";

const ColorPicker = (props) => {
  const {
    label,
    color,
    handleClose,
    handleClick,
    handleOnChange,
    displayColorPicker,
    name
  } = props;
  return (
      <div>
        <div style={{ paddingBottom: "10px" }}>
          <p style={{ fontSize: "0.85rem", color: "#121828" }}>{label}</p>
        </div>
        <div
          style={{
            padding: "5px",
            background: "white",
            borderRadius: "3px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-flex",
            cursor: "pointer",
            flexDirection: "row",
          }}
          onClick={()=>handleClick(name)}
        >
          <div
            style={{
              width: "22px",
              height: "22px",
              borderRadius: "2px",
              backgroundColor: color,
              marginRight: "5px",
            }}
          />
          <p style={{ fontSize: "0.80rem", color: color }}>{color}</p>
        </div>

        {displayColorPicker === name && (
          <div
            style={{
              position: "absolute",
              zIndex: "2",
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={handleClose}
            />
            <ChromePicker color={color} onChange={(color)=>handleOnChange(color, name)} />
          </div>
        )}
      </div>
  );
};

export default ColorPicker;
