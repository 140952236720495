import React, { useRef } from "react";
import { API_BASE_URL } from "../../constants/apiConstants";
import {
  action,
  computed,
  observable,
  makeAutoObservable,
  runInAction,
  autorun,
} from "mobx";
import ConfigurationService from "../../services/ConfigurationService";
import RecetaService from "../../services/RecetaService";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { async } from "regenerator-runtime";

class Store {
  hashId = 0;
  navigate = null;
  previousControllerDiagnosticos = useRef();
  loadingConfig = null;
  paciente = null;
  medico = null;
  urlCallback = null;
  dataForm = null;
  estilos = null;
  sectionsEnabled = null;
  error = null;
  recetasIds = [];
  recetasGeneradas = [];
  loadingReceta = false;
  loadingDownloadReceta = false;
  errorPostGeneracion = null;
  textAccionReceta = "Generando receta ...";
  valueDni = null;
  errorRenovar = null;
  leyenda = "";
  medicamentosReceta = [];
  posdatadas = [{ id: 1, fecha: dayjs(new Date() + 20) }];
  diagnostico = null;
  cantidadRecetas = 1;
  diagnosticos = [];
  openSessionExpired = false;
  imprimirCodigo = false;

  emailValidado = false;
  emailPaciente = null;
  emailEnviado = false;
  loadingSendEmail = false;
  errorSendEmail = null;

  //Medicamentos
  showDialogAddMedicamento = false;
  showDialogEditMedicamento = false;
  showDialogDeleteMedicamento = false;
  showDialogShareRecipes = false;

  cantidad = 1;
  presentaciones = [];
  presentacionSeleccionada = null;
  marcas = [];
  drogas = [];
  marcaSeleccionada = null;
  drogaSeleccionada = null;
  loadingSearchMarca = false;
  loadingSearchDroga = false;
  medOriginal = false;
  medGenerico = false;
  tratamiento = false;
  tratamientoProlongado = false;
  posologia = "";
  diagnosticosByMed = [];
  previousControllerDiagnosticosByMed = useRef();
  diagnosticoByMed = null;
  imprimirCodigoByMed = false;
  productSelected = "";
  panelConfiguracionMedico = false;
  agregaMedicamento = false;
  maxCantidadEnvases = 1;
  productoYaExistente = false;
  timerInterval;
  tokenVencido = 1200;
  borrado = false;
  timerOnChangeLeyenda = null;
  timerOnInputChange = null;
  timerSearchMedicamentosByMarca = null;
  timerSearchMedicamentosByDroga = null;
  timerSearchDiagnosticosByMed = null;
  setOldRecipe = false;
  oldRecipe = null;

  constructor(hashId, navigate) {
    this.startControlToken();
    makeAutoObservable(this, {
      loadingConfig: observable,
      paciente: observable,
      medico: observable,
      urlCallback: observable,
      dataForm: observable,
      estilos: observable,
      sectionsEnabled: observable,
      error: observable,
      recetasIds: observable,
      recetasGeneradas: observable,
      loadingReceta: observable,
      loadingDownloadReceta: observable,
      errorPostGeneracion: observable,
      textAccionReceta: observable,
      valueDni: observable,
      errorRenovar: observable,
      leyenda: observable,
      medicamentosReceta: observable,
      posdatadas: observable,
      diagnostico: observable,
      cantidadRecetas: observable,
      diagnosticos: observable,
      openSessionExpired: observable,
      imprimirCodigo: observable,
      showDialogAddMedicamento: observable,
      showDialogEditMedicamento: observable,
      showDialogDeleteMedicamento: observable,
      cantidad: observable,
      presentaciones: observable,
      presentacionSeleccionada: observable,
      marcas: observable,
      drogas: observable,
      emailValidado: observable,
      emailPaciente: observable,
      emailEnviado: observable,
      loadingSendEmail: observable,
      errorSendEmail: observable,
      marcaSeleccionada: observable,
      drogaSeleccionada: observable,
      loadingSearchMarca: observable,
      loadingSearchDroga: observable,
      medOriginal: observable,
      medGenerico: observable,
      tratamiento: observable,
      posologia: observable,
      diagnosticosByMed: observable,
      diagnosticoByMed: observable,
      imprimirCodigoByMed: observable,
      productSelected: observable,
      panelConfiguracionMedico: observable,
      openPanelConfiguracionMedico: observable,
      agregaMedicamento: observable,
      maxCantidadEnvases: observable,
      productoYaExistente: observable,
      borrado: observable,
      tratamientoProlongado: observable,
      hayDiagnosticoSeleccionado: observable,
      fetchData: action,
      renovarSesion: action,
      setDocumentoMedico: action,
      onChangeLeyenda: action,
      duplicarReceta: action,
      addDiagnostico: action,
      onInputChange: action,
      handleChangePostdatada: action,
      setShowDialogAddMedicamento: action,
      setShowDialogEditMedicamento: action,
      setShowDialogDeleteMedicamento: action,
      setShowDialogShareRecipes: action,
      onInputChangeMedicamentos: action,
      seleccionarMedicamento: action,
      onChangePosologia: action,
      saveConfiguracionMedico: action,
      startControlToken: action.bound,
      setTokenVencido: computed,
      getRecord: action,
    });
    this.hashId = hashId;
    this.navigate = navigate;
  }

  get setTokenVencido() {
    if (this.tokenVencido < 0) {
      clearInterval(this.timerInterval);
      this.tokenVencido = 1200;
      this.handleClickOpenSessionExpired();
    }
  }

  startControlToken() {
    autorun(() => this.setTokenVencido);

    this.timerInterval = setInterval(
      () =>
        runInAction(() => {
          if (this.tokenVencido > 0) {
            let token = jwt_decode(sessionStorage.getItem("tokenByHash"));
            this.tokenVencido = token.exp - (new Date().getTime() + 1) / 1000;
          }
        }),
      10000
    );
  }

  fetchData = async (data) => {
    if (sessionStorage.getItem("tokenByHash")) {
      const token = jwt_decode(sessionStorage.getItem("tokenByHash"));
      const isExpired = token.exp < (new Date().getTime() + 1) / 1000;

      if (isExpired) {
        this.handleClickOpenSessionExpired();
      } else {
        await this.getConfiguracion();
      }
    } else {
      const data = await ConfigurationService.loginByHash(this.hashId);

      if (data.status === "Ok") {
        sessionStorage.setItem("tokenByHash", data.result.accessToken);
        await this.getConfiguracion();
      } else {
        this.handleClickOpenSessionExpired();
      }
    }
  };

  getRecord = async (data) => {
    try {
      let recods = [];
      let response = await ConfigurationService.getRecord(
        data.appId,
        data.medicoId,
        data.pacienteId
      );
      if (response) {
        // ordenar por fechaVigencia
        response = response.sort((a, b) => {
          return new Date(b.fechaVigencia) - new Date(a.fechaVigencia);
        });
        response.map((p) => {
          recods.push({
            numero: p.numeroReceta,
            id: p.numeroReceta,
            estado: p.estado.toUpperCase(),
            medicamentos: p.jsonEnviado.medicamentos,
            fecha: p.fechaVigencia,
            recipe: p.jsonEnviado,
            hash: p.formBotonId,
            idReceta: p.idReceta,
          });
        });
        return recods;
      }
    } catch (err) {
      return err;
    }
  };

  unsubscribeRecipe = async (id) => {
    try {
      let response = await ConfigurationService.unsubscribeRecipe(id);
      if (response.data.status === "Ok") {
        return response.data;
      } else {
        this.error = response.errors[0];
      }
    } catch (err) {
      return err;
    }
  };

  getResendRecipe = async (recipeId, emailPaciente, hash) => {
    try {
      let response = await ConfigurationService.getResendRecipe(
        recipeId,
        emailPaciente,
        hash
      );
      if (response.data.status === "Ok") {
        return response.data;
      } else {
        this.error = response.errors[0];
      }
    } catch (err) {
      return err;
    }
  };

  createRecipeWithOldRecipe = async (recipe) => {
    try {
      delete recipe.idMedico;
      delete recipe.idPaciente;
      delete recipe.numeroFinanciador;
      delete recipe.numeroAfiliado;
      delete recipe.nombreConsultorio;
      delete recipe.direccionConsultorio;
      delete recipe.email;
      delete recipe.clienteAppId;
      recipe.recetaPosdatadas = recipe.recetasPostadatas;
      delete recipe.recetasPostadatas;
      let response = await ConfigurationService.createRecipeWithOldRecipe(
        recipe
      );
      if (response.data.status === "Ok") {
        return response.data;
      } else {
        this.error = response.errors[0];
      }
    } catch (err) {
      return err;
    }
  };

  getConfiguracion = async () => {
    try {
      runInAction(() => {
        this.loadingConfig = true;
      });
      let response = await ConfigurationService.getConfigurationByHash(
        this.hashId
      );
      if (response.status === "Ok") {
        let paciente = {};
        paciente.pacienteId = response.result.pacienteId;
        paciente.nombrePaciente = response.result.nombrePaciente;
        paciente.apellidoPaciente = response.result.apellidoPaciente;
        paciente.numeroAfiliado = response.result.numeroAfiliado;
        paciente.financiadorId = response.result.financiadorId;
        paciente.financiadorNombre = response.result.financiadorNombre;
        paciente.plan = response.result.plan;
        paciente.pais = response.result.pais
          ? response.result.pais
          : "Argentina";
        paciente.nroDocumento = response.result.nroDocumentoPaciente
          ? response.result.nroDocumentoPaciente
          : "-";
        paciente.tipoDocumento = response.result.tipoDocumentoPaciente
          ? response.result.tipoDocumentoPaciente
          : "DNI";

        let medico = {};
        medico.medicoId = response.result.medicoId;
        medico.nombreMedico = response.result.nombreMedico;
        medico.apellidoMedico = response.result.apellidoMedico;
        medico.tipoMatricula =
          response.result.tipoMatriculaMedico === "MN"
            ? "Nacional"
            : "Provincial";
        medico.nroMatricula = response.result.matriculaMedico
          ? response.result.matriculaMedico
          : "-";

        this.paciente = paciente;
        this.medico = medico;
        this.dataForm = response.result.configuracion.dataFormulario;

        this.emailPaciente =
          response.result.emailPaciente && response.result.emailPaciente !== ""
            ? response.result.emailPaciente
            : null;

        this.emailValidado =
          response.result.emailPaciente && response.result.emailPaciente !== ""
            ? true
            : false;

        if (this.dataForm) {
          this.maxCantidadEnvases = this.dataForm.cantidadMedicamentoPorReceta;
        }

        this.estilos = response.result.configuracion.estilos;
        this.urlCallback = response.result.urlCallback;
        this.sectionsEnabled = {
          showHeaderSection: response.result.configuracion.showHeaderSection,
          showMedicoSection: response.result.configuracion.showMedicoSection,
          showPacienteSection:
            response.result.configuracion.showPacienteSection,
        };

        if (response.result.formBotonTempData) {
          if (
            response.result.formBotonTempData.medicamentos &&
            response.result.formBotonTempData.medicamentos.length > 0
          ) {
            this.medicamentosReceta =
              response.result.formBotonTempData.medicamentos.map((p) => {
                return {
                  presentacion: p.presentacion,
                  regNo: p.regNo,
                  nombreProducto: p.nombreProducto,
                  nombreDroga: p.nombreDroga,
                  cantidad: p.cantidad,
                  permiteSustitucion: p.permiteSustitucion,
                  tratamiento: p.tratamiento,
                  posologia: p.posologia,
                  diagnostico: p.diagnostico !== "" ? p.diagnostico : "",
                  id: this.randomNumberInRange(100, 1000),
                };
              });
          }

          if (response.result.formBotonTempData.recetaPosdatadas) {
            this.cantidadRecetas =
              response.result.formBotonTempData.recetaPosdatadas.fechas.length;
            this.posdatadas =
              response.result.formBotonTempData.recetaPosdatadas.fechas.map(
                (p, i) => {
                  return { id: i + 1, fecha: dayjs(p, "DD/MM/YYYY") };
                }
              );
          }

          if (
            response.result.formBotonTempData.diagnostico &&
            response.result.formBotonTempData.diagnostico !== ""
          ) {
            this.diagnostico = JSON.parse(
              response.result.formBotonTempData.diagnostico
            );
          }

          if (
            response.result.formBotonTempData.informacionAdicional &&
            response.result.formBotonTempData.informacionAdicional !== ""
          ) {
            this.leyenda = response.result.formBotonTempData.leyenda;
          }

          if (response.result.formBotonTempData.imprimirDiagnostico !== "") {
            this.imprimirCodigo =
              response.result.formBotonTempData.imprimirDiagnostico === "S"
                ? true
                : false;
          }
        }
        runInAction(() => {
          this.loadingConfig = false;
        });

        if (response.result.recetas && response.result.recetas.length > 0) {
          this.textAccionReceta = "Obteniendo datos de la receta ...";
          this.loadingReceta = true;
          this.recetasIds = response.result.recetas.map((p) => {
            return {
              recetaId: p.numeroReceta,
              hash: p.hash,
            };
          });

          await this.getInfoReceta(
            response.result.recetas.map((p) => {
              return {
                recetaId: p.numeroReceta + "falla",
                hash: p.hash,
              };
            })
          );

          this.loadingReceta = false;
        } else {
          this.recetasIds = [];
          this.recetasGeneradas = [];
        }
      } else {
        runInAction(() => {
          this.loadingConfig = false;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.loadingConfig = false;
      });
    }
  };

  getInfoReceta = async (recAux) => {
    let recetasGeneradas = [];

    if (recAux && recAux.length > 0) {
      if (sessionStorage.getItem("recetasGeneradas")) {
        recetasGeneradas = JSON.parse(
          sessionStorage.getItem("recetasGeneradas")
        );
      } else {
        for (let receta of recAux) {
          let aux = {};

          try {
            let detailRecetaResponse = await RecetaService.getRecetaById(
              this.hashId,
              receta.recetaId
            );

            if (detailRecetaResponse.status === "Ok") {
              aux = detailRecetaResponse.result;
              aux.recetaId = receta.recetaId;
            } else {
              this.errorPostGeneracion = detailRecetaResponse.errors[0];
            }
          } catch (err) {
            this.errorPostGeneracion = err;
          }

          try {
            let downloadRecetaResponse =
              await RecetaService.descargarRecetaById(this.hashId, receta.hash);

            if (downloadRecetaResponse.status === "Ok") {
              aux.linkReceta = downloadRecetaResponse.result.s3Link;
            } else {
              this.errorPostGeneracion = downloadRecetaResponse.errors[0];
            }
          } catch (err) {
            this.errorPostGeneracion = err;
          }
          recetasGeneradas.push(aux);
        }
      }

      if (!sessionStorage.getItem("recetasGeneradas")) {
        sessionStorage.setItem(
          "recetasGeneradas",
          JSON.stringify(recetasGeneradas)
        );
      }

      this.recetasGeneradas = recetasGeneradas;
    }
  };

  handleClickOpenSessionExpired = async () => {
    this.openSessionExpired = true;
  };

  saveConfiguracionMedico = async (action) => {
    if (action === "salir") {
      this.openPanelConfiguracionMedico();
    } else {
      this.openPanelConfiguracionMedico();
    }
  };

  openPanelConfiguracionMedico = () => {
    this.panelConfiguracionMedico = !this.panelConfiguracionMedico;
  };

  handleCloseSessionExpired = async () => {
    this.openSessionExpired = false;
  };

  setShowDialogAddMedicamento = async (value) => {
    this.showDialogAddMedicamento = value;
  };

  setShowDialogEditMedicamento = async (value) => {
    this.showDialogEditMedicamento = value;
  };

  setShowDialogDeleteMedicamento = async (value) => {
    this.showDialogDeleteMedicamento = value;
  };

  setShowDialogShareRecipes = async (value) => {
    this.showDialogShareRecipes = value;
    this.emailEnviado = false;
    this.errorSendEmail = null;
  };

  renovarSesion = async () => {
    try {
      const data = await ConfigurationService.renovarSessionByHash(
        this.hashId,
        this.valueDni
      );

      if (data.status === "Ok") {
        this.handleCloseSessionExpired();
        sessionStorage.setItem("tokenByHash", data.result.accessToken);
        this.getConfiguracion();
      } else {
        this.errorRenovar = data.errors[0];
      }
    } catch (err) {
      this.errorRenovar = err;
    }
  };

  setDocumentoMedico = (event) => {
    this.errorRenovar = null;
    this.valueDni = event.target.value;
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  onChangeEmail = (event) => {
    this.emailPaciente = event.target.value;

    this.emailValidado =
      this.emailPaciente &&
      this.emailPaciente !== "" &&
      this.validateEmail(this.emailPaciente)
        ? true
        : false;
  };

  onChangeLeyenda = (event) => {
    try {
      this.leyenda = event.target.value;
      let dataRecipe = {};
      dataRecipe.hash = this.hashId;
      dataRecipe.diagnostico = this.diagnostico
        ? JSON.stringify(this.diagnostico)
        : "";
      dataRecipe.horario = "";
      dataRecipe.diasAtencion = "";
      dataRecipe.datosContacto = "";
      dataRecipe.leyenda = event.target.value ? event.target.value : "";
      dataRecipe.informacionAdicional = event.target.value
        ? event.target.value
        : "";
      dataRecipe.medicamentos = this.medicamentosReceta.map((data) => ({
        presentacion: data.presentacion,
        nombreProducto: data.nombreProducto,
        nombreDroga: data.nombreDroga,
        cantidad: data.cantidad,
        permiteSustitucion: data.permiteSustitucion.toString(),
        tratamiento: parseInt(data.tratamiento, 10),
        diagnostico: data.diagnostico,
        posologia: data.posologia,
        regNo: parseInt(data.regNo, 10),
      }));
      let recetasPostdatadas = {};
      recetasPostdatadas.cantidad = this.posdatadas.length;
      recetasPostdatadas.diasAPosdatar = 18;
      recetasPostdatadas.fechas = this.posdatadas.map((data) =>
        data.fecha.format("DD/MM/YYYY")
      );
      dataRecipe.recetaPosdatadas = recetasPostdatadas;
      if (this.timerOnChangeLeyenda) {
        clearTimeout(this.timerOnChangeLeyenda);
      }
      this.timerOnChangeLeyenda = setTimeout(() => {
        fetch(API_BASE_URL + "GenerateForm/Update", {
          method: "PUT",
          body: JSON.stringify(dataRecipe),
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }).then((response) => {
          return response;
        });
      }, 500);
    } catch (error) {
      return error;
    }
  };

  duplicarReceta = async () => {
    const token = jwt_decode(sessionStorage.getItem("tokenByHash"));
    const isExpired = token.exp < (new Date().getTime() + 1) / 1000;

    if (isExpired) {
      this.handleClickOpenSessionExpired();
    } else {
      runInAction(() => {
        this.loadingConfig = true;
      });

      try {
        let response = await RecetaService.duplicarFormulario({
          hash: this.hashId,
        });

        if (response.status === "Ok") {
          this.hashId = response.result.hash;

          this.medicamentosReceta = [];
          this.leyenda = "";
          this.posdatadas = [{ id: 1, fecha: dayjs(new Date() + 20) }];
          this.diagnostico = null;
          this.cantidadRecetas = 1;
          this.recetasIds = [];
          this.tratamientoProlongado = false;
          sessionStorage.removeItem("recetasGeneradas");


          this.navigate(`/formulario/${response.result.hash}`);
          await this.getConfiguracion();

          if (this.setOldRecipe) {
            this.setOldReceipeInForm(this.oldRecipe);
          }

          runInAction(() => {
            this.loadingConfig = false;
          });
        } else {
          runInAction(() => {
            this.loadingConfig = false;
          });
          console.error(response.errors[0]);
        }
      } catch (error) {
        runInAction(() => {
          this.loadingConfig = false;
        });
      }
    }
  };

  setOldReceipeInForm = async (recipe) => {
    recipe.medicamentos.map(async (p) => {
      this.medicamentosReceta.push({
        presentacion: p.presentacion,
        regNo: p.regNo,
        nombreProducto: p.nombreProducto,
        nombreDroga: p.nombreDroga,
        cantidad: p.cantidad,
        permiteSustitucion:
          p.permiteSustitucion.length > 0 && p.permiteSustitucion === "S"
            ? '{"o":true,"g":false}'
            : p.permiteSustitucion === "N"
            ? '{"o":false,"g":true}'
            : '{"o":false,"g":false}',
        tratamiento: p.tratamiento,
        posologia: p.posologia,
        diagnostico: p.diagnostico.length > 0 ? JSON.stringify(await this.getDiagnostico(p.diagnostico, p.regNo, recipe.recetaId)) : null,

        id: this.randomNumberInRange(100, 1000),
      });
    });
    this.leyenda = recipe.leyenda;
    this.diagnostico =
      recipe.diagnostico.length > 0
        ? await this.getDiagnostico(recipe.diagnostico, recipe.regNo, recipe.recetaId)
        : null;

    this.updateReceta();
    this.setOldRecipe = false;
  };

  getDiagnostico = async (search, regNo = 0, recetaId) => {

    let data = await fetch(
      `${API_BASE_URL}Diagnostico/GetDiagnosticoByCode/${search}/${recetaId}/${regNo}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
        },
      }
    );
    let reponse = await data.json();
    return reponse.result;
  };

  addDiagnostico = (value) => {
    this.diagnostico = value;
    this.updateReceta();
  };

  getData = (searchTerm) => {
    if (this.timerOnInputChange) {
      clearTimeout(this.timerOnInputChange);
    }

    this.timerOnInputChange = setTimeout(() => {
      fetch(API_BASE_URL + "Diagnostico/GetDiagnosticoByQuery/" + searchTerm, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          hash: this.hashId,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          const updatedOptions = myJson.results;

          if (updatedOptions && updatedOptions.length > 0) {
            this.diagnosticos =
              updatedOptions &&
              updatedOptions !== undefined &&
              updatedOptions.length > 0
                ? updatedOptions
                : [];
          } else {
            this.diagnostico = {
              idDiagnostico: 999999,
              codDiagnostico: "AA9900",
              descDiagnostico: searchTerm,
            };
          }
        });
    }, 300);
  };

  onInputChange = (value) => {
    if (value) {
      this.getData(value);
    } else {
      this.diagnosticos = [];
    }
  };

  handleChangePostdatada = (id, value) => {
    this.posdatadas = this.posdatadas.map((item) => {
      if (item.id === id) {
        return {
          id: item.id,
          fecha: value,
        };
      }
      return { ...item };
    });
    this.updateReceta();
  };

  executeAction = async (action, data) => {
    if (action === "imprimir") {
      this.imprimirCodigo = !this.imprimirCodigo;
      this.updateReceta();
    } else if (action === "share") {
      this.showDialogShareRecipes = true;
    } else if (action === "imprimir-particular") {
      this.imprimirCodigoByMed = !this.imprimirCodigoByMed;
    } else if (action === "add-medicamento") {
      this.showDialogAddMedicamento = !this.showDialogAddMedicamento;
      this.inicializarValores();
    } else if (action === "presentacion") {
      this.presentacionSeleccionada = data;
    } else if (action === "add") {
      this.cantidad = this.cantidad + 1;
    } else if (action === "send-email") {
      this.loadingSendEmail = true;
      try {
        let response = await RecetaService.enviarRecetaByEmail(
          this.hashId,
          this.emailPaciente
        );

        if (response.status === "Ok") {
          this.loadingSendEmail = false;
          this.emailEnviado = true;
        } else {
          this.loadingSendEmail = false;
          this.errorSendEmail =
            "Ocurrió un error al compartir las receta/s. Vuelva a intentarlo más tarde. " +
            response.errors[0];
        }
      } catch (error) {
        this.loadingSendEmail = false;
        this.errorSendEmail =
          "Ocurrió un error al compartir las receta/s. Vuelva a intentarlo más tarde.";
      }
    } else if (action === "remove") {
      if (this.cantidad > 1) {
        this.cantidad = this.cantidad - 1;
      }
    } else if (action === "add-med") {
      const aux = {};
      aux.presentacion = this.presentacionSeleccionada.presentacion;
      aux.regNo = this.presentacionSeleccionada.regNo;
      aux.nombreProducto = this.marcaSeleccionada.nombreProducto;
      aux.nombreDroga = this.drogaSeleccionada.nombreDroga;
      aux.cantidad = this.cantidad;
      aux.permiteSustitucion = JSON.stringify({
        o: this.medOriginal,
        g: this.medGenerico,
      });
      aux.tratamiento = this.tratamiento ? 1 : 0;
      aux.posologia = this.posologia;
      aux.diagnostico = this.diagnosticoByMed
        ? JSON.stringify({
            idDiagnostico: this.diagnosticoByMed.idDiagnostico,
            codDiagnostico: this.diagnosticoByMed.codDiagnostico,
            descDiagnostico: this.diagnosticoByMed.descDiagnostico,
          })
        : null;

      aux.id = this.randomNumberInRange(100, 1000);

      if (
        this.medicamentosReceta.filter(
          (p) =>
            p.regNo.toString() ===
              this.presentacionSeleccionada.regNo.toString() &&
            p.nombreProducto === this.marcaSeleccionada.nombreProducto &&
            p.nombreDroga === this.drogaSeleccionada.nombreDroga
        ).length > 0
      ) {
        runInAction(() => {
          this.productoYaExistente = true;
        });
      } else {
        this.medicamentosReceta.push(aux);

        this.showDialogAddMedicamento = !this.showDialogAddMedicamento;
        this.updateReceta();
      }
    } else if (action === "tratamiento") {
      runInAction(() => {
        this.tratamientoProlongado = !this.tratamientoProlongado;
      });
    } else if (action === "edit-med") {
      if (
        this.medicamentosReceta.filter(
          (p) =>
            p.regNo.toString() ===
              this.presentacionSeleccionada.regNo.toString() &&
            p.nombreProducto === this.marcaSeleccionada.nombreProducto &&
            p.nombreDroga === this.drogaSeleccionada.nombreDroga
        ).length > 0 &&
        this.productSelected.nombreDroga !==
          this.drogaSeleccionada.nombreDroga &&
        this.productSelected.nombreProducto !==
          this.marcaSeleccionada.nombreProducto &&
        this.productSelected.regNo.toString() !==
          this.presentacionSeleccionada.regNo.toString()
      ) {
        runInAction(() => {
          this.productoYaExistente = true;
        });
      } else {
        this.medicamentosReceta = this.medicamentosReceta.map((medicamento) => {
          if (medicamento.id === this.productSelected.id) {
            return {
              ...medicamento,
              presentacion: this.presentacionSeleccionada.presentacion,
              regNo: this.presentacionSeleccionada.regNo,
              nombreProducto: this.marcaSeleccionada.nombreProducto,
              nombreDroga: this.drogaSeleccionada.nombreDroga,
              cantidad: this.cantidad,
              permiteSustitucion: JSON.stringify({
                o: this.medOriginal,
                g: this.medGenerico,
              }),
              tratamiento: this.tratamiento ? 1 : 0,
              posologia: this.posologia,
              diagnostico:
                this.diagnosticoByMed &&
                typeof this.diagnosticoByMed === "string"
                  ? this.diagnosticoByMed
                  : this.diagnosticoByMed &&
                    typeof this.diagnosticoByMed !== "string"
                  ? JSON.stringify({
                      idDiagnostico: this.diagnosticoByMed.idDiagnostico,
                      codDiagnostico: this.diagnosticoByMed.codDiagnostico,
                      descDiagnostico: this.diagnosticoByMed.descDiagnostico,
                    })
                  : null,
            };
          }
          return medicamento;
        });
        this.showDialogEditMedicamento = !this.showDialogEditMedicamento;
        this.updateReceta();
      }
    } else if (action === "add-postdatadas") {
      if (this.cantidadRecetas < this.dataForm.cantidadRecetasPostdatadas + 1) {
        this.cantidadRecetas = this.cantidadRecetas + 1;

        let receta = {};
        let aux = this.posdatadas;

        receta.id = aux.length + 1;
        receta.fecha = aux[aux.length - 1].fecha.add(18, "day");
        aux.push(receta);
        this.posdatadas = aux;
        this.updateReceta();
      }
    } else if (action === "remove-postdatadas") {
      if (this.cantidadRecetas > 1) {
        this.cantidadRecetas = this.cantidadRecetas - 1;
        this.posdatadas = this.posdatadas.filter(
          (p) => p.id !== this.posdatadas.length
        );
      }
      this.updateReceta();
    } else if (action === "clear") {
      this.inicializarValores();
    } else if (action === "original") {
      this.medOriginal = !this.medOriginal;
      if (this.medGenerico) this.medGenerico = false;
    } else if (action === "generico") {
      this.medGenerico = !this.medGenerico;
      if (this.medOriginal) this.medOriginal = false;
    } else if (action === "tratamiento") {
      this.tratamiento = !this.tratamiento;
    } else if (action === "edit") {
      this.productSelected = data;
      this.cantidad = data.cantidad;
      this.marcaSeleccionada = {
        nombreProducto: data.nombreProducto,
        nombreDroga: data.nombreDroga,
      };
      this.drogaSeleccionada = {
        nombreProducto: data.nombreProducto,
        nombreDroga: data.nombreDroga,
      };
      this.presentacionSeleccionada = {
        presentacion: data.presentacion,
        regNo: data.regNo,
      };
      this.medOriginal = JSON.parse(data.permiteSustitucion).o;
      this.medGenerico = JSON.parse(data.permiteSustitucion).g;
      this.posologia = data.posologia;
      this.imprimirCodigo = data.imprimirCodigo;
      this.diagnosticoByMed = data.diagnostico;
      this.tratamiento = data.tratamiento === 0 ? false : true;

      this.showDialogEditMedicamento = !this.showDialogEditMedicamento;
    } else if (action === "delete") {
      runInAction(() => {
        this.borrado = false;
        this.productSelected = data;
        this.showDialogDeleteMedicamento = !this.showDialogDeleteMedicamento;
      });
    } else if (action === "cancelar-delete") {
      this.showDialogDeleteMedicamento = !this.showDialogDeleteMedicamento;
    } else if (action === "confirmar-delete") {
      this.borrado = true;
      this.medicamentosReceta = this.medicamentosReceta.filter(
        (m) => m.id !== data
      );
      this.showDialogDeleteMedicamento = !this.showDialogDeleteMedicamento;

      this.updateReceta();
    } else if (action === "generar-receta") {
      this.error = null;

      let dataRecipe = {};
      dataRecipe.hash = this.hashId;
      dataRecipe.diagnostico = this.diagnostico
        ? this.diagnostico.descDiagnostico
        : "";

      dataRecipe.diasAtencion = "";
      dataRecipe.datosContacto = "";
      dataRecipe.leyenda = this.leyenda ? this.leyenda : "";
      dataRecipe.imprimirDiagnostico = this.imprimirCodigo ? "N" : "S";
      var tzoffset = new Date().getTimezoneOffset() * 60000;

      dataRecipe.fechaEmision = this.posdatadas
        .filter((p) => p.id === 1)
        .map((data) =>
          new Date(data.fecha - tzoffset).toISOString().slice(0, -1)
        )[0];

      dataRecipe.informacionAdicional = this.leyenda ? this.leyenda : "";
      dataRecipe.medicamentos = this.medicamentosReceta.map((data) => ({
        presentacion: data.presentacion,
        nombreProducto: data.nombreProducto,
        nombreDroga: data.nombreDroga,
        cantidad: data.cantidad,
        permiteSustitucion: JSON.parse(data.permiteSustitucion).o
          ? "N"
          : JSON.parse(data.permiteSustitucion).g
          ? "S"
          : "",
        tratamiento: this.tratamientoProlongado ? 1 : 0,
        diagnostico: data.diagnostico
          ? JSON.parse(data.diagnostico).descDiagnostico
          : "",
        posologia: data.posologia,
        regNo: parseInt(data.regNo, 10),
      }));

      let recetasPostdatadas = {};
      recetasPostdatadas.cantidad =
        this.posdatadas.length === 1 ? 0 : this.posdatadas.length - 1;
      recetasPostdatadas.diasAPosdatar = 18;
      if (this.posdatadas.length > 1) {
        recetasPostdatadas.fechas = this.posdatadas
          .filter((p) => p.id !== 1)
          .map((data) => data.fecha.format("DD/MM/YYYY"));
      }
      dataRecipe.recetaPosdatadas = recetasPostdatadas;

      this.loadingReceta = true;
      let recIdsAuxiliar = [];

      try {
        let response = await RecetaService.generarReceta(dataRecipe);

        if (response.status === "Ok") {
          this.recetasIds = response.results;
          recIdsAuxiliar = response.results;
        } else {
          this.error = response.errors[0];
          this.loadingReceta = false;
        }
      } catch (error) {
        this.loadingReceta = false;
        this.error = error;
        return error;
      }

      let recAux = [];
      if (recIdsAuxiliar && recIdsAuxiliar.length > 0) {
        let index = 0;
        for (let receta of recIdsAuxiliar) {
          let aux = {};
          aux.diagnostico = this.diagnostico
            ? this.diagnostico.descDiagnostico
            : "";
          aux.imprimirCodigo = this.imprimirCodigo;
          aux.codDiagnostico = this.codDiagnostico
            ? this.diagnostico.codDiagnostico
            : "";
          aux.horario = "";
          aux.diasAtencion = "";
          aux.datosContacto = "";
          aux.informacionAdicional = this.leyenda ? this.leyenda : "";
          aux.medicamentos = this.medicamentosReceta;
          aux.fecha = this.posdatadas.map((data) =>
            data.fecha.format("DD/MM/YYYY")
          )[index];
          aux.recetaId = receta.recetaId;
          aux.numeroReceta = receta.numeroReceta;

          index = index + 1;
          aux.linkReceta = receta.s3Link;
          recAux.push(aux);
        }
      }
      this.loadingReceta = false;
      this.recetasGeneradas = recAux;
      sessionStorage.setItem("recetasGeneradas", JSON.stringify(recAux));
    } else if (action === "reintentar") {
      this.errorPostGeneracion = null;
      this.loadingDownloadReceta = true;

      let recAux = [];

      if (this.recetasIds && this.recetasIds.length > 0) {
        let index = 0;
        for (let receta of this.recetasIds) {
          let aux = {};
          aux.diagnostico = this.diagnostico
            ? this.diagnostico.descDiagnostico
            : "";
          aux.imprimirCodigo = this.imprimirCodigo;
          aux.codDiagnostico = this.codDiagnostico
            ? this.diagnostico.codDiagnostico
            : "";
          aux.horario = "";
          aux.diasAtencion = "";
          aux.datosContacto = "";
          aux.leyenda = this.leyenda ? this.leyenda : "";
          aux.informacionAdicional = this.leyenda ? this.leyenda : "";
          aux.medicamentos = this.medicamentosReceta;
          aux.fecha = this.posdatadas.map((data) =>
            data.fecha.format("DD/MM/YYYY")
          )[index];
          aux.recetaId = receta.recetaId;

          index = index + 1;

          // try {
          //   let response = await RecetaService.getRecetaById(
          //     this.hashId,
          //     receta.recetaId
          //   );

          //   if (response.status === "Ok") {
          //     aux = response.result;
          //     aux.recetaId = receta.recetaId;
          //   } else {
          //     this.errorPostGeneracion = response.errors[0];
          //     this.loadingReceta = false;
          //   }
          // } catch (error) {
          //   this.loadingReceta = false;
          //   this.errorPostGeneracion = error;
          // }

          try {
            let response = await RecetaService.descargarRecetaById(
              this.hashId,
              receta.recetaId
            );

            if (response.status === "Ok") {
              aux.linkReceta = response.result.s3Link;
            } else {
              this.errorPostGeneracion = response.errors[0];
              this.loadingReceta = false;
            }
          } catch (error) {
            this.loadingReceta = false;
            this.errorPostGeneracion = error;
          }
          recAux.push(aux);
        }
      }
      this.loadingDownloadReceta = false;
      this.recetasGeneradas = recAux;
      sessionStorage.setItem("recetasGeneradas", JSON.stringify(recAux));
    }
  };

  randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  searchMedicamentosByMarca = (searchTerm) => {
    if (this.timerSearchMedicamentosByMarca) {
      clearTimeout(this.timerSearchMedicamentosByMarca);
    }
    this.timerSearchMedicamentosByMarca = setTimeout(() => {
      fetch(
        API_BASE_URL +
          "Medicamento/GetMedicamentoByQuery/" +
          searchTerm +
          "/marca",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            hash: this.hashId,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          this.loadingSearchMarca = false;
          const updatedOptions = myJson.results;
          this.marcas = updatedOptions;
        })
        .catch((error) => {
          console.error(error);
        });
    }, 500);
  };

  searchMedicamentosByDroga = (searchTerm) => {
    if (this.timerSearchMedicamentosByDroga) {
      clearTimeout(this.timerSearchMedicamentosByDroga);
    }
    this.timerSearchMedicamentosByDroga = setTimeout(() => {
      fetch(
        API_BASE_URL +
          "Medicamento/GetMedicamentoByQuery/" +
          searchTerm +
          "/marca",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            hash: this.hashId,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          this.loadingSearchDroga = false;
          const updatedOptions = myJson.results;

          if (updatedOptions.length > 0) {
            this.drogas = updatedOptions.sort(this.dynamicSort("nombreDroga"));
          } else {
            this.drogas = updatedOptions;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 500);
  };

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  onInputChangeMedicamentos = (value, groupBy) => {
    if (this.productoYaExistente) {
      runInAction(() => {
        this.productoYaExistente = false;
      });
    }

    if (groupBy === "marca") {
      if (value) {
        this.searchMedicamentosByMarca(value);
      } else {
        this.marcas = [];
      }
    } else {
      if (value) {
        this.searchMedicamentosByDroga(value);
      } else {
        this.drogas = [];
      }
    }
  };

  seleccionarMedicamento = (value, groupBy) => {
    if (groupBy === "marca") {
      if (value) {
        this.marcaSeleccionada = value;
        this.drogaSeleccionada = value;
        this.presentaciones = value.presentaciones;

        if (value.presentaciones.length === 1) {
          this.presentacionSeleccionada = value.presentaciones[0];
        } else {
          this.presentacionSeleccionada = null;
        }
      } else {
        this.presentaciones = [value.presentaciones];
        this.presentacionSeleccionada = null;
        this.marcas = [];
        this.marcaSeleccionada = null;
      }
    } else {
      if (value) {
        this.drogaSeleccionada = value;
        this.marcaSeleccionada = value;
        this.presentaciones = value.presentaciones;

        if (value.presentaciones.length === 1) {
          this.presentacionSeleccionada = value.presentaciones[0];
        } else {
          this.presentacionSeleccionada = null;
        }
      } else {
        this.presentaciones = [];
        this.presentacionSeleccionada = null;
        this.drogas = [];
        this.drogaSeleccionada = null;
      }
    }
  };

  inicializarValores = () => {
    runInAction(() => {
      this.productoYaExistente = false;
      this.marcas = [];
      this.drogas = [];
      this.marcaSeleccionada = null;
      this.drogaSeleccionada = null;
      this.presentaciones = [];
      this.presentacionSeleccionada = null;
      this.cantidad = 1;
      this.tratamiento = false;
      this.medGenerico = false;
      this.medOriginal = false;
      this.posologia = "";
      this.diagnosticoByMed = null;
      this.diagnosticosByMed = [];
      this.imprimirCodigoByMed = false;
    });
  };

  onChangePosologia = (event) => {
    this.posologia = event.target.value;
  };

  searchDiagnosticosByMed = (searchTerm) => {
    if (this.timerSearchDiagnosticosByMed) {
      clearTimeout(this.timerSearchDiagnosticosByMed);
    }
    this.timerSearchDiagnosticosByMed = setTimeout(() => {
      fetch(API_BASE_URL + "Diagnostico/GetDiagnosticoByQuery/" + searchTerm, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          hash: this.hashId,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          const updatedOptions = myJson.results;
          if (updatedOptions && updatedOptions.length > 0) {
            this.diagnosticosByMed = updatedOptions;
          } else {
            this.diagnosticoByMed = {
              idDiagnostico: 999999,
              codDiagnostico: "AA9900",
              descDiagnostico: searchTerm,
            };
          }
        });
    }, 300);
  };

  addDiagnosticoByMed = (value) => {
    this.diagnosticoByMed = value;
  };

  onInputChangeDiagnosticoByMed = (value) => {
    if (this.productoYaExistente) {
      runInAction(() => {
        this.productoYaExistente = false;
      });
    }

    if (value) {
      this.searchDiagnosticosByMed(value);
    } else {
      this.diagnosticosByMed = [];
      this.diagnosticoByMed = null;
    }
  };

  updateReceta = async () => {
    runInAction(() => {
      this.agregaMedicamento = true;
    });
    let dataRecipe = {};

    dataRecipe.hash = this.hashId;
    dataRecipe.diagnostico = this.diagnostico
      ? JSON.stringify(this.diagnostico)
      : "";
    dataRecipe.horario = this.imprimirCodigo ? "S" : "N";
    dataRecipe.diasAtencion = "";
    dataRecipe.datosContacto = "";
    dataRecipe.informacionAdicional = this.leyenda ? this.leyenda : "";
    dataRecipe.leyenda = this.leyenda ? this.leyenda : "";
    dataRecipe.imprimirDiagnostico = this.imprimirCodigo ? "S" : "N";
    dataRecipe.medicamentos = this.medicamentosReceta.map((data) => ({
      presentacion: data.presentacion,
      nombreProducto: data.nombreProducto,
      nombreDroga: data.nombreDroga,
      cantidad: data.cantidad,
      permiteSustitucion: data.permiteSustitucion,
      tratamiento: parseInt(data.tratamiento, 10),
      diagnostico: data.diagnostico,
      posologia: data.posologia,
      regNo: parseInt(data.regNo, 10),
    }));
    let recetasPostdatadas = {};
    recetasPostdatadas.cantidad = this.posdatadas.length;
    recetasPostdatadas.diasAPosdatar = 18;
    recetasPostdatadas.fechas = this.posdatadas.map((data) =>
      data.fecha.format("DD/MM/YYYY")
    );
    dataRecipe.recetaPosdatadas = recetasPostdatadas;

    let response = await ConfigurationService.updateDataForm(dataRecipe);

    runInAction(() => {
      this.agregaMedicamento = false;
    });
  };
}

export default Store;
