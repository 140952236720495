import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import { Typography, Box, Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

const DiagnosticoGeneral = (props) => {
  const { data, diagnostico, setDiagnostico, handleOnChange, checked, title, estilos, onInputChange, particular } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      {title && (
        <Typography
          sx={{
            m: 1,
            fontSize: "0.91rem",
            color: estilos.colorTextSecondary,
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={{
          padding: "15px 15px 2px",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Autocomplete
          id="diagnostico"
          value={diagnostico}
          freeSolo
          clearOnBlur={true}
          onChange={(event, newValue) => {
            setDiagnostico(newValue);
          }}
          onInputChange={(event, value) => onInputChange(value)}
          sx={{ width: "69%" }}
          filterSelectedOptions
          getOptionLabel={(option) => option.descDiagnostico}
          options={data}
          noOptionsText={"Sin resultados"}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              color={estilos.backgroundColorPrimary}
              fullWidth
              label="Diagnóstico"
              id="diagnostico"
            />
          )}
        />
        <CustomTextField
          size="small"
          color={estilos.backgroundColorPrimary}
          sx={{ width: "29%" }}
          label="Código"
          disabled
          value={
            diagnostico && diagnostico.idDiagnostico !== 999999
              ? diagnostico.codDiagnostico
              : ""
          }
          InputProps={{
            classes: {
              input: classes.resizeFont,
            },
          }}
          InputLabelProps={{ style: { fontSize: 15 }, shrink: true }}
        />
      </Box>
      {!particular && (
        <Box
          sx={{
            padding: "2px 15px 5px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                size={"small"}
                sx={{
                  "&.Mui-checked": {
                    color: estilos.backgroundColorPrimary,
                  },
                }}
                checked={checked}
              />
            }
            onChange={() =>
              particular
                ? handleOnChange("imprimir-particular")
                : handleOnChange("imprimir")
            }
            label={
              <Typography
                sx={{ fontSize: "0.82rem", color: estilos.colorTextSecondary }}
              >
                No imprimir diagnóstico. Sólo imprimir código.
              </Typography>
            }
          />
        </Box>
      )}
    </div>
  );
};

export default DiagnosticoGeneral;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    margin: "20px 0px",
  },
  resizeFont: {
    fontSize: 15,
  },
}));



const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& label.Mui-focused": {
    color: color,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: color,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: color,
    },
    "&.Mui-focused fieldset": {
      borderColor: color,
    },
    fontSize: "15px",
  },
}));