import "./users.scss";
import React, { useContext, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { UserNewToolbar } from "../../components/users/user-new-toolbar";
import { useNavigate } from "react-router-dom";
import { UserNewForm } from "../../components/users/user-new-form";
import createUser from "../../context/actions/users/createUser";
import clearCreateUser from "../../context/actions/users/clearCreateUser";
import { GlobalContext } from "../../context/Provider";

const NewUser = () => {
  const navigate = useNavigate();
  
  const {
    usersDispatch,
    usersState: {
      addUser: { loading, error, data },
    },
  } = useContext(GlobalContext);


   useEffect(() => {
     if (data) {
       navigate("/users");
     }
     return () => {
       clearCreateUser()(usersDispatch);
     };
   }, [data]);

  const onSubmit = (values) => {
    createUser(values)(usersDispatch);
  };

  const executeAction = (action) => {
    if (action === "back") {
      navigate("/users");
    }
  };

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth={false}>
          <UserNewToolbar onClick={executeAction} />
          <Box sx={{ mt: 4 }}>
            <UserNewForm
              onSubmit={onSubmit}
              error={error}
              loading={loading}
            />
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default NewUser;
