import React from "react";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../theme";
import ButtonClose from "./button-close";
import { Stack, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const CustomDialog = (props) => {
  const classes = useStyles(theme);
  const { onClose, open, children, customStyle, title, estilos } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={useMediaQuery(theme.breakpoints.down("md")) ? "960px" : "none"}
      fullScreen={useMediaQuery(theme.breakpoints.down("md")) ? true : false}
    >
      <div style={customStyle} className={classes.container}>
        <Stack spacing={4}>
          <div className={classes.buttonSection}>
            <Typography
              sx={{
                fontSize: "0.98rem",
                color: estilos
                  ? estilos.backgroundColorPrimary
                  : "primary.dark",
                fontWeight: "500",
              }}
            >
              {title}
            </Typography>
            <ButtonClose onClick={onClose} estilos={estilos} />
          </div>
          {children}
        </Stack>
      </div>
    </Dialog>
  );
};

export default CustomDialog;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 32px 64px",
    [theme.breakpoints.up("sm")]: {
      width: "960px"
    },
    backgroundColor: "white",
  },
  buttonSection: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderBottom: "1px solid rgba(0,0,0,0.18)",
    paddingBottom: "5px",
  },
}));
