import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip
} from "@mui/material";
import { SeverityPill } from "../severity-pill";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CircularProgress from "@mui/material/CircularProgress";

export const UsersListResults = ({ users, onClick,loading, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      sx={{
        border: "0.5px solid #dddddd",
        padding: "1px",
        boxShadow: "1px 1px #d5d5d5",
      }}
      {...rest}
    >
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Acciones
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                users.length > 0 &&
                users.slice(0, limit).map((user) => (
                  <TableRow hover key={user.id}>
                    <TableCell>{user.nombre}</TableCell>
                    <TableCell>{user.apellido}</TableCell>
                    <TableCell>
                      <SeverityPill color={user.activo ? "success" : "error"}>
                        {user.activo ? "Activo" : "Inactivo"}
                      </SeverityPill>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {1 === 1 ? (
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Editar" placement="left">
                            <IconButton
                              sx={{
                                marginRight: "2px",
                              }}
                              onClick={() => onClick("editar", user)}
                            >
                              <EditOutlinedIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          {user.activo ? (
                            <Tooltip title="Deshabilitar" placement="right">
                              <IconButton
                                sx={{
                                  marginLeft: "4px",
                                }}
                                onClick={() => onClick("desactivar", user)}
                              >
                                <CancelOutlinedIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Habilitar" placement="right">
                              <IconButton
                                sx={{
                                  marginLeft: "4px",
                                }}
                                onClick={() => onClick("activar", user)}
                              >
                                <CheckCircleOutlineOutlinedIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.84rem",
                              lineHeight: "1.5rem",
                              fontWeight: "600",
                              color: "primary.dark",
                            }}
                          >
                            {" "}
                            -{" "}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          alignItems: "center",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!!loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Box>
      <TablePagination
        component="div"
        count={users.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

UsersListResults.propTypes = {
  users: PropTypes.array.isRequired,
};
