import React from "react";
import { Typography, Box, Button, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";

const PanelDeleteMedicamento = (props) => {
  const { onClick, medicamento, estilos, borrado, onClose } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.content}>
      <Stack spacing={2} sx={{ alignItems: "center", paddingBottom: "32px" }}>
        <Typography
          sx={{
            fontSize: "1.05rem",
            color: estilos.colorTextSecondary,
          }}
        >
          ¿Confirma que desea eliminar el producto seleccionado?
        </Typography>
        <Stack spacing={1} sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "0.98rem",
              color: estilos.colorTextSecondary,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {medicamento.nombreProducto} -{medicamento.nombreDroga} -{" "}
            {medicamento.presentacion} - x {medicamento.cantidad}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          padding: "15px 0px 0px",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          borderTop: "1px solid rgba(0, 0, 0, 0.18)",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
            justifyItems: "center",
            paddingBottom: "10px",
          }}
        >
          <Button
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              backgroundColor: estilos.backgroundColorPrimary,
              "&:hover": {
                backgroundColor: estilos.backgroundColorPrimary,
                opacity: 0.8,
              },
            }}
            variant="contained"
            disabled={borrado}
            onClick={() => onClick("confirmar-delete", medicamento.id)}
          >
            CONFIRMAR
          </Button>
          <Button
            variant="outlined"
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              color: estilos.backgroundColorPrimary,
              "&.MuiButton-outlinedPrimary": {
                border: "1px solid",
                borderColor: estilos.backgroundColorPrimary,
              },
            }}
            onClick={() => onClose(false)}
          >
            CANCELAR
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default PanelDeleteMedicamento;

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "white",
  },
}));
