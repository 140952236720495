import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import Avatar from "@mui/material/Avatar";
import ListItemText from "./list-item-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { CheckCircle } from "@mui/icons-material";

const PanelPaciente = (props) => {
  const { paciente, estilos, showRecord, titleGoBack } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.card}>
      <div
        className={classes.header}
        style={{ backgroundColor: estilos.backgroundColorAccent }}
      >
        <div className={classes.sectionName}>
          <Avatar variant="rounded" sx={{ height: 64, width: 64 }}>
            <FontAwesomeIcon icon={faIdCard} size="xl" />
          </Avatar>
          <div className={classes.sectionText}>
            <Typography
              sx={{
                fontSize: "13.5px",
                lineHeight: "25px",
                color: estilos.colorTextPrimary,
              }}
            >
              Paciente
            </Typography>
            {paciente.nombrePaciente && paciente.apellidoPaciente && (
              <Typography
                sx={{
                  fontSize: "17.6px",
                  lineHeight: "25px",
                  color: estilos.colorTextSecondary,
                }}
              >
                {paciente.nombrePaciente} {paciente.apellidoPaciente}
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div className={classes.body}>
        <ListItemText
          color={estilos.colorTextSecondary}
          label={"Obra social / Prepaga / Programa"}
          data={paciente.financiadorNombre}
        />
        <ListItemText
          color={estilos.colorTextSecondary}
          label={"Número de afiliado"}
          data={paciente.numeroAfiliado}
        />
        <Typography
          sx={{
            fontSize: "14.5px",
            lineHeight: "22px",
            fontWeight: "500",
            color: estilos.colorTextSecondary,
            //alinear texto verticalmente
            display: "flex",
            alignItems: "center",
          }}
        >
          Verificación:{" "}
          <CheckCircle sx={{ color: "#2fc421", marginLeft: "0.4rem" }} />
        </Typography>
        {paciente.plan && (
          <ListItemText
            color={estilos.colorTextSecondary}
            label={"Plan"}
            data={paciente.plan}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={5}>
            {paciente.tipoDocumento && paciente.nroDocumento && (
              <ListItemText
                color={estilos.colorTextSecondary}
                label={paciente.tipoDocumento}
                data={paciente.nroDocumento}
              />
            )}
          </Grid>
          <Grid item xs={7}>
            <Button
              variant="outlined"
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "17px",
                  md: "10px",
                  lg: "12px",
                  xl: "15px",
                },
                backgroundColor: "#e5e7eb",
                borderRadius: "10px",
                border: "none",
                width: "100%",
                fontWeight: "500",
                color: "#687c88",
                height: "45px",
                padding: "0",
                textAlign: "center",
                paddingX: "2px",
              }}
              onClick={showRecord}
            >
              {titleGoBack && titleGoBack.length > 0
                ? titleGoBack
                : "HISTORIAL DE RECETAS"}
            </Button>
          </Grid>
        </Grid>
        {/*  <ListItemText
          color={estilos.colorTextSecondary}
          label={"País"}
          data={paciente.pais}
        /> */}
      </div>
    </div>
  );
};

export default PanelPaciente;

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    borderRadius: 7,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    margin: "5px",
    border: "1px solid rgba(0, 0, 0, 0.14)",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
  },
  header: {
    height: 100,
    padding: 15,
  },
  sectionName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    gap: "16",
  },
  sectionText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    paddingLeft: "16px",
  },
  body: {
    display: "flex",
    padding: 15,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
  },
}));
