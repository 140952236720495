import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {theme} from "../theme";
import CloseIcon from "@mui/icons-material/Close";

const ButtonClose = (props) => {
  const { onClick,estilos } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.btnClose} onClick={onClick}>
      <CloseIcon
        sx={{
          width: "24px",
          height: "24px",
          fill: estilos ? estilos.backgroundColorPrimary : theme.palette.primary.accent,
        }}
      />
    </div>
  );
};

export default ButtonClose;

const useStyles = makeStyles((theme) => ({
  btnClose: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px",
    width: "40px",
    height: "40px",
  },
}));
