import "./client.scss";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box, Container, Grid } from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { useNavigate, useParams } from "react-router-dom";
import { ClientToolbar } from "../../components/clients/client-toolbar";
import { ClientInformation } from "../../components/clients/client-information";
import { ClientApiKey } from "../../components/clients/client-api-key";
import { ClientCredenciales } from "../../components/clients/client-credenciales";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DialogComponent } from "../../components/dialog-component";
import { GlobalContext } from "../../context/Provider";
import getAplications from "../../context/actions/aplications/getAplications";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "./Store";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

const ClientDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [client, setClient] = useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [titleDialog, setTitleDialog] = React.useState("");
  const [bodyDialog, setBodyDialog] = React.useState("");
  const { aplicationsDispatch, aplicationsState } = useContext(GlobalContext);
  const [store] = useState(new Store(params.clientId));

  const {
    aplications: { data, loading },
  } = aplicationsState;

  useEffect(() => {
    if (data.length === 0) {
      getAplications()(aplicationsDispatch);
      setClient(data.filter((u) => u.id === params.clientId)[0]);
    } else {
      setClient(data.filter((u) => u.id === params.clientId)[0]);
    }
  }, [data, params.clientId]);


  const executeAction = (action, data) => {
    if (action === "back") {
      navigate("/home");
    } else if (action === "copy-access-key") {
      copyClipboard(data);
    } else if (action === "copy-app-user") {
      copyClipboard(data);
    } else if (action === "desactivar") {
      setTitleDialog("Deshabilitar cliente app");
      setBodyDialog(
        <p>
          ¿Confirma que desea deshabilitar el cliente de app{" "}
          <span style={{ fontWeight: "600" }}>{data.appName}</span>?
        </p>
      );
      setOpen(true);
    } else if (action === "activar") {
      setTitleDialog("Habilitar cliente app");
      setBodyDialog(
        <p>
          ¿Confirma que desea habilitar el cliente de app{" "}
          <span style={{ fontWeight: "600" }}>{data.appName}</span>?
        </p>
      );
      setOpen(true);
    } else if (action === "configuracion") {
      navigate(`/configuration/${params.clientId}`);
    }
  };

  async function copyClipboard(data) {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(data);
      setOpenAlert(true);
    } else {
      setOpenAlert(true);
      document.execCommand("copy", true, data);
    }
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth={false}>
          <ClientToolbar onClick={executeAction} />
          <Box sx={{ mt: 4 }}>
            {client && !store.loadingCredenciales && (
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    open={openAlert}
                    autoHideDuration={3000}
                    onClose={handleCloseAlert}
                  >
                    <Alert
                      onClose={handleCloseAlert}
                      severity="info"
                      sx={{ width: "100%" }}
                    >
                      Valor copiado en el clipboard!
                    </Alert>
                  </Snackbar>
                  <DialogComponent
                    open={open}
                    handleClose={handleClose}
                    onClick={executeAction}
                    title={titleDialog}
                    description={bodyDialog}
                  />
                  <ClientInformation client={client} onClick={executeAction} />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <ClientApiKey
                    apiKey={client.accessKey}
                    appUser={client.appUser}
                    onClick={executeAction}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <ClientCredenciales
                    loading={store.loadingUpdate}
                    credenciales={store.credenciales}
                    onClick={executeAction}
                    onSubmit={store.onSubmit}
                    handleOnChange={store.handleOnChange}
                    status={store.userActivo}
                    mensaje={store.mensaje}
                    error={store.error}
                  />
                </Grid>
              </Grid>
            )}

            {!!store.loadingCredenciales && <CircularProgress />}
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default observer(ClientDetail);
