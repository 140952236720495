import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import { Typography, Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const TratamientoProlongado = (props) => {
  const { estilos, checked, handleOnChange } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      <Typography
        sx={{
          m: 1,
          fontSize: "0.91rem",
          color: estilos.colorTextSecondary,
          fontWeight: "500",
        }}
      >
        Tratamiento Prolongado
      </Typography>
     <Box
          sx={{
            padding: "2px 15px 5px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                size={"small"}
                sx={{
                  "&.Mui-checked": {
                    color: estilos.backgroundColorPrimary,
                  },
                }}
                checked={checked}
              />
            }
            onChange={() => handleOnChange("tratamiento")
            }
            label={
              <Typography
                sx={{ fontSize: "0.82rem", color: estilos.colorTextSecondary }}
              >
                El paciente requiere tratamiento prolongado
              </Typography>
            }
          />
        </Box>
    </div>
  );
};

export default TratamientoProlongado;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginBottom: 5,
  },
  resizeFont: {
    fontSize: 15,
  },
}));
