import React from "react";
import { Typography, Stack, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import Avatar from "@mui/material/Avatar";
import MedicationIcon from "@mui/icons-material/Medication";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const CardMedicamento = (props) => {
  const { medicamento, estilos, onClick } = props;
  const classes = useStyles(theme);

  const medDescription = JSON.parse(medicamento?.diagnostico)?.descDiagnostico ?? ''

  return (
    <div
      className={classes.card}
      style={{ backgroundColor: estilos.backgroundColorAccent }}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid
            item
            xs={2}
            md={1}
            sx={{
              padding: "18px 25px",
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
          >
            <Avatar
              sx={{
                height: 56,
                width: 56,
                backgroundColor: estilos.backgroundColorPrimary,
              }}
            >
              <MedicationIcon
                sx={{
                  height: 36,
                  width: 36,
                  color: theme.palette.background.default,
                }}
              />
            </Avatar>
          </Grid>
          <Grid item xs={12} md={10}>
            <Grid
              container
              sx={{
                padding: "16px 12px 12px 12px",
                width: "100%",
                [theme.breakpoints.down("sm")]: { display: "none" },
              }}
            >
              <Grid item xs={4} md={4}>
                <Typography
                  sx={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Marca del medicamento
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14.2px",
                    lineHeight: "25px",
                    fontWeight: "500",
                    color: estilos.colorTextSecondary,
                  }}
                >
                  {medicamento.nombreProducto}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  sx={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Presentación
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14.2px",
                    lineHeight: "25px",
                    fontWeight: "500",
                    color: estilos.colorTextSecondary,
                  }}
                >
                  {medicamento.presentacion}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  sx={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Droga
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14.2px",
                    lineHeight: "25px",
                    fontWeight: "500",
                    color: estilos.colorTextSecondary,
                  }}
                >
                  {medicamento.nombreDroga}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography
                  sx={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Cantidad
                </Typography>
                <Typography
                  px={3}
                  sx={{
                    fontSize: "14.2px",
                    lineHeight: "25px",
                    fontWeight: "500",
                    color: estilos.colorTextSecondary,
                  }}
                >
                  {medicamento.cantidad}
                </Typography>
              </Grid>
            </Grid>
            <Stack
              flexDirection={"column"}
              sx={{
                [theme.breakpoints.up("sm")]: { display: "none" },
                padding: "16px 12px 12px 12px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14.2px",
                  lineHeight: "25px",
                  fontWeight: "500",
                  color: estilos.colorTextSecondary,
                }}
              >
                <span
                  style={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Marca :
                </span>{" "}
                {medicamento.nombreProducto}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14.2px",
                  lineHeight: "25px",
                  fontWeight: "500",
                  color: estilos.colorTextSecondary,
                }}
              >
                <span
                  style={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Presentación :
                </span>{" "}
                {medicamento.presentacion}
              </Typography>

              <Typography
                sx={{
                  fontSize: "14.2px",
                  lineHeight: "25px",
                  fontWeight: "500",
                  color: estilos.colorTextSecondary,
                }}
              >
                <span
                  style={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Droga :
                </span>{" "}
                {medicamento.nombreDroga}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14.2px",
                  lineHeight: "25px",
                  fontWeight: "500",
                  color: estilos.colorTextSecondary,
                }}
              >
                <span
                  style={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  Cantidad :
                </span>{" "}
                {medicamento.cantidad}
              </Typography>
            </Stack>

            {medicamento.diagnostico && medicamento.diagnostico !== 0 && (
              <Grid
                container
                sx={{
                  borderTop: "1px solid rgba(0, 0, 0, 0.38)",
                }}
              >
                <div className={classes.diagnostico}>
                  <Typography
                    sx={{
                      fontSize: "13.1px",
                      lineHeight: "25px",
                      color: estilos.colorTextAccent,
                    }}
                  >
                    Diagnóstico:{"  "}
                    <span
                      style={{
                        color: estilos.colorTextSecondary,
                        fontWeight: "500",
                      }}
                    >
                      {medDescription}{" "}
                      {JSON.parse(medicamento?.diagnostico)?.idDiagnostico !==
                      999999
                        ? "(" +
                        JSON.parse(medicamento?.diagnostico)?.codDiagnostico +
                          ")"
                        : ""}
                    </span>
                  </Typography>
                </div>
              </Grid>
            )}
            {medicamento.posologia && (
              <Grid container>
                <div className={classes.variables}>
                  <Typography
                    sx={{
                      fontSize: "13.1px",
                      lineHeight: "25px",
                      color: estilos.colorTextAccent,
                    }}
                  >
                    Posología:{"  "}
                    <span
                      style={{
                        color: estilos.colorTextSecondary,
                        fontWeight: "500",
                      }}
                    >
                      {medicamento.posologia}
                    </span>
                  </Typography>
                </div>
              </Grid>
            )}
            <Grid container>
              <div className={classes.variables}>
                <Typography
                  sx={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  {medicamento.permiteSustitucion &&
                    JSON.parse(medicamento.permiteSustitucion).o &&
                    "No Sustituir"}
                  {medicamento.permiteSustitucion &&
                    JSON.parse(medicamento.permiteSustitucion).g &&
                    "Permite sustitución"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13.1px",
                    lineHeight: "25px",
                    color: estilos.colorTextAccent,
                  }}
                >
                  {medicamento.tratamiento === 1
                    ? "Tratamiento prolongado"
                    : ""}
                </Typography>
              </div>
            </Grid>
            <Stack
              sx={{
                flexDirection: "row",
                display: "flex",
                background: "white",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
                [theme.breakpoints.up("sm")]: { display: 'none' },
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  aria-label="back"
                  onClick={() => onClick("edit", medicamento)}
                >
                  <EditIcon sx={{ color: estilos.backgroundColorPrimary }} />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  aria-label="back"
                  onClick={() => onClick("delete", medicamento)}
                >
                  <DeleteIcon sx={{ color: estilos.backgroundColorPrimary }} />
                </IconButton>
              </div>
            </Stack>
          </Grid>
          <Grid
            item
            xs={2}
            md={1}
            sx={{
              backgroundColor: "#fff",
              padding: "18px 32px",
              [theme.breakpoints.down("sm")]: {
                padding: "18px 0px",
                display: "none",
              },
              borderRadius: "0px 16px 16px 0px",
            }}
          >
            <div className={classes.opciones}>
              <Stack spacing={2}>
                <IconButton
                  aria-label="back"
                  onClick={() => onClick("edit", medicamento)}
                >
                  <EditIcon sx={{ color: estilos.backgroundColorPrimary }} />
                </IconButton>
                <IconButton
                  aria-label="back"
                  onClick={() => onClick("delete", medicamento)}
                >
                  <DeleteIcon sx={{ color: estilos.backgroundColorPrimary }} />
                </IconButton>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CardMedicamento;

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "16px",
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    margin: "5px",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
  },
  diagnostico: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 24px 0px 0px",
    gap: "16px",
  },
  posologia: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "5px 24px 5px 0px",
    gap: "16px",
  },
  variables: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "5px 24px 15px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 12px 12px 12px",
      width: "100%",
    },
    gap: "16px",
  },
  opciones: {
    backgroundColor: "white",
  },

  headerMedicamento: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "32px 32px 12px 0px",
    gap: "16px",
  },
  footerMedicamento: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px 24px 32px 0px",
    gap: "16px",
    borderTop: "1px solid rgba(0, 0, 0, 0.38)",
  },
  dataMedicamento: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",
  },
  marca: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "4px 0px",
  },
  presentacion: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "4px 0px",
  },
  droga: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "4px 0px",
  },
  cantidad: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "4px 0px",
  },
  leyenda: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "4px 0px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16",
  },
  sectionIcon: {},
  sectionInformacion: {},
  sectionBotones: {},
  header: {
    backgroundColor: "#E3E3E3",
    height: 100,
    padding: 15,
  },
  sectionText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    paddingLeft: "16px",
  },
  body: {
    display: "flex",
    padding: 15,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
  },
}));
