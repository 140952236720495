import "./users.scss";
import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { UserEditToolbar } from "../../components/users/user-edit-toolbar";
import { useNavigate, useParams } from "react-router-dom";
import { UserEditFormData } from "../../components/users/user-edit-form-data";
import { UserEditFormChangePassword } from "../../components/users/user-edit-form-change-password";
import { GlobalContext } from "../../context/Provider";
import updateUser from "../../context/actions/users/updateUser";

const EditUser = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [values, setValues] = useState(null);
  const { usersDispatch, usersState } = useContext(GlobalContext);

  const {
    users: { data, loading, error },
  } = usersState;

  useEffect(() => {
    setValues(
      data
        .filter((u) => u.id === params.userId)
        .map((u) => ({
          nombre: u.nombre,
          apellido: u.apellido,
          rol: u.rol.id,
          email: u.email,
          activo: u.activo,
        }))[0]
    );
  }, [data]);

  const onSubmit = (values) => {
    const payload = {};
    payload.id = params.userId;
    payload.nombre = values.nombre;
    payload.apellido = values.apellido;
    payload.email = values.email;
    payload.rol = values.rol === "1" ? "Admin" : "Operador";
    payload.activo = values.activo;

    updateUser(payload)(usersDispatch);
  };

  const onSubmitChangePassword = (values) => {
    console.log("Cambiar contraseña");
  };

  const executeAction = (action) => {
    if (action === "back") {
      navigate("/users");
    }
  };

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth={false}>
          <UserEditToolbar onClick={executeAction} />
          <Box sx={{ mt: 4 }}>
            {values && (
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                  <UserEditFormData
                    loading={loading}
                    error={error}
                    initialValues={values}
                    onSubmit={onSubmit}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <UserEditFormChangePassword
                    initialValues={{ password: "", repetirPassword: "" }}
                    onSubmit={onSubmitChangePassword}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
      </Box>
    </PageLayout>
  );
};

export default EditUser;
