import { action, observable, makeAutoObservable, runInAction } from "mobx";
import ConfigurationService from "../../services/ConfigurationService";

class Store {
  clientId = null;
  credenciales = { user: "", pass: "", prefijo: "" };
  userActivo = false;
  loadingCredenciales = false;
  loadingUpdate = false;
  mensaje = null;
  error = null;

  constructor(clientId) {
    this.clienteId = clientId;
    makeAutoObservable(this, {
      credenciales: observable,
      userActivo: observable,
      loadingCredenciales: observable,
      mensaje: observable,
      error: observable,
      onSubmit: action,
      handleOnChange: action,
    });
    this.getCredenciales();
  }

  getCredenciales = async () => {
    try {
      runInAction(() => {
        this.loadingCredenciales = true;
      });
      let response = await ConfigurationService.getCredencialesConfig(
        this.clienteId
      );

      if (response.status === "Ok") {
        runInAction(() => {
          this.credenciales = response.result;
          this.userActivo = response.result.status;
          this.loadingCredenciales = false;
        });
      } else {
        this.loadingCredenciales = false;
      }
    } catch (e) {
      runInAction(() => {
        this.loadingCredenciales = false;
      });
    }
  };

  onSubmit = async (values) => {
    let aux = values;
    aux.status = this.userActivo;
    aux.aplicacionId = this.clienteId;
    runInAction(() => {
      this.error = null;
      this.mensaje = null;
      this.loadingUpdate = true;
    });

    try {
      let response = await ConfigurationService.createOrUpdateCredenciales(aux);

      if (response.status === "Ok") {
        runInAction(() => {
          this.mensaje = "Credenciales actualizadas exitosamente.";
          this.loadingUpdate = false;
        });
      } else {
        runInAction(() => {
          this.error =
            "Error al actualizar las credenciales. Inténtelo más tarde.";
          this.loadingUpdate = false;
        });
      }
    } catch (e) {
      runInAction(() => {
        this.error =
          "Error al actualizar las credenciales. Inténtelo más tarde.";
        this.loadingUpdate = false;
      });
    }
  };

  handleOnChange = (value) => {
    runInAction(() => {
      this.userActivo = value;
    });
  };
}

export default Store;
