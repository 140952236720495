/* eslint-disable react-hooks/exhaustive-deps */
import "./configuration.scss";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Container,
  Card,
  Grid,
  Typography,
  CardContent,
  CardHeader,
  Divider,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@mui/material";
import ConfigurationStore from "./Store";
import { PageLayout } from "../../components/page/page-layout";
import { ConfigurationDetailToolbar } from "../../components/configuration/configuration-detail-toolbar";
import { useNavigate, useParams } from "react-router-dom";
import TabEstilos from "../../components/configuration/tab-estilos";
import TabFinanciadores from "../../components/configuration/tab-financiadores";
import getAplications from "../../context/actions/aplications/getAplications";
import { GlobalContext } from "../../context/Provider";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Configuration = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { aplicationsDispatch, aplicationsState } = useContext(GlobalContext);
  
  const {
    aplications: { data, loading },
  } = aplicationsState;

  const [store] = useState(new ConfigurationStore(params.appId, navigate));

  useEffect(() => {
    const getData = async () => {
      if (data.length === 0) {
        await getAplications()(aplicationsDispatch);
      }
    };
    getData();
    store.getConfiguration(data.filter((u) => u.id === params.appId)[0]);
    
  }, [data, params.appId]);

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ my: 1 }}>
            <ConfigurationDetailToolbar onClick={store.goToPage} />
          </Box>

          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={store.openCamposObligatorios}
            onClose={store.handleCloseCamposObligatorios}
          >
            <DialogTitle sx={{ fontSize: "14.5px" }}>
              Campos obligatorios
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ fontSize: "13.4px" }}>
                Selecciona los campos obligatorios que quiere setear para la
                entidad médico y paciente.
              </DialogContentText>

              <Grid
                container
                spacing={4}
                px={1}
                py={4}
                sx={{ justifyContent: "space-between" }}
              >
                <Grid item md={5} xs={12}>
                  <Typography sx={{ fontSize: "0.90rem", fontWeight: "500" }}>
                    Campos del médico
                  </Typography>
                  <Divider sx={{ py: 0.5, mb: 2 }} />
                  <Grid container>
                    {store.dataMaestrosMedico &&
                      store.dataMaestrosMedico.length > 0 &&
                      store.dataMaestrosMedico.map((t, i) => {
                        return (
                          <Grid key={i} item md={4} xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={t.select}
                                  disabled={t.disabled}
                                />
                              }
                              onChange={() =>
                                store.handleChangeFields("Medico", t.campo)
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "0.85rem",
                                    color:
                                      store.estilosFormulario
                                        .colorTextSecondary,
                                  }}
                                >
                                  {t.descripcion}
                                </Typography>
                              }
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Typography sx={{ fontSize: "0.90rem", fontWeight: "500" }}>
                    Campos del paciente
                  </Typography>
                  <Divider sx={{ py: 0.5, mb: 2 }} />
                  <Grid container>
                    {store.dataMaestrosPaciente &&
                      store.dataMaestrosPaciente.length > 0 &&
                      store.dataMaestrosPaciente.map((t, i) => {
                        return (
                          <Grid key={i} item md={4} xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={t.select}
                                  disabled={t.disabled}
                                />
                              }
                              onChange={() =>
                                store.handleChangeFields("Paciente", t.campo)
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "0.85rem",
                                    color:
                                      store.estilosFormulario
                                        .colorTextSecondary,
                                  }}
                                >
                                  {t.descripcion}
                                </Typography>
                              }
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={store.handleCloseCamposObligatorios}>
                Cancelar
              </Button>
              <Button onClick={store.saveCamposObligatorios}>Aceptar</Button>
            </DialogActions>
          </Dialog>
          {store.application && (
            <Card
              sx={{
                border: "0.5px solid #dddddd",
                padding: "1px",
                boxShadow: "1px 1px #dddddd",
              }}
            >
              <CardHeader
                sx={{
                  padding: "20px",
                }}
                subheader={
                  "Personaliza el formulario en la siguiente configuración"
                }
                title={store.application.appName}
              />
              <Divider style={{ background: "#dddddd", height: "1.5px" }} />
              <CardContent
                sx={{
                  padding: "8px",
                }}
              >
                {store.dataFormulario &&
                  store.estilosFormulario &&
                  store.dataDictionary !== undefined &&
                  store.financiadores &&
                  store.secciones && (
                    <Box>
                      <TabEstilos
                        estilos={store.estilosFormulario}
                        coloresEstandar={store.coloresEstandar}
                        handleOnChange={store.handleOnChangeStyles}
                        handleOnChangeLogo={store.handleOnChangeLogo}
                        handleSetSameLogo={store.handleSetSameLogo}
                        handleOpenCamposObligatorios={
                          store.handleOpenCamposObligatorios
                        }
                        diccionario={store.dataDictionary}
                        sectionConfiguration={store.secciones}
                        handleChangeSection={store.handleChangeSection}
                        logoReceta={store.logoReceta}
                        onChangeImage={store.onChangeImage}
                        onDeleteImage={store.onDeleteImage}
                        loadingUploadImage={store.loadingUploadImage}
                      />
                      <Divider
                        style={{ background: "#dddddd", height: "1.5px" }}
                      />
                      <TabFinanciadores
                        financiadores={store.financiadores}
                        selectAll={store.vinculateAllFinanciers}
                        onChange={store.onChangeFinanciadores}
                        addFinanciadores={store.addFinanciadores}
                        financiadoresSelected={store.selectedFinanciers}
                        dataForm={store.dataFormulario}
                        handleOnChange={store.handleOnChange}
                        validacionFinanciador={store.validacionFinanciador}
                        validacionCantidades={store.validacionCantidades}
                        validacionDiagnostico={store.validacionDiagnostico}
                      />
                    </Box>
                  )}

                {(!!loading ||
                  !!store.loadingConfiguration ||
                  !!store.loadingDictionary ||
                  !!store.loadingFinanciadores) && (
                  <Box
                    sx={{
                      alignItems: "center",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <CircularProgress />
                    </div>
                  </Box>
                )}
              </CardContent>
              <Divider style={{ background: "#dddddd", height: "2px" }} />
              {store.errorUpdate && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 1,
                  }}
                >
                  <Alert fullWidth severity="error">
                    {store.errorUpdate}
                  </Alert>
                </Box>
              )}
              {store.mensajeExitoso && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 1,
                  }}
                >
                  <Alert fullWidth severity="success">
                    {store.mensajeExitoso}
                  </Alert>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 3,
                }}
              >
                <LoadingButton
                  loading={store.loadingUpdate}
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={store.guardarConfiguration}
                >
                  Guardar configuración
                </LoadingButton>
              </Box>
            </Card>
          )}
        </Container>
      </Box>
    </PageLayout>
  );
};

export default observer(Configuration);