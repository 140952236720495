import { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import { UsuarioDataSchema } from "../../helpers/validation";
import LoadingButton from "@mui/lab/LoadingButton";

const roles = [
  {
    value: "1",
    label: "Administrador",
  },
  {
    value: "2",
    label: "Operador",
  },
];

export const UserEditFormData = (props) => {
  const { onSubmit, initialValues, loading, error } = props;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: UsuarioDataSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          border: "0.5px solid #dddddd",
          padding: "1px",
          boxShadow: "1px 1px #d5d5d5",
        }}
      >
        <CardHeader
          subheader="Puede editar los datos del usuario"
          title="Usuario"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.nombre && formik.errors.nombre)}
                fullWidth
                helperText={formik.touched.nombre && formik.errors.nombre}
                label="Nombre"
                name="nombre"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.nombre}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.apellido && formik.errors.apellido
                )}
                fullWidth
                helperText={formik.touched.apellido && formik.errors.apellido}
                label="Apellido"
                name="apellido"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.apellido}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                type="email"
                disabled
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.rol && formik.errors.rol)}
                fullWidth
                helperText={formik.touched.rol && formik.errors.rol}
                label="Rol"
                name="rol"
                select
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.rol}
                variant="outlined"
              >
                {roles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          {error && (
            <Typography
              align="center"
              sx={{
                fontSize: "0.84rem",
                lineHeight: "1.5rem",
                fontWeight: "300",
                color: "red",
              }}
            >
              {error}
            </Typography>
          )}
          <LoadingButton
            loading={loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Guardar cambios
          </LoadingButton>
        </Box>
      </Card>
    </form>
  );
};
