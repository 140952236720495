import { Box, Button, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export const AppsListToolbar = (props) => {
  const { onClick } = props;
  return (
    <Box {...props}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
        }}
      >
        <Typography
          sx={{ m: 1, fontSize: "1.45rem", color: "primary.dark" }}
          variant="h4"
        >
          Clientes de aplicación
        </Typography>
        <Box sx={{ m: 1 }}>
          <Button
            startIcon={<PersonAddIcon fontSize="small" />}
            sx={{ mr: 1, fontSize: "0.75rem", fontWeight: "400" }}
            color="primary"
            variant="contained"
            onClick={() => onClick("agregar")}
          >
            Agregar cliente app
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
