import "./clients.scss";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  TextField,
  Box,
  Button,
  Grid
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

export const ClientApiKey = (props) => {
  const { apiKey, appUser, onClick } = props;

  return (
    <Card
      sx={{
        border: "0.5px solid #dddddd",
        padding: "1px",
        boxShadow: "1px 1px #d5d5d5",
      }}
    >
      <CardHeader
        subheader="Access Key y App User"
        title="Datos de integración"
        sx={{ padding: 2 }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3} mb={1}>
          <Grid item md={8} xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: "0.84rem",
                  lineHeight: "1.5rem",
                  fontWeight: "600",
                  color: "primary.dark",
                }}
              >
                APP USER
              </Typography>
              <TextField
                id="app-user-text"
                defaultValue={appUser ? appUser : ""}
                fullWidth
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12} alignSelf={"end"} py={2}>
            <Button
              variant="outlined"
              onClick={() => onClick("copy-app-user", appUser)}
              sx={{ fontSize: "0.86rem", lineHeight: "1.1", fontWeight: "500" }}
              startIcon={<ContentCopyOutlinedIcon color="primary" />}
            >
              Copiar
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item md={8} xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: "0.84rem",
                  lineHeight: "1.5rem",
                  fontWeight: "600",
                  color: "primary.dark",
                }}
              >
                ACCESS KEY
              </Typography>
              <TextField
                id="api-key-text"
                defaultValue={apiKey}
                fullWidth
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12} alignSelf={"end"} py={2}>
            <Button
              variant="outlined"
              onClick={() => onClick("copy-access-key", apiKey)}
              sx={{ fontSize: "0.86rem", lineHeight: "1.1", fontWeight: "500" }}
              startIcon={<ContentCopyOutlinedIcon color="primary" />}
            >
              Copiar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
