import React from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  TextField,
  Stack,
  Button,
  Tooltip
} from "@mui/material";
import ColorPicker from "./color-picker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ImageIcon from "@mui/icons-material/Image";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import CircularProgress from "@mui/material/CircularProgress";


const TabEstilos = (props) => {
  const classes = useStyles(theme);
  const {
    estilos,
    handleOnChange,
    coloresEstandar,
    handleOnChangeLogo,
    handleSetSameLogo,
    diccionario,
    handleOpenCamposObligatorios,
    handleChangeSection,
    sectionConfiguration,
    onChangeImage,
    onDeleteImage,
    logoReceta,
    loadingUploadImage
  } = props;
  const [displayColorPicker, setDisplayColorPicker] = React.useState(null);
  const [expandedPanelEstilos, setExpandedPanelEstilos] = React.useState(true);
  const [expandedPanelLogoReceta, setExpandedPanelLogoReceta] = React.useState(true);
  const [expandedPanelCampos, setExpandedPanelCampos] = React.useState(true);
  const [expandedPanelVisualizacion, setExpandedPanelVisualizacion] = React.useState(true);

  const handleClick = (name) => {
    setDisplayColorPicker(name);
  };

  const handleClose = () => {
    setDisplayColorPicker(null);
  };

  return (
    <Box sx={{ mx: 2, mt: 2, mb: 8 }}>
      <Accordion
        expanded={expandedPanelLogoReceta}
        onChange={() => setExpandedPanelLogoReceta(!expandedPanelLogoReceta)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: "0.95rem", fontWeight: "500" }}>
            Configuración de la receta
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={4}
            px={1}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item md={12} xs={12}>
              <Typography sx={{ fontSize: "0.95rem" }}>
                Logo que se visualizará en el pdf de la receta
              </Typography>

              {!logoReceta && !loadingUploadImage && (
                <Grid item md={4} xs={4}>
                  <div style={{ margin: "10px 0px" }}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={onChangeImage}
                    />
                    <label
                      htmlFor="contained-button-file"
                      className={classes.containerBtn}
                    >
                      <div className={classes.btnFab}>
                        <AddPhotoAlternateIcon color="primary" />
                      </div>
                      <p className={classes.textBtn}>Subir logo</p>
                    </label>
                  </div>
                </Grid>
              )}

              {logoReceta && !loadingUploadImage && (
                <div className={classes.containerImage}>
                  <img alt="logo-receta" src={logoReceta} height="80" />
                  <div>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="edit-button-file"
                      multiple
                      type="file"
                      onChange={onChangeImage}
                    />
                    <label
                      htmlFor="edit-button-file"
                      className={classes.containerBtn}
                    >
                      <Tooltip title="Modificar logo">
                        <div className={classes.btnFab}>
                          <EditOutlinedIcon color="primary" />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                  <div>
                    <button
                      className={classes.input}
                      id="delete-button-file"
                      onClick={onDeleteImage}
                    />
                    <label
                      htmlFor="delete-button-file"
                      className={classes.containerBtn}
                    >
                      <Tooltip title="Eliminar logo">
                        <div className={classes.btnFab}>
                          <CancelOutlinedIcon color="error" />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                </div>
              )}

              {!!loadingUploadImage && (
                <div>
                  <CircularProgress />
                </div>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanelVisualizacion}
        onChange={() =>
          setExpandedPanelVisualizacion(!expandedPanelVisualizacion)
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel12-header"
        >
          <Typography sx={{ fontSize: "0.95rem", fontWeight: "500" }}>
            Visualización de secciones del formulario
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px 30px 30px 30px" }}>
          <Typography sx={{ fontSize: "0.85rem" }}>
            Habilite o deshabilite las secciones que desea mostrar en el
            formulario.
          </Typography>
          <Divider sx={{ py: 0.5, mb: 2 }} />
          <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
            <Grid item md={4} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox checked={sectionConfiguration.showHeaderSection} />
                }
                onChange={() =>
                  handleChangeSection(
                    "showHeaderSection",
                    sectionConfiguration.showHeaderSection
                  )
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "0.85rem",
                      color: estilos.colorTextSecondary,
                    }}
                  >
                    Mostrar el header
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox checked={sectionConfiguration.showMedicoSection} />
                }
                onChange={() =>
                  handleChangeSection(
                    "showMedicoSection",
                    sectionConfiguration.showMedicoSection
                  )
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "0.85rem",
                      color: estilos.colorTextSecondary,
                    }}
                  >
                    Mostrar los datos del médico
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sectionConfiguration.showPacienteSection}
                  />
                }
                onChange={() =>
                  handleChangeSection(
                    "showPacienteSection",
                    sectionConfiguration.showPacienteSection
                  )
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "0.85rem",
                      color: estilos.colorTextSecondary,
                    }}
                  >
                    Mostrar los datos del paciente
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanelEstilos}
        onChange={() => setExpandedPanelEstilos(!expandedPanelEstilos)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: "0.95rem", fontWeight: "500" }}>
            Estilos del formulario
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={4}
            px={1}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item md={4} xs={12}>
              <Typography sx={{ fontSize: "0.95rem" }}>
                Logo de la aplicación
              </Typography>
              <Divider sx={{ py: 0.5 }} />
              <Box py={2}>
                <Stack spacing={5}>
                  {estilos.logo &&
                    estilos.logo.match(/\.(jpeg|jpg|gif|png|svg)$/) !==
                      null && (
                      <div style={{ display: "flex" }}>
                        <img
                          src={estilos.logo}
                          height="80"
                          alt="logo"
                        />
                      </div>
                    )}
                  <div className={classes.containerTextAndBtn}>
                    <TextField
                      id="logo"
                      label="Logo"
                      variant="outlined"
                      placeholder="Ingrese la url del logo de la aplicación"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={estilos.logo}
                      onChange={(value) => handleOnChangeLogo(value)}
                    />
                    {logoReceta !== estilos.logo && (
                      <div className={classes.btnUseSameImage}>
                        <button
                          className={classes.input}
                          id="use-same-logo-button"
                          onClick={() => handleSetSameLogo(logoReceta)}
                        />
                        <label
                          htmlFor="use-same-logo-button"
                          className={classes.containerBtn}
                        >
                          <Tooltip title="Usar el mismo logo de la receta para el formulario. Recuerde guardar los cambios.">
                            <div className={classes.btnFab}>
                              <ImageIcon color="primary" />
                            </div>
                          </Tooltip>
                        </label>
                      </div>
                    )}
                  </div>
                </Stack>
              </Box>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid
                container
                spacing={2}
                sx={{ justifyContent: "space-between" }}
              >
                <Grid item md={4} xs={12} alignItems="center">
                  <ColorPicker
                    name={"backgroundColorPrimary"}
                    label={"Background principal"}
                    color={
                      estilos.backgroundColorPrimary
                        ? estilos.backgroundColorPrimary
                        : coloresEstandar.backgroundColorPrimary
                    }
                    handleClose={handleClose}
                    handleClick={handleClick}
                    handleOnChange={handleOnChange}
                    displayColorPicker={displayColorPicker}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ColorPicker
                    name={"backgroundColorSecondary"}
                    label={"Background secundario"}
                    color={
                      estilos.backgroundColorSecondary
                        ? estilos.backgroundColorSecondary
                        : coloresEstandar.backgroundColorSecondary
                    }
                    handleClose={handleClose}
                    handleClick={handleClick}
                    handleOnChange={handleOnChange}
                    displayColorPicker={displayColorPicker}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ColorPicker
                    name={"backgroundColorAccent"}
                    label={"Background accent"}
                    color={
                      estilos.backgroundColorAccent
                        ? estilos.backgroundColorAccent
                        : coloresEstandar.backgroundColorAccent
                    }
                    handleClose={handleClose}
                    handleClick={handleClick}
                    handleOnChange={handleOnChange}
                    displayColorPicker={displayColorPicker}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ColorPicker
                    name={"colorTextPrimary"}
                    label={"Color de texto primario"}
                    color={
                      estilos.colorTextPrimary
                        ? estilos.colorTextPrimary
                        : coloresEstandar.colorTextPrimary
                    }
                    handleClose={handleClose}
                    handleClick={handleClick}
                    handleOnChange={handleOnChange}
                    displayColorPicker={displayColorPicker}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ColorPicker
                    name={"colorTextSecondary"}
                    label={"Color de texto secundario"}
                    color={
                      estilos.colorTextSecondary
                        ? estilos.colorTextSecondary
                        : coloresEstandar.colorTextSecondary
                    }
                    handleClose={handleClose}
                    handleClick={handleClick}
                    handleOnChange={handleOnChange}
                    displayColorPicker={displayColorPicker}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ColorPicker
                    name={"colorTextAccent"}
                    label={"Color de texto accent"}
                    color={
                      estilos.colorTextAccent
                        ? estilos.colorTextAccent
                        : coloresEstandar.colorTextAccent
                    }
                    handleClose={handleClose}
                    handleClick={handleClick}
                    handleOnChange={handleOnChange}
                    displayColorPicker={displayColorPicker}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanelCampos}
        onChange={() => setExpandedPanelCampos(!expandedPanelCampos)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: "0.95rem", fontWeight: "500" }}>
            Campos para generar formulario
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontSize: "0.85rem" }}>
            Campos obligatorios para la generación del formulario
          </Typography>
          <Grid
            container
            spacing={4}
            px={1}
            py={4}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item md={5} xs={12}>
              <Typography sx={{ fontSize: "0.90rem", fontWeight: "500" }}>
                Campos del médico
              </Typography>
              <Divider sx={{ py: 0.5, mb: 2 }} />
              <Grid container>
                {diccionario &&
                  diccionario.length > 0 &&
                  diccionario.filter((d) => d.source === "Medico").length > 0 &&
                  diccionario
                    .filter((d) => d.source === "Medico")[0]
                    .campos.map((t, i) => {
                      return (
                        <Grid key={i} item md={4} xs={6}>
                          <FormControlLabel
                            control={<Checkbox checked={true} disabled />}
                            label={
                              <Typography
                                sx={{
                                  fontSize: "0.85rem",
                                  color: estilos.colorTextSecondary,
                                }}
                              >
                                {t.descripcion}
                              </Typography>
                            }
                          />
                        </Grid>
                      );
                    })}
              </Grid>
            </Grid>
            <Grid item md={5} xs={12}>
              <Typography sx={{ fontSize: "0.90rem", fontWeight: "500" }}>
                Campos del paciente
              </Typography>
              <Divider sx={{ py: 0.5, mb: 2 }} />
              <Grid container>
                {diccionario &&
                  diccionario.length > 0 &&
                  diccionario
                    .filter((d) => d.source === "Paciente")[0]
                    .campos.map((t, i) => {
                      return (
                        <Grid key={i} item md={4} xs={6}>
                          <FormControlLabel
                            control={<Checkbox checked={true} disabled />}
                            label={
                              <Typography
                                sx={{
                                  fontSize: "0.85rem",
                                  color: estilos.colorTextSecondary,
                                }}
                              >
                                {t.descripcion}
                              </Typography>
                            }
                          />
                        </Grid>
                      );
                    })}
              </Grid>
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                variant="contained"
                onClick={() => handleOpenCamposObligatorios()}
                sx={{
                  fontSize: "0.86rem",
                  lineHeight: "1.1",
                  fontWeight: "500",
                }}
              >
                Editar campos
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TabEstilos;

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  button: {
    color: theme.palette.primary.main,
    margin: "13px",
  },
  buttonEdit: {
    margin: "13px",
    backgroundColor: "#fff",
  },
  containerBtn: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    alignItems: "center",
  },
  textBtn: {
    fontFamily: "'Poppins',sans-serif",
    fontSize: "13px",
    fontWeight: "500",
    color: theme.palette.primary.main,
  },
  containerImage: {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  btnFab: {
    display: "flex",
    cursor: "pointer",
    padding: "10px",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(241, 241,241,0.8)",
    "&:hover": {
      opacity: 0.9,
    },
  },
  containerTextAndBtn: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    width: "100%",
  },
}));