import axiosInstance from "../helpers/axiosInstance";
import axios from "axios";
import { API_BASE_URL } from "../constants/apiConstants";

class ConfigurationService {
  static getConfiguration(appId) {
    return axiosInstance
      .get("backoffice/Aplicacion/GetConfigByAplicacionId/" + appId)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getCredencialesConfig(appId) {
    return axiosInstance
      .get("backoffice/Credenciales/GetCredencialesByAplicacionId/" + appId)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getLogo(financiadorId, appId) {
    return axiosInstance
      .get(
        "backoffice/Logo/GetLogoByFinanciador/" + appId + "/" + financiadorId
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteLogo(financiadorId, appId) {
    return axiosInstance
      .delete("backoffice/Logo/delete/" + appId + "/" + financiadorId)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static saveLogo(financiadorId, appId, body) {
    let formData = new FormData();

    formData.append("Logo", body);

    return axiosInstance
      .post("backoffice/Logo/new/" + appId + "/" + financiadorId, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getMaestrosCamposConfigurables(appId) {
    return axiosInstance
      .get(
        "backoffice/DiccionarioCampos/GetMaestroCamposRecipeByAplicacionId/" +
          appId
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static setCamposConfigurados(payload) {
    return axiosInstance
      .post("backoffice/DiccionarioCampos/SetCamposConfigurados", payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static createOrUpdateCredenciales(payload) {
    return axiosInstance
      .post("backoffice/Credenciales/CreateOrUpdateCredenciales", payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static updateConfiguration(payload) {
    return axiosInstance
      .put("backoffice/Aplicacion/UpdateConfiguracion", payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getFinanciadores(appKey) {
    return axiosInstance
      .get("backoffice/Financiadores/GetList", {
        headers: { "access-token": appKey },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getDiccionarioCampos(appKey) {
    return axiosInstance
      .get("backoffice/DiccionarioCampos/GetDiccionarioCampos", {
        headers: { "access-token": appKey },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // Sin autenticacion //
  static getConfigurationByHash(hash) {
    return axios
      .get(API_BASE_URL + "GenerateForm/GetByHash/" + hash, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static updateDataForm(body) {
    return axios
      .put(API_BASE_URL + "GenerateForm/Update", body, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // Login //
  static loginByHash(hash) {
    return axios
      .post(API_BASE_URL + "Login/LoginByHash", { hash: hash })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // Renovar sesion //
  static renovarSessionByHash(hash, matriculaMedico) {
    return axios
      .post(API_BASE_URL + "Login/LoginByMedicoData", {
        hash: hash,
        matriculaMedico: matriculaMedico,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // Historial de recetas //
  static getRecord(appId, medicoId, pacienteId) {
    return axios
      .get(
        API_BASE_URL +
          "Receta/GetHistorialRecetas/" +
          appId +
          "/" +
          medicoId +
          "/" +
          pacienteId
      )
      .then((response) => response.data.results)
      .catch((error) => {
        throw error;
      });
  }

  static unsubscribeRecipe(recetaId) {
    return axios.put(API_BASE_URL + "Receta/AnularRecetaById/", {
      recetaId: recetaId,
    });
  }

  static getResendRecipe(numeroReceta, emailPaciente, hash) {
    return axios.get(
      API_BASE_URL + "Receta/Compartir/" + emailPaciente + "/" + numeroReceta,
      {
        headers: {
          hash: hash,
        },
      }
    );
  }

  static createRecipeWithOldRecipe(recipe) {
    return axios.post(API_BASE_URL + "Receta/Create", recipe, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("tokenByHash")}`,
      },
    });
  }
}

export default ConfigurationService;