/* eslint-disable import/no-anonymous-default-export */
import axiosInstance from "../../../helpers/axiosInstance";
import { CONNECTION_ERROR } from "../../../constants/apiConstants";
import {
  USERS_LOADING,
  USERS_LOAD_SUCCESS,
  USERS_LOAD_ERROR,
} from "../../../constants/actionTypes";

export default () => (dispatch) => {
  dispatch({
    type: USERS_LOADING,
  });
  axiosInstance
    .get("backoffice/UsuarioBackoffice/GetList")
    .then((res) => {
      if (res.data.status !== "Error") {
      dispatch({
        type: USERS_LOAD_SUCCESS,
        payload: res.data.results,
      });
      } else {
        dispatch({
          type: USERS_LOAD_ERROR,
          payload: res.data.errors[0],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: USERS_LOAD_ERROR,
        payload: err.response ? err.response.data : CONNECTION_ERROR,
      });
    });
};
