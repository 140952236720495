export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";

/** USUARIOS */

export const USERS_LOADING = "USERS_LOADING";
export const USERS_LOAD_SUCCESS = "USERS_LOAD_SUCCESS";
export const USERS_LOAD_ERROR = "USERS_LOAD_ERROR";

export const ADD_USER_LOAD = "ADD_USER_LOAD";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const CLEAR_ADD_USER = "CLEAR_ADD_USER";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const UPDATE_USER_LOAD = "UPDATE_USER_LOAD";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";


/** APLICACIONES */

export const APLICATIONS_LOADING = "APLICATIONS_LOADING";
export const APLICATIONS_LOAD_SUCCESS = "APLICATIONS_LOAD_SUCCESS";
export const APLICATIONS_LOAD_ERROR = "APLICATIONS_LOAD_ERROR";

export const ADD_APLICATION_LOAD = "ADD_APLICATION_LOAD";
export const ADD_APLICATION_SUCCESS = "ADD_APLICATION_SUCCESS";
export const ADD_APLICATION_ERROR = "ADD_APLICATION_ERROR";

export const CLEAR_ADD_APLICATION = "CLEAR_ADD_APLICATION";

export const DELETE_APLICATION_LOADING = "DELETE_APLICATION_LOADING";
export const DELETE_APLICATION_SUCCESS = "DELETE_APLICATION_SUCCESS";
export const DELETE_APLICATION_ERROR = "DELETE_APLICATION_ERROR";

export const UPDATE_APLICATION_LOAD = "UPDATE_APLICATION_LOAD";
export const UPDATE_APLICATION_SUCCESS = "UPDATE_APLICATION_SUCCESS";
export const UPDATE_APLICATION_ERROR = "UPDATE_APLICATION_ERROR";

/** CONFIGURATION_APP */
export const CONFIGURATION_APP_LOADING = "CONFIGURATION_APP_LOADING";
export const CONFIGURATION_APP_LOAD_SUCCESS =
  "CONFIGURATION_APP_LOAD_SUCCESS";
export const CONFIGURATION_APP_LOAD_ERROR = "CONFIGURATION_APP_LOAD_ERROR";