import "./home.scss";
import React, { useContext , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, Container, Grid, CardContent} from "@mui/material";
import { PageLayout } from "../../components/page/page-layout";
import { Budget } from "../../components/dashboard/budget";
import { AppsListToolbar } from "../../components/dashboard/apps-list-toolbar";
import { AppsListResults } from "../../components/dashboard/app-list-results";
import { DialogComponent } from "../../components/dialog-component";
import { GlobalContext } from "../../context/Provider";
import getAplications from "../../context/actions/aplications/getAplications";

const Home = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [titleDialog, setTitleDialog] = React.useState("");
  const [bodyDialog, setBodyDialog] = React.useState("");
  const { aplicationsDispatch, aplicationsState } = useContext(GlobalContext);

  const {
    aplications: { data, loading },
  } = aplicationsState;

  useEffect(() => {
    getAplications()(aplicationsDispatch);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const executeAction = (action, data) => {
     if (action === "accept-dialog") {
       setOpen(false);
     } else if (action === "desactivar") {
       setTitleDialog("Deshabilitar cliente app");
       setBodyDialog(
         <p>
           ¿Confirma que desea deshabilitar el cliente de app{" "}
           <span style={{ fontWeight: "600" }}>
             {data.appName}
           </span>
           ?
         </p>
       );
       setOpen(true);
     } else if (action === "activar") {
       setTitleDialog("Habilitar cliente app");
       setBodyDialog(
         <p>
           ¿Confirma que desea habilitar el cliente de app{" "}
           <span style={{ fontWeight: "600" }}>
             {data.appName}
           </span>
           ?
         </p>
       );
       setOpen(true);
     } else if (action === "agregar") {
       navigate("/clients/new");
     } else if (action === "view") {
       navigate(`/clients/${data.id}`);
     }
  }

  return (
    <PageLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ my: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <Budget cantidad={data && data.length > 0 ? data.length : 0} />
              </Grid>
            </Grid>
          </Box>
          <Card
            sx={{
              border: "0.5px solid #dddddd",
              padding: "1px",
              boxShadow: "1px 1px #d5d5d5",
            }}
          >
            <CardContent>
              <AppsListToolbar onClick={executeAction} />
              <Box sx={{ mt: 7 }}>
                <DialogComponent
                  open={open}
                  handleClose={handleClose}
                  onClick={executeAction}
                  title={titleDialog}
                  description={bodyDialog}
                />
                <AppsListResults
                  clientes={data}
                  onClick={executeAction}
                  loading={loading}
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </PageLayout>
  );

};


export default Home;
