import React from "react";
import {
  Typography,
  Box,
  Stack,
  Grid,
  Button,
  Alert,
  AlertTitle,
  Divider,
  IconButton,
  Tooltip
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReplayIcon from "@mui/icons-material/Replay";
import RiseLoader from "react-spinners/RiseLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const PanelRecetaGenerada = (props) => {
  const { estilos, error, recetas, onClick, loading, urlCallback, duplicarReceta, tratamientoProlongado } = props;
  const classes = useStyles(theme);
  return (
    <div className={classes.card}>
      <Stack
        flexDirection={'row'}
        sx={{
          padding: "15px 15px",
          alignItems: "center",
        }}
      >
        <>
          <Tooltip title="Esta funcionalidad permite generar otra receta para el paciente del formulario.">
            <Button
              startIcon={<NoteAddRoundedIcon />}
              sx={{
                mr: 1,
                fontSize: "0.68rem",
                fontWeight: "500",
                backgroundColor: estilos.backgroundColorPrimary,
                "&:hover": {
                  backgroundColor: estilos.backgroundColorPrimary,
                  opacity: 0.8,
                },
              }}
              variant="contained"
              onClick={() => duplicarReceta()}
              target="_blank"
            >
              GENERAR NUEVA RECETA
            </Button>
          </Tooltip>
          {urlCallback !== "" && (
            <Button
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              sx={{
                mr: 1,
                fontSize: "0.68rem",
                fontWeight: "500",
                color: estilos.backgroundColorPrimary,
                "&.MuiButton-outlinedPrimary": {
                  border: "1px solid",
                  borderColor: estilos.backgroundColorPrimary,
                },
              }}
              href={urlCallback}
              target="_blank"
            >
              VOLVER AL INICIO
            </Button>
          )}
        </>
      </Stack>
      <Divider style={{ background: "#dddddd", height: "1.5px" }} />
      <Grid container spacing={2} alignItems="center" py={1}>
        <Grid item md={6} xs={6}>
          <Typography
            sx={{
              m: 1,
              fontSize: "1.10rem",
              color: estilos.colorTextSecondary,
              fontWeight: "bold",
            }}
          >
            Prescripción de recetas
          </Typography>
        </Grid>
        <Grid item md={6} xs={6} sx={{ textAlign: "end" }}>
          <Tooltip title="Se compartirán todas las recetas generados en el formulario a la casilla de correo del paciente o al que indique el médico antes de la confirmación.">
            <Button
              startIcon={<ForwardToInboxIcon />}
              sx={{
                mr: 1,
                fontSize: "0.68rem",
                fontWeight: "500",
                backgroundColor: "#4BB067",
                "&:hover": {
                  backgroundColor: "#64C57F",
                  opacity: 0.8,
                },
              }}
              variant="contained"
              onClick={() => onClick("share")}
              target="_blank"
            >
              COMPARTIR RECETA/S
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Divider style={{ background: "#dddddd", height: "1.5px" }} />
      <Box
        sx={{
          padding: "15px 15px",
          alignItems: "center",
        }}
      >
        {error && (
          <Stack spacing={2}>
            <Alert severity="error">
              <AlertTitle>
                Ocurrió un error al obtener los datos de la receta generada.
              </AlertTitle>
              {error}
            </Alert>
            <Box
              spacing={2}
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                paddingBottom: "10px",
              }}
            >
              <Button
                sx={{
                  mr: 1,
                  fontSize: "0.68rem",
                  fontWeight: "500",
                  backgroundColor: estilos.backgroundColorPrimary,
                  "&:hover": {
                    backgroundColor: estilos.backgroundColorPrimary,
                    opacity: 0.8,
                  },
                }}
                startIcon={<ReplayIcon />}
                variant="contained"
                onClick={() => onClick("reintentar")}
              >
                Reintentar
              </Button>
            </Box>
          </Stack>
        )}
        {loading && (
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Stack
              spacing={5}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <RiseLoader
                color={estilos.backgroundColorPrimary}
                loading={loading}
                size={17}
              />
              <Typography
                sx={{
                  fontSize: "0.95rem",
                  fontWeight: "400",
                  textAlign: "center",
                  color: estilos.colorTextSecondary,
                }}
              >
                Obteniendo datos de tu receta ...
              </Typography>
            </Stack>
          </Box>
        )}
        <Stack spacing={10}>
          {!error &&
            recetas &&
            recetas.length > 0 &&
            recetas.map((receta, i) => {
              return (
                <Grid key={i} container>
                  <Grid item md={9} xs={9}>
                    <Stack spacing={3}>
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          fontWeight: "400",
                          color: estilos.colorTextSecondary,
                        }}
                      >
                        Número de receta:{" "}
                        <span style={{ fontWeight: "700" }}>
                          {receta.numeroReceta}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          fontWeight: "400",
                          color: estilos.colorTextSecondary,
                        }}
                      >
                        Fecha:{" "}
                        <span style={{ fontWeight: "700" }}>
                          {receta.fecha}
                        </span>
                      </Typography>
                      <Stack spacing={2}>
                        {receta.medicamentos &&
                          receta.medicamentos.length > 0 &&
                          receta.medicamentos.map((medicamento, i) => {
                            return (
                              <Box>
                                {medicamento.permiteSustitucion &&
                                  !JSON.parse(medicamento.permiteSustitucion)
                                    .g && (
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        fontWeight: "600",
                                        color: estilos.colorTextSecondary,
                                      }}
                                    >
                                      {medicamento.nombreProducto}
                                    </Typography>
                                  )}
                                {medicamento.permiteSustitucion &&
                                  JSON.parse(medicamento.permiteSustitucion)
                                    .g && (
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        fontWeight: "600",
                                        color: estilos.colorTextSecondary,
                                      }}
                                    >
                                      {medicamento.nombreDroga}
                                    </Typography>
                                  )}
                                <Typography
                                  sx={{
                                    fontSize: "0.85rem",
                                    fontWeight: "400",
                                    color: estilos.colorTextAccent,
                                  }}
                                >
                                  {medicamento.presentacion}
                                </Typography>
                                {medicamento.permiteSustitucion &&
                                  !JSON.parse(medicamento.permiteSustitucion)
                                    .g && (
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        fontWeight: "400",
                                        color: estilos.colorTextAccent,
                                      }}
                                    >
                                      ({medicamento.nombreDroga})
                                    </Typography>
                                  )}
                                <Typography
                                  sx={{
                                    fontSize: "0.85rem",
                                    fontWeight: "400",
                                    color: estilos.colorTextAccent,
                                  }}
                                >
                                  Envases: {medicamento.cantidad}
                                </Typography>
                                {medicamento.diagnostico && (
                                  <Typography
                                    sx={{
                                      fontSize: "0.85rem",
                                      fontWeight: "400",
                                      color: estilos.colorTextAccent,
                                    }}
                                  >
                                    Diagnóstico:{" "}
                                    {
                                      JSON.parse(medicamento.diagnostico)
                                        .descDiagnostico
                                    }
                                  </Typography>
                                )}
                                {medicamento.permiteSustitucion &&
                                  JSON.parse(medicamento.permiteSustitucion)
                                    .o && (
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        fontWeight: "400",
                                        color: estilos.colorTextAccent,
                                      }}
                                    >
                                      NO SUSTITUIR
                                    </Typography>
                                  )}
                                {medicamento.posologia &&
                                  medicamento.posologia !== "" && (
                                    <Typography
                                      sx={{
                                        fontSize: "0.85rem",
                                        fontWeight: "400",
                                        color: estilos.colorTextAccent,
                                        marginTop: "10px",
                                      }}
                                    >
                                      Tratamiento: {medicamento.posologia}
                                    </Typography>
                                  )}
                              </Box>
                            );
                          })}
                      </Stack>
                      {receta.diagnostico && (
                        <Typography
                          sx={{
                            fontSize: "0.85rem",
                            fontWeight: "400",
                            color: estilos.colorTextAccent,
                          }}
                        >
                          DIAGNÓSTICO: {receta.diagnostico}
                        </Typography>
                      )}
                      {tratamientoProlongado && (
                        <Typography
                          sx={{
                            fontSize: "0.85rem",
                            fontWeight: "400",
                            color: estilos.colorTextAccent,
                          }}
                        >
                          TRATAMIENTO PROLONGADO
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Tooltip title="Descargar PDF de receta">
                      <IconButton
                        sx={{
                          backgroundColor: estilos.backgroundColorPrimary,
                          "&:hover": {
                            backgroundColor: estilos.backgroundColorPrimary,
                            opacity: 0.8,
                          },
                        }}
                        href={receta.linkReceta}
                        target="_blank"
                      >
                        <PictureAsPdfIcon sx={{ color: "#ffff" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}
        </Stack>
      </Box>
    </div>
  );
};

export default PanelRecetaGenerada;

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    borderRadius: 7,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
    margin: "5px",
    padding: "16px",
    filter:
      "dropShadow(0px 1px 1px rgba(0, 0, 0, 0.14)) dropShadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
  },
}));
