import axios from "axios";
import { API_BASE_URL } from "../../../constants/apiConstants";

import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from "../../../constants/actionTypes";
export const login =
  (payload) =>
  (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });

    axios
      .post(API_BASE_URL + "backoffice/LoginBackoffice", payload)
      .then((res) => {

        if (res.data.status !== 'Error') {
          localStorage.setItem("refreshToken", res.data.result.refreshToken);
          localStorage.setItem("accessToken", res.data.result.accessToken);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.result,
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: res.data.errors[0],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ERROR,
          payload: err.response ? err.response.data : "NO SE PUEDE INGRESAR EN ESTE MOMENTO. INTENTELO MÁS TARDE",
        });
      });
  };
