import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import {
  Typography,
  Box,
  Stack,
  Autocomplete,
  IconButton,
  Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DiagnosticoGeneral from "./panel-receta/diagnostico-general";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const FormAddMedicamento = (props) => {
  const {
    marcas,
    marcaSeleccionada,
    drogas,
    drogaSeleccionada,
    seleccionarMedicamento,
    onInputChangeMedicamentos,
    presentaciones,
    presentacionSeleccionada,
    diagnosticos,
    diagnostico,
    addDiagnostico,
    onInputChangeDiagnostico,
    imprimirCodigo,
    cantidad,
    onClick,
    estilos,
    dataForm,
    loadingSearchDroga,
    loadingSearchMarca,
    original,
    generico,
    tratamiento,
    onChangePosologia,
    posologia,
    addMed,
    maxCantidadEnvases,
    existente
  } = props;

  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      <Typography
        sx={{
          mb: 1,
          fontSize: "0.95rem",
          color: estilos.colorTextSecondary,
          fontWeight: "500",
        }}
      >
        Buscá por marca o droga.
      </Typography>
      <Box
        sx={{
          padding: "15px 0px 10px 0px",
        }}
      >
        <Stack
          direction={
            useMediaQuery(theme.breakpoints.down("md")) ? "column" : "row"
          }
          spacing={useMediaQuery(theme.breakpoints.down("md")) ? 5 : 2}
        >
          <Autocomplete
            id="marca"
            sx={{
              width: "50%",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
            filterSelectedOptions
            onInputChange={(event, value) =>
              onInputChangeMedicamentos(value, "marca")
            }
            getOptionLabel={(option) => option.nombreProducto}
            options={marcas}
            loadingText={"Buscando..."}
            noOptionsText={"Sin resultados"}
            value={marcaSeleccionada}
            loading={loadingSearchMarca}
            onChange={(event, newValue) => {
              seleccionarMedicamento(newValue, "marca");
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                color={estilos.backgroundColorPrimary}
                label="Marca de medicamento"
                placeholder={"Por ejemplo: Actron Flex,  Luminaletas"}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(props, option) => (
              <Typography {...props}>
                {option.nombreProducto + "(" + option.nombreDroga + ")"}
              </Typography>
            )}
          />
          <Autocomplete
            id="droga"
            sx={{
              width: "50%",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
            value={drogaSeleccionada}
            options={drogas}
            loadingText={"Buscando.."}
            noOptionsText={"Sin resultados"}
            onInputChange={(event, value) =>
              onInputChangeMedicamentos(value, "droga")
            }
            getOptionLabel={(option) => option.nombreDroga}
            onChange={(event, newValue) => {
              seleccionarMedicamento(newValue, "droga");
            }}
            renderOption={(props, option) => (
              <Typography {...props}>
                {option.nombreProducto + "(" + option.nombreDroga + ")"}
              </Typography>
            )}
            loading={loadingSearchDroga}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                color={estilos.backgroundColorPrimary}
                label="Droga"
                placeholder="Por ejemplo: Ibuprofeno, Fenobarbital"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          padding: "10px 0px 10px 0px",
        }}
      >
        <Stack
          direction={
            useMediaQuery(theme.breakpoints.down("md")) ? "column" : "row"
          }
          spacing={useMediaQuery(theme.breakpoints.down("md")) ? 5 : 2}
          sx={{ alignItems: "center" }}
        >
          <Stack
            sx={{
              width: "50%",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
            spacing={0}
          >
            <Box sx={{ height: "20px", marginBottom: "8px" }}></Box>
            <Autocomplete
              id="presentaciones"
              disabled={presentaciones.length === 0}
              value={presentacionSeleccionada}
              getOptionLabel={(option) => option.presentacion}
              options={presentaciones}
              loadingText={"Buscando.."}
              noOptionsText={"Sin resultados"}
              onChange={(event, newValue) => {
                onClick("presentacion", newValue);
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  color={estilos.backgroundColorPrimary}
                  label="Presentación"
                  placeholder="Elije una opción"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Stack>

          <Stack spacing={0} sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                mb: 1,
                fontSize: "0.85rem",
                color: estilos.colorTextSecondary,
                fontWeight: "400",
              }}
            >
              Cantidad
            </Typography>
            <Stack direction="row" spacing={2}>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ color: estilos.backgroundColorPrimary }}
                onClick={() => onClick("remove")}
              >
                <RemoveIcon fontSize="inherit" />
              </IconButton>
              <Typography
                sx={{
                  mx: 1,
                  fontSize: "0.99rem",
                  color: estilos.colorTextSecondary,
                  fontWeight: "400",
                }}
              >
                {cantidad}
              </Typography>
              <IconButton
                aria-label="add"
                size="small"
                disabled={cantidad === maxCantidadEnvases}
                sx={{ color: estilos.backgroundColorPrimary }}
                onClick={() => onClick("add")}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ padding: "15px 0px" }}>
        <Typography
          sx={{
            m: 0.5,
            fontSize: "0.91rem",
            color: estilos.colorTextSecondary,
            fontWeight: "500",
          }}
        >
          Posología
        </Typography>
        <Box
          sx={{
            padding: "10px 0px 0px 0px",
            display: "flex",
          }}
        >
          <CustomTextField
            size="small"
            onChange={onChangePosologia}
            value={posologia}
            color={estilos.backgroundColorPrimary}
            fullWidth
            placeholder="Escribe la frecuencia de la toma"
            label="Posología"
            id="posologia"
            InputProps={{
              classes: {
                input: classes.resizeFont,
              },
            }}
            InputLabelProps={{ style: { fontSize: 15 }, shrink: true }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: "5px 0px 0px 0px",
        }}
      >
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={original}
                size={"small"}
                sx={{
                  "&.Mui-checked": {
                    color: estilos.backgroundColorPrimary,
                  },
                }}
              />
            }
            onChange={() => onClick("original")}
            label={
              <Typography
                sx={{ fontSize: "0.85rem", color: estilos.colorTextSecondary }}
              >
                No sustituir
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={generico}
                size={"small"}
                sx={{
                  "&.Mui-checked": {
                    color: estilos.backgroundColorPrimary,
                  },
                }}
              />
            }
            onChange={() => onClick("generico")}
            label={
              <Typography
                sx={{ fontSize: "0.85rem", color: estilos.colorTextSecondary }}
              >
                Sólo genérico
              </Typography>
            }
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={tratamiento}
                size={"small"}
                sx={{
                  "&.Mui-checked": {
                    color: estilos.backgroundColorPrimary,
                  },
                }}
              />
            }
            onChange={() => onClick("tratamiento")}
            label={
              <Typography
                sx={{ fontSize: "0.85rem", color: estilos.colorTextSecondary }}
              >
                Tratamiento prolongado
              </Typography>
            }
          /> */}
        </Stack>
      </Box>
      {dataForm.diagnosticoPorMedicamento && (
        <DiagnosticoGeneral
          data={diagnosticos}
          title={"Diagnóstico"}
          diagnostico={diagnostico}
          onInputChange={onInputChangeDiagnostico}
          setDiagnostico={addDiagnostico}
          handleOnChange={onClick}
          checked={imprimirCodigo}
          estilos={estilos}
          particular
        />
      )}
      {existente && (
        <Box
          sx={{
            padding: "15px 0px 0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "0.75rem",
              color: "red",
              fontWeight: "400",
            }}
          >
            La edición no puede realizarse, ya que está intentando cambiar el
            medicamento con otro previamente cargado.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          padding: "15px 0px 0px",
          [theme.breakpoints.down("sm")]: {
            padding: "50px 0px 0px",
            justifyContent: "center",
          },
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              color: estilos.backgroundColorPrimary,
              "&.MuiButton-outlinedPrimary": {
                border: "1px solid",
                borderColor: estilos.backgroundColorPrimary,
              },
            }}
            color="primary"
            variant="outlined"
            onClick={() => onClick("clear")}
          >
            LIMPIAR
          </Button>
          <Button
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              backgroundColor: estilos.backgroundColorPrimary,
              "&:hover": {
                backgroundColor: estilos.backgroundColorPrimary,
                opacity: 0.8,
              },
            }}
            color="primary"
            variant="contained"
            disabled={
              !marcaSeleccionada ||
              !drogaSeleccionada ||
              !presentacionSeleccionada ||
              addMed
            }
            onClick={() => onClick("add-med")}
          >
            AGREGAR MEDICAMENTO
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default FormAddMedicamento;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    marginBottom: 5,
  },
  resizeFont: {
    fontSize: 15,
  },
}));

const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& label.Mui-focused": {
    color: color,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: color,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: color,
    },
    "&.Mui-focused fieldset": {
      borderColor: color,
    },
    fontSize: "15px",
  },
}));