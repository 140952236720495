import React, { useContext,useRef, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { useParams } from "react-router-dom";
import PanelPaciente from "../../components/formulario/panel-paciente";
import { Box } from "@mui/material";
import PanelMedico from "../../components/formulario/panel-medico";
import PanelReceta from "../../components/formulario/panel-receta";
import CustomDialog from "../../components/custom-dialog";
import FormAddMedicamento from "../../components/formulario/form-add-medicamento";
import ConfigurationService from "../../services/ConfigurationService";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";

const Formulario = () => {
  const params = useParams();
  /** DIAGNOSTICO */
  const [diagnosticos, setDiagnosticos] = useState([
    {
      idDiagnostico: 30907,
      codDiagnostico: "C250",
      descDiagnostico: "TUMOR MALIGNO DE LA CABEZA DEL PANCREAS",
    },
    {
      idDiagnostico: 30995,
      codDiagnostico: "C470",
      descDiagnostico:
        "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DE LA CABEZA, CARA Y CUELLO",
    },
    {
      idDiagnostico: 31008,
      codDiagnostico: "C490",
      descDiagnostico:
        "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DE LA CABEZA, CARA Y CUELLO",
    },
    {
      idDiagnostico: 31127,
      codDiagnostico: "C760",
      descDiagnostico: "TUMOR MALIGNO DE LA CABEZA, CARA Y CUELLO",
    },
    {
      idDiagnostico: 31136,
      codDiagnostico: "C770",
      descDiagnostico:
        "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE LA CABEZA, CARA Y CUELLO",
    },
    {
      idDiagnostico: 31351,
      codDiagnostico: "D170",
      descDiagnostico:
        "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DE CABEZA, CARA Y CUELLO",
    },
    {
      idDiagnostico: 31368,
      codDiagnostico: "D210",
      descDiagnostico:
        "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DE CABEZA, CARA Y CUELLO",
    },
    {
      idDiagnostico: 34208,
      codDiagnostico: "L040",
      descDiagnostico: "LINFADENITIS AGUDA DE CARA, CABEZA Y CUELLO",
    },
    {
      idDiagnostico: 35018,
      codDiagnostico: "M911",
      descDiagnostico:
        "OSTEOCONDROSIS JUVENIL DE LA CABEZA DEL FEMUR [LEGG-CALVE-PERTHES]",
    },
    {
      idDiagnostico: 35046,
      codDiagnostico: "M952",
      descDiagnostico: "OTRAS DEFORMIDADES ADQUIRIDAS DE LA CABEZA",
    },
  ]);
  const previousControllerDiagnosticos = useRef();
  const [diagnostico, setDiagnostico] = React.useState(null);
  const [imprimirCodigo, setImprimirCodigo] = React.useState(false);

  /** LEYENDA */
  const [leyenda, setLeyenda] = useState("");

  /** MEDICAMENTOS */
  const [presentaciones, setPresentaciones] = React.useState([]);
  const [presentacionSeleccionada, setPresentacionSeleccionada] =
    React.useState(null);

  const [marcas, setMarcas] = React.useState([]);
  const [drogas, setDrogas] = React.useState([]);

  const [marcaSeleccionada, setMarcaSeleccionada] = React.useState(null);
  const [drogaSeleccionada, setDrogaSeleccionada] = React.useState(null);

  const previousControllerMedicamentosByMarca = useRef();
  const previousControllerMedicamentosByDroga = useRef();

  const [loadingSearchMarca, setLoadingSearchMarca] = React.useState(false);
  const [loadingSearchDroga, setLoadingSearchDroga] = React.useState(false);

  const [medicamentosReceta, setMedicamentosReceta] = React.useState([]);

  const [sustituirMed, setSustituirMed] = React.useState(false);
  const [tratamiento, setTratamiento] = React.useState(false);

  const [posologia, setPosologia] = React.useState("");

  const [diagnosticosByMed, setDiagnosticosByMed] = useState([]);
  const previousControllerDiagnosticosByMed = useRef();
  const [diagnosticoByMed, setDiagnosticoByMed] = React.useState(null);
  const [imprimirCodigoByMed, setImprimirCodigoByMed] = React.useState(false);

  const [showDialogAddMedicamento, setShowDialogAddMedicamento] =
    React.useState(false);
  const [cantidad, setCantidad] = React.useState(1);
  const [dataForm, setDataForm] = useState(null);
  const [estilos, setEstilos] = useState(null);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [cantidadRecetas, setCantidadRecetas] = useState(1);
  const [posdatadas, setPosdatadas] = useState([
    { id: 1, fecha: dayjs(new Date()) },
  ]);

  const [sectionConfiguration, setSectionConfiguration] = React.useState(null);

  useEffect(() => {
    setLoadingConfig(true);

    ConfigurationService.getConfiguration(params.appId)
      .then((response) => {
        if (response.status === "Ok") {
          setDataForm(response.result.dataFormulario);
          setEstilos(response.result.estilos);
          setSectionConfiguration({
            showHeaderSection: response.result.showHeaderSection,
            showMedicoSection: response.result.showMedicoSection,
            showPacienteSection: response.result.showPacienteSection,
          });
          setLoadingConfig(false);
        } else {
          setLoadingConfig(false);
        }
      })
      .catch(function (error) {
        setLoadingConfig(false);
      });
  }, [data, params.appId]);

  const getData = (searchTerm) => {
    
  };

  const addDiagnostico = (value) => {
    setDiagnostico(value);
  };

  const onInputChange = (value) => {
    if (value) {
      getData(value);
    } else {
      setDiagnosticos([]);
    }
  };

  const onChangeLeyenda = (event) => {
    setLeyenda(event.target.value);
  };

  const handleChangePostdatada = (id, value) => {
    setPosdatadas((oldState) =>
      oldState.map((item) => {
        if (item.id === id) {
          return {
            fecha: value,
          };
        }
        return { ...item };
      })
    );
  };

  const searchMedicamentosByMarca = (searchTerm) => {
    
  };

  const searchMedicamentosByDroga = (searchTerm) => {
    
  };

  const onInputChangeMedicamentos = (value, groupBy) => {
    if (groupBy === "marca") {
      if (value) {
        searchMedicamentosByMarca(value);
      } else {
        setMarcas([]);
      }
    } else {
      if (value) {
        searchMedicamentosByDroga(value);
      } else {
        setDrogas([]);
      }
    }
  };

  const seleccionarMedicamento = (value, groupBy) => {
    if (groupBy === "marca") {
      if (value) {
        setMarcaSeleccionada(value);
        setDrogaSeleccionada(value);
        setPresentaciones(value.presentaciones);
        if (value.presentaciones.length === 1) {
          setPresentacionSeleccionada(value.presentaciones[0]);
        } else {
          setPresentacionSeleccionada(null);
        }
      } else {
        setPresentaciones([]);
        setPresentacionSeleccionada(null);
        setMarcas([]);
        setMarcaSeleccionada(null);
      }
    } else {
      if (value) {
        setDrogaSeleccionada(value);
        setMarcaSeleccionada(value);
        setPresentaciones(value.presentaciones);
        if (value.presentaciones.length === 1) {
          setPresentacionSeleccionada(value.presentaciones[0]);
        } else {
          setPresentacionSeleccionada(null);
        }
      } else {
        setPresentaciones([]);
        setPresentacionSeleccionada(null);
        setDrogas([]);
        setDrogaSeleccionada(null);
      }
    }
  };

  const inicializarValores = () => {
    setMarcas([]);
    setDrogas([]);
    setMarcaSeleccionada(null);
    setDrogaSeleccionada(null);
    setPresentaciones([]);
    setPresentacionSeleccionada(null);
    setCantidad(1);
    setTratamiento(false);
    setSustituirMed(false);
    setPosologia("");
    setDiagnosticosByMed([]);
    setDiagnosticoByMed(null);
    setImprimirCodigoByMed(false);
  };

  const onChangePosologia = (event) => {
    setPosologia(event.target.value);
  };

  const searchDiagnosticosByMed = (searchTerm) => {
    
  };

  const addDiagnosticoByMed = (value) => {
    setDiagnosticoByMed(value);
  };

  const onInputChangeDiagnosticoByMed = (value) => {
    if (value) {
      searchDiagnosticosByMed(value);
    } else {
      setDiagnosticosByMed([]);
      setDiagnosticoByMed(null);
    }
  };

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  const executeAction = (action, data) => {
    if (action === "imprimir") {
      setImprimirCodigo(!imprimirCodigo);
    } else if (action === "imprimir-particular") {
      setImprimirCodigoByMed(!imprimirCodigoByMed);
    } else if (action === "add-medicamento") {
      inicializarValores();
      setShowDialogAddMedicamento(!showDialogAddMedicamento);
    } else if (action === "presentacion") {
      setPresentacionSeleccionada(data);
    } else if (action === "add") {
      setCantidad(cantidad + 1);
    } else if (action === "remove") {
      if (cantidad > 1) {
        setCantidad(cantidad - 1);
      }
    } else if (action === "add-med") {
      const aux = {};
      aux.presentacion = presentacionSeleccionada.presentacion;
      aux.regNo = presentacionSeleccionada.regNo;
      aux.nombreProducto = marcaSeleccionada.nombreProducto;
      aux.nombreDroga = drogaSeleccionada.nombreDroga;
      aux.cantidad = cantidad;
      aux.permiteSustitucion = sustituirMed ? "S" : "N";
      aux.tratamiento = tratamiento ? 1 : 0;
      aux.posologia = posologia;
      aux.diagnostico = diagnosticoByMed;
      aux.imprimirCodigo = imprimirCodigoByMed;
      aux.id = randomNumberInRange(100, 1000);

      medicamentosReceta.push(aux);
      setShowDialogAddMedicamento(!showDialogAddMedicamento);
    } else if (action === "add-postdatadas") {
      if (cantidadRecetas < dataForm.cantidadRecetasPostdatadas + 1) {
        setCantidadRecetas(cantidadRecetas + 1);
        let receta = {};
        receta.id = posdatadas.length + 1;
        receta.fecha = posdatadas[posdatadas.length - 1].fecha.add(18, "day");
        posdatadas.push(receta);
      }
    } else if (action === "remove-postdatadas") {
      if (cantidadRecetas > 1) {
        setCantidadRecetas(cantidadRecetas - 1);
        setPosdatadas(posdatadas.filter((p) => p.id !== posdatadas.length));
      }
    } else if (action === "clear") {
      inicializarValores();
    } else if (action === "sustituir") {
      setSustituirMed(!sustituirMed);
    } else if (action === "tratamiento") {
      setTratamiento(!tratamiento);
    }
  };
  return (
    <>
      {loadingConfig && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            padding: "50px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loadingConfig && estilos && dataForm && sectionConfiguration && (
        <>
          {sectionConfiguration.showHeaderSection && <AppBar position="fixed" color={"default"}>
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                {estilos.logo && (
                  <img src={estilos.logo} height={"52px"} alt="logo" />
                )}
              </Toolbar>
            </Container>
          </AppBar>}

          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              width: "100%",
              paddingTop: sectionConfiguration.showHeaderSection ?  "68px" : "0px",
              backgroundColor: "#EEEEEE",
            }}
          >
            <Grid container spacing={1}>
              {!loadingConfig && (sectionConfiguration.showMedicoSection || sectionConfiguration.showPacienteSection) && (
                <Grid item xs={12} md={3}>
                  {sectionConfiguration.showPacienteSection && <PanelPaciente paciente={data.paciente} estilos={estilos} />}
                  {sectionConfiguration.showMedicoSection && <PanelMedico medico={data.medico} estilos={estilos} />}
                </Grid>
              )}
              <CustomDialog
                title={"Agregar medicamento"}
                open={showDialogAddMedicamento}
                estilos={estilos}
                onClose={() => setShowDialogAddMedicamento(false)}
              >
                {/* <FormAddMedicamento
                  medicamentos={listaMedicamentos}
                  presentaciones={presentaciones}
                  marcaSeleccionada={marcaSeleccionada}
                  drogaSeleccionada={drogaSeleccionada}
                  presentacionSeleccionada={presentacionSeleccionada}
                  cantidad={cantidad}
                  onClick={executeAction}
                  diagnosticos={diagnosticos}
                  dataForm={dataForm}
                  estilos={estilos}
                /> */}

                <FormAddMedicamento
                  marcas={listaMedicamentos}
                  marcaSeleccionada={marcaSeleccionada}
                  drogas={listaMedicamentos}
                  drogaSeleccionada={drogaSeleccionada}
                  imprimirCodigo={imprimirCodigoByMed}
                  onInputChangeMedicamentos={onInputChangeMedicamentos}
                  seleccionarMedicamento={seleccionarMedicamento}
                  loadingSearchMarca={loadingSearchMarca}
                  loadingSearchDroga={loadingSearchDroga}
                  presentaciones={presentaciones}
                  presentacionSeleccionada={presentacionSeleccionada}
                  cantidad={cantidad}
                  sustituirMed={sustituirMed}
                  tratamiento={tratamiento}
                  onChangePosologia={onChangePosologia}
                  posologia={posologia}
                  onClick={executeAction}
                  diagnosticos={diagnosticosByMed}
                  diagnostico={diagnosticoByMed}
                  addDiagnostico={addDiagnosticoByMed}
                  onInputChangeDiagnostico={onInputChangeDiagnosticoByMed}
                  dataForm={dataForm}
                  estilos={estilos}
                />
              </CustomDialog>
              {!loadingConfig && (
                <Grid item xs={12} md={(sectionConfiguration.showMedicoSection || sectionConfiguration.showPacienteSection) ? 9 : 12}>
                  {/* <PanelReceta
                    dataForm={dataForm}
                    estilos={estilos}
                    diagnosticos={diagnosticos}
                    diagnostico={diagnostico}
                    setDiagnostico={setDiagnostico}
                    imprimirCodigo={imprimirCodigo}
                    onClick={executeAction}
                    medicamentosReceta={medicamentosReceta}
                    cantidadRecetas={cantidadRecetas}
                    posdatadas={posdatadas}
                  /> */}
                  <PanelReceta
                    dataForm={dataForm}
                    estilos={estilos}
                    diagnosticos={diagnosticos}
                    diagnostico={diagnostico}
                    onInputChange={onInputChange}
                    leyenda={leyenda}
                    onChangeLeyenda={onChangeLeyenda}
                    setDiagnostico={addDiagnostico}
                    imprimirCodigo={imprimirCodigo}
                    posdatadas={posdatadas}
                    handleChangePostdatada={handleChangePostdatada}
                    cantidadRecetas={cantidadRecetas}
                    onClick={executeAction}
                    medicamentosReceta={medicamentosReceta}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Formulario;

const data = {
  paciente: {
    idPaciente: "1232131",
    obraSocial: "OSDE",
    nombrePaciente: "Tomás",
    apellidoPaciente: "Manchini",
    nroAfiliado: "21321312",
    plan: "210",
    tipoDocumento: "DNI",
    nroDocumento: "33567098",
    pais: "Argentina",
  },
  medico: {
    idMedico: "3232323",
    nombreMedico: "Pablo",
    apellidoMedico: "López",
    nroMatricula: "896534",
  },
};

const listaMedicamentos = [
  {
    nombreProducto: "TAFIROL",
    nombreDroga: "paracetamol",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "56507",
        presentacion: "Exp.blist.x 40 comp.x10",
        descuento: 0.0,
      },
      {
        regNo: "20095",
        presentacion: "comp.blister x 10",
        descuento: 0.0,
      },
      {
        regNo: "32033",
        presentacion: "comp.x 100",
        descuento: 0.0,
      },
      {
        regNo: "49825",
        presentacion: "comp.x 30",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROL 1 G",
    nombreDroga: "paracetamol",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "45318",
        presentacion: "comp.ran.x 80",
        descuento: 0.0,
      },
      {
        regNo: "29983",
        presentacion: "comp.ran.x 50",
        descuento: 0.0,
      },
      {
        regNo: "56668",
        presentacion: "comp.ran.x 24",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROL ARTRO",
    nombreDroga: "diclofenac potásico+paracetamol",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "38214",
        presentacion: "50/500 mg comp.rec.x 20",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROL FLEX",
    nombreDroga: "paracetamol+ibuprofeno",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "58652",
        presentacion: "comp.x 10",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROL FORTE",
    nombreDroga: "paracetamol",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "59163",
        presentacion: "Exp.blist.x30 comp.x 10",
        descuento: 0.0,
      },
      {
        regNo: "49827",
        presentacion: "650 mg comp.x 30",
        descuento: 0.0,
      },
      {
        regNo: "42689",
        presentacion: "650 mg comp.blister x 10",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROL MIGRA",
    nombreDroga: "paracetamol+asoc.",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "58347",
        presentacion: "Exp.blist.x 40 comp.x 5",
        descuento: 0.0,
      },
      {
        regNo: "55051",
        presentacion: "comp.x 10",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROL PEDIATRICO",
    nombreDroga: "paracetamol",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "38031",
        presentacion: "gts.x 20 ml",
        descuento: 0.0,
      },
      {
        regNo: "34006",
        presentacion: "jbe.x 120 ml",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROL PLUS RAPIDA ACCION",
    nombreDroga: "diclofenac sódico+paracetamol",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "54623",
        presentacion: "cáps.bl. x 8",
        descuento: 0.0,
      },
      {
        regNo: "54624",
        presentacion: "cáps.bl. x 16",
        descuento: 0.0,
      },
      {
        regNo: "55684",
        presentacion: "Exp.blist.x 30 cáps. x 8",
        descuento: 0.0,
      },
    ],
  },
  {
    nombreProducto: "TAFIROLITO",
    nombreDroga: "paracetamol",
    psicofarmaco: false,
    estupefaciente: false,
    ventaControlada: false,
    presentaciones: [
      {
        regNo: "56330",
        presentacion: "comp.mast.x 20",
        descuento: 0.0,
      },
      {
        regNo: "59263",
        presentacion: "80 mg comp. x 20",
        descuento: 0.0,
      },
    ],
  },
];
