import {
  Box,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { ClientSchema } from "../../helpers/validation";

const tiposDocumentos = [
  {
    value: "CUIT",
    label: "CUIT",
  },
  {
    value: "DNI",
    label: "DNI",
  },
  {
    value: "LE",
    label: "LE",
  },
  {
    value: "LC",
    label: "LC",
  },
  {
    value: "CI",
    label: "CI",
  },
  {
    value: "PASAPORTE",
    label: "PASAPORTE",
  },
];


export const ClientNewForm = (props) => {
  const { onSubmit, loading, error} = props;

  const formik = useFormik({
    initialValues: {
      appName: "",
      appUser:"",
      appUrl: "",
      razonSocial: "",
      email: "",
      tipoDoc:"CUIT",
      personaFisica: true,
      nroDoc: "0",
      cuit: "",
      aceptaTerminos: true,
    },
    validationSchema: ClientSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          border: "0.5px solid #dddddd",
          padding: "1px",
          boxShadow: "1px 1px #dddddd",
        }}
      >
        <CardHeader
          subheader="Complete los datos del formulario para agregar un cliente de aplicación"
          title="Cliente"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.appName && formik.errors.appName)}
                fullWidth
                helperText={formik.touched.appName && formik.errors.appName}
                label="Nombre de la app"
                name="appName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.appName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.appUrl && formik.errors.appUrl)}
                fullWidth
                helperText={formik.touched.appUrl && formik.errors.appUrl}
                label="Url App"
                name="appUrl"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.appUrl}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.appUser && formik.errors.appUser)}
                fullWidth
                helperText={formik.touched.appUser && formik.errors.appUser}
                label="Nombre de usuario"
                name="appUser"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.appUser}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.razonSocial && formik.errors.razonSocial
                )}
                fullWidth
                helperText={
                  formik.touched.razonSocial && formik.errors.razonSocial
                }
                label="Razón social"
                name="razonSocial"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.razonSocial}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                type="email"
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={4} xs={12}>
              <FormControlLabel
                error={Boolean(
                  formik.touched.personaFisica && formik.errors.personaFisica
                )}
                fullWidth
                helperText={
                  formik.touched.personaFisica && formik.errors.personaFisica
                }
                control={<Checkbox checked={formik.values.personaFisica} />}
                onBlur={formik.handleBlur}
                name="personaFisica"
                onChange={formik.handleChange}
                label="Es persona física"
              />
            </Grid> */}
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.cuit && formik.errors.cuit)}
                fullWidth
                helperText={formik.touched.cuit && formik.errors.cuit}
                label="CUIT"
                name="cuit"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cuit}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(
                      formik.touched.tipoDoc && formik.errors.tipoDoc
                    )}
                    fullWidth
                    helperText={formik.touched.tipoDoc && formik.errors.tipoDoc}
                    label="Tipo de documento"
                    name="tipoDoc"
                    select
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.tipoDoc}
                    variant="outlined"
                  >
                    {tiposDocumentos.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(
                      formik.touched.nroDoc && formik.errors.nroDoc
                    )}
                    fullWidth
                    helperText={formik.touched.nroDoc && formik.errors.nroDoc}
                    label="Número de documento"
                    name="nroDoc"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.nroDoc}
                    variant="outlined"
                  />
                </Grid> */}

            {/* <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.rol && formik.errors.rol)}
                fullWidth
                helperText={formik.touched.rol && formik.errors.rol}
                label="Rol"
                name="rol"
                select
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.rol}
                variant="outlined"
              >
                {tiposDocumentos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
          </Grid>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              pt: 5,
            }}
          >
            <Grid item md={12} xs={12}>
              <FormControl>
                <FormControlLabel
                  error={Boolean(
                    formik.touched.aceptaTerminos &&
                      formik.errors.aceptaTerminos
                  )}
                  fullWidth
                  helperText={
                    formik.touched.aceptaTerminos &&
                    formik.errors.aceptaTerminos
                  }
                  control={<Checkbox checked={formik.values.aceptaTerminos} />}
                  onBlur={formik.handleBlur}
                  name="aceptaTerminos"
                  onChange={formik.handleChange}
                  label="Acepta términos y condiciones"
                />
                <FormHelperText>
                  {Boolean(
                    formik.touched.aceptaTerminos &&
                      formik.errors.aceptaTerminos
                  ) && formik.errors.aceptaTerminos}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Box> */}
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          {" "}
          <Stack sx={{ width: "100%" }} spacing={2}>
            {error && (
              <Alert fullWidth severity="error">
                {error}
              </Alert>
            )}
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <LoadingButton
            loading={loading}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Guardar aplicación
          </LoadingButton>
        </Box>
      </Card>
    </form>
  );
};
