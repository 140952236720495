import React from "react";
import { Typography, Box, Button, Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";
import TextField from "@mui/material/TextField";
import RiseLoader from "react-spinners/RiseLoader";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { styled } from "@mui/material/styles";

const PanelShareRecetas = (props) => {
  const { onClick, estilos, onClose, emailValidado, email, onChange, loading, error, enviado } = props;
  const classes = useStyles(theme);
  
  return (
    <div className={classes.content}>
      <Stack spacing={2} sx={{ alignItems: "center", paddingBottom: "32px" }}>
        {!loading && !enviado && (
          <>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "0.95rem",
                color: estilos.colorTextSecondary,
              }}
            >
              Se compartirán las recetas generadas al siguiente email. Si el
              email no fue precargado previamente, ingresarlo para poder
              compartir las recetas.
            </Typography>
            <Box sx={{ padding: "20px" }}>
              <CustomTextField
                size="small"
                onChange={onChange}
                value={email}
                color={estilos.backgroundColorPrimary}
                fullWidth
                label="Correo electrónico"
                id="emial-paciente"
                InputProps={{
                  classes: {
                    input: classes.resizeFont,
                  },
                }}
                InputLabelProps={{ style: { fontSize: 15 } }}
              />
            </Box>
          </>
        )}
        {loading && (
          <Box p={2}>
            <RiseLoader
              color={estilos.backgroundColorPrimary}
              loading={loading}
              size={15}
            />
          </Box>
        )}
        {!loading && enviado && (
          <Box p={2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <MarkEmailReadIcon sx={{ color: "#4BB067", fontSize: "60px" }} />
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "0.85rem",
                  color: estilos.colorTextSecondary,
                }}
              >
                Se compartieron exitosamente la/s receta/s al email del
                paciente.
              </Typography>
            </div>
          </Box>
        )}
      </Stack>
      {error && (
        <Box py={1}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "0.75rem",
              color: "red",
            }}
          >
            {error}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          padding: "15px 0px 0px",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          borderTop: "1px solid rgba(0, 0, 0, 0.18)",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
            justifyItems: "center",
            paddingBottom: "10px",
          }}
        >
          {!loading && !enviado && (
            <Button
              sx={{
                mr: 1,
                fontSize: "0.68rem",
                fontWeight: "500",
                backgroundColor: estilos.backgroundColorPrimary,
                "&:hover": {
                  backgroundColor: estilos.backgroundColorPrimary,
                  opacity: 0.8,
                },
              }}
              disabled={!emailValidado}
              variant="contained"
              onClick={() => onClick("send-email")}
            >
              CONFIRMAR
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{
              mr: 1,
              fontSize: "0.68rem",
              fontWeight: "500",
              color: estilos.backgroundColorPrimary,
              "&.MuiButton-outlinedPrimary": {
                border: "1px solid",
                borderColor: estilos.backgroundColorPrimary,
              },
            }}
            onClick={() => onClose(false)}
          >
            {!enviado ? "CANCELAR" : "CERRAR"}
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default PanelShareRecetas;

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "white",
  },
  resizeFont: {
    fontSize: 15,
  },
}));


const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& label.Mui-focused": {
    color: color,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: color,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: color,
    },
    "&.Mui-focused fieldset": {
      borderColor: color,
    },
    fontSize: "14px",
  },
}));