import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { CambiarPasswordSchema } from "../../helpers/validation";

export const UserEditFormChangePassword = (props) => {
  const { onSubmit, initialValues } = props;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CambiarPasswordSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        sx={{
          border: "0.5px solid #dddddd",
          padding: "1px",
          boxShadow: "1px 1px #d5d5d5",
        }}
      >
        <CardHeader
          subheader="Crea una nueva contraseña para el usuario"
          title="Cambiar contraseña"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Contraseña"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.repetirPassword &&
                    formik.errors.repetirPassword
                )}
                fullWidth
                helperText={
                  formik.touched.repetirPassword &&
                  formik.errors.repetirPassword
                }
                label="Repetir contraseña"
                name="repetirPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.repetirPassword}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Guardar contraseña
          </Button>
        </Box>
      </Card>
    </form>
  );
};
