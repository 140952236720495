import {
  APLICATIONS_LOADING,
  APLICATIONS_LOAD_SUCCESS,
  APLICATIONS_LOAD_ERROR,
  ADD_APLICATION_LOAD,
  ADD_APLICATION_SUCCESS,
  ADD_APLICATION_ERROR,
  UPDATE_APLICATION_LOAD,
  UPDATE_APLICATION_SUCCESS,
  UPDATE_APLICATION_ERROR,
  CLEAR_ADD_APLICATION,
  DELETE_APLICATION_LOADING,
  DELETE_APLICATION_SUCCESS,
  CONFIGURATION_APP_LOAD_SUCCESS,
  CONFIGURATION_APP_LOAD_ERROR,
  CONFIGURATION_APP_LOADING,
} from "../../constants/actionTypes";

const aplications = (state, { payload, type }) => {
  switch (type) {
    case UPDATE_APLICATION_LOAD:
    case APLICATIONS_LOADING: {
      return {
        ...state,
        aplications: {
          ...state.aplications,
          loading: true,
        },
      };
    }

    case APLICATIONS_LOAD_SUCCESS: {
      return {
        ...state,
        aplications: {
          ...state.aplications,
          loading: false,
          data: payload,
        },
      };
    }
    case APLICATIONS_LOAD_ERROR: {
      return {
        ...state,
        aplications: {
          ...state.aplications,
          loading: false,
          error: payload,
        },
      };
    }

    case ADD_APLICATION_LOAD: {
      return {
        ...state,
        addAplication: {
          ...state.addAplication,
          error: null,
          loading: true,
        },
      };
    }

    case ADD_APLICATION_ERROR: {
      return {
        ...state,
        addAplication: {
          ...state.addAplication,
          error: payload,
          loading: false,
        },
      };
    }

    case ADD_APLICATION_SUCCESS: {
      return {
        ...state,
        addAplication: {
          ...state.addAplication,
          loading: false,
          data: payload,
        },

        aplications: {
          ...state.aplications,
          loading: false,
          data: [payload, ...state.aplications.data],
        },
      };
    }

    case UPDATE_APLICATION_ERROR: {
      return {
        ...state,
        aplications: {
          ...state.aplications,
          loading: false,
          error: payload,
        },
      };
    }

    case UPDATE_APLICATION_SUCCESS: {
      return {
        aplications: {
          ...state.aplications,
          loading: false,
          data: state.aplications.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                nombre: payload.nombre,
                apellido: payload.apellido,
                rol: payload.rol,
              };
            }
            return item;
          }),
        },
      };
    }

    case CLEAR_ADD_APLICATION: {
      return {
        ...state,
        addAplication: {
          ...state.addAplication,
          error: null,
          loading: false,
          data: null,
        },
      };
    }

    case DELETE_APLICATION_LOADING: {
      return {
        ...state,
        aplications: {
          ...state.aplications,
          loading: true,
          data: state.aplications.data.map((item) => {
            if (item.id === payload.id) {
              return { ...item, deleting: true };
            }
            return item;
          }),
        },
      };
    }

    case DELETE_APLICATION_SUCCESS: {
      return {
        ...state,
        aplications: {
          ...state.aplications,
          loading: false,
          data: state.aplications.data.filter((item) => item.id !== payload.id),
        },
      };
    }


    default:
      return state;
  }
};

export default aplications;
