import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";
import {
  Typography,
} from "@mui/material";

const SinMedicamentosCargados = (props) => {
  const classes = useStyles(theme);
  return (
    <div className={classes.container}>
      <Typography
        sx={{
          m: 1,
          fontSize: "0.81rem",
          color: "primary.dark",
          fontWeight: "400",
        }}
      >
        Aún no se ha cargado ningun medicamento a la receta
      </Typography>
    </div>
  );
};

export default SinMedicamentosCargados;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 3,
    backgroundColor: "#E5E7EB",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "10px",
  },
}));
