import React from "react";
import { withRouter } from "./withRouter";
import { Outlet, Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {theme} from "../theme";


function PrivateRoute() {

  return isAuthenticated() ? (
    <div style={{ display: "flex", background: "white" }}>
      <MuiThemeProvider theme={theme}>
        <Outlet />
      </MuiThemeProvider>
    </div>
  ) : (
    <Navigate to="/" />
  );
}

export default withRouter(PrivateRoute);
