import { Avatar, Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@mui/material";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { Link as RouterLink } from 'react-router-dom';

export const ButtonAplicacion = (props) => {
  const { name ,id, activo} = props;

  return (
    <Card
      sx={{
        border: "0.5px solid #dddddd",
        padding: "1px",
        boxShadow: "1px 1px #d5d5d5",
      }}
      {...props}
    >
      <CardActionArea
        component={RouterLink}
        to={{
          pathname: "/configuration/" + id,
        }}
      >
        <CardContent>
          <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: "primary.dark",
                  height: 56,
                  width: 56,
                }}
              >
                <RecentActorsIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "0.85rem",
                  color: "primary.dark",
                }}
              >
                Aplicación
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.05rem",
                  color: "primary.main",
                }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: activo ? "#0ab54a" : "#D14343",
                  borderRadius: 30,
                }}
              ></div>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography sx={{ fontSize: "0.75rem" }}>
                Última actualización
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: "0.79rem", fontWeight: "bold" }}>
                09/08/2022
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

