import React from 'react';
import Login from "../pages/login/Login";
import Home from "../pages/home/Home";
import Configuration from "../pages/configuration/Configuration";
import DetailConfiguration from "../pages/configuration/DetailConfiguration";
import Users from "../pages/users/Users";
import NewUser from '../pages/users/NewUser';
import EditUser from "../pages/users/EditUser";
import NewClient from "../pages/clients/NewClient";
import ClientDetail from "../pages/clients/ClientDetail";
import Formulario from "../pages/formulario/Formulario";
import FormularioVistaPrevia from "../pages/formulario-vista-previa/FormularioVistaPrevia";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import Documentacion from '../pages/documentacion/Documentacion';
import Docs from '../pages/documentacion/Docs';

export default function Routing() {
  return (
    <BrowserRouter
      basename={process.env.PUBLIC_URL ? process.env.PUBLIC_URL : ""}
    >
      <Routes>
        <Route exact path="/home" element={<PrivateRoute />}>
          <Route exact path="/home" element={<Home />} />
        </Route>
        <Route exact path="/configuration" element={<PrivateRoute />}>
          <Route exact path="/configuration" element={<Configuration />} />
        </Route>
        <Route exact path="/users" element={<PrivateRoute />}>
          <Route exact path="/users" element={<Users />} />
        </Route>
        <Route exact path="/users/new" element={<PrivateRoute />}>
          <Route exact path="/users/new" element={<NewUser />} />
        </Route>
        <Route exact path="/users/edit/:userId" element={<PrivateRoute />}>
          <Route exact path="/users/edit/:userId" element={<EditUser />} />
        </Route>
        <Route exact path="/clients/new" element={<PrivateRoute />}>
          <Route exact path="/clients/new" element={<NewClient />} />
        </Route>
        <Route exact path="/clients/:clientId" element={<PrivateRoute />}>
          <Route exact path="/clients/:clientId" element={<ClientDetail />} />
        </Route>
        <Route exact path="/documentacion" element={<PrivateRoute />}>
          <Route exact path="/documentacion" element={<Documentacion />} />
        </Route>
        <Route exact path="/configuration/:appId" element={<PrivateRoute />}>
          <Route
            exact
            path="/configuration/:appId"
            element={<DetailConfiguration />}
          />
        </Route>
        <Route
          exact
          path="/formulario-vista-previa/:appId"
          element={<PrivateRoute />}
        >
          <Route
            exact
            path="/formulario-vista-previa/:appId"
            element={<FormularioVistaPrevia />}
          />
        </Route>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/formulario/:hash" element={<Formulario />} />
        <Route exact path="/formulario/docs" element={<Docs />} />
      </Routes>
    </BrowserRouter>
  );
}
